import React, { useState, useRef, useEffect, useCallback, FC } from 'react';
import TemplateSidebar from './modules/RoomListTemplate/LibraryCientificSidebar'
import {
  FiSearch,
  FiFile,
  FiChevronLeft,
  FiChevronRight,
  FiLock,
  FiX,
} from 'react-icons/fi';



import { FormHandles } from '@unform/core';
import {
  FaYoutube,
  FaTrophy,
  FaFilePdf,
  FaFileExcel,
  FaFilePowerpoint,
  FaStar,
  FaHeart,
  FaArrowLeft,
  FaLock,
  FaLockOpen,
  FaFilter,
  FaChevronCircleRight,
  FaChevronCircleLeft
} from 'react-icons/fa';

import { Link, useParams } from 'react-router-dom';
import { AiOutlineReload } from 'react-icons/ai';
import { uuid } from 'uuidv4';
import { LanguageSet } from '../Menu/styles';
import Button from '../Button';
import { useAuth } from '../../hooks/Auth';
import { useModal } from '../../hooks/Modal';
import { useToast } from '../../hooks/Toast';

import { useSocket } from '../../hooks/Socket';

import { QuizContainerReceiver } from './modules/QuizzComponent';

import {
  Form,
  Input,
  Container,
  Sidebar,
  Body,
  Header,
  Content,
  AnimatedContainer,
  VideoContainer,
  HeaderBox,
  SidebarMobileButton,
  Modal,
  ButtonColor,
  Card, ContainerSelector, SelectButton, SelectButtonFixed
} from './styles';

import SidebarContent from './Sidebar';
import HeaderContent from './Header';
import api from '../../services/api';
import { useVideo } from '../../hooks/Video';
import Vimeo from '../Vimeo';
import WebsitePopupComponent from '../WebsitePopupComponent';
import ChatRoom from '../Chat';
import Youtube from '../Youtube';
import { authTitle, defaultTheme, hash, urlWebsite } from '../../config';

import { RoomMessagerContainer, RoomMessagerCreator } from './modules/RoomMessager';
import { RoomChangeContainer, RoomChangeCreator } from './modules/RoomChange';
import { useLanguage } from '../../hooks/Language';
import launchMeeting from '../ZoomStarter/index.js';
import ZoomContainer from '../ZoomContainerLink';
import Zoom from '../ZoomStarter'

import VideoPlayer from '../VideoPlayer';
import SorteioRoom from '../Sorteio';
import StateManager from 'react-select';
import LessonEmoji from './modules/LessonEmojis';
import Favorite from './modules/Favorite';
import { useInteraction } from '../../hooks/Interactions';
import StatusChanger from './modules/StatusChanger';
import { addHours } from 'date-fns';
import OnDemandPlayer from '../OnDemandPlayer';
import Whatsapp from '../Whatsapp';
import CounterCardRoomSocket from '../../pages/DashboardPages/core/components/CounterCardRoomSocket';
import IframePlayer from '../IframePlayer';
import HLSPlayer from '../HLSPLayer';
import { useConfiguration } from '../../hooks/Configuration';
import RoomListTemplate from './modules/RoomListTemplate';
import { loadFromJson } from '../../utils/loadFromJson';
import { ICourses } from './interfaces';
import RoomConfiguration from './modules/RoomConfigurations';
import { day } from '../../utils/date';
import CourseProductsSubscriptionModule from '../../pages/CourseProductSubscription';


interface IValidationRules {
  type: 'email' | 'required' | 'min' | 'max' | 'length';
  message?: string;
  quantity?: number;
}

interface IvideoIdItem {
  videoIdItem: string;
  projectHash: string;
}

interface IValidationSchema {
  name: string;
  type: 'string' | 'number' | 'boolean';
  label: string;
  rules: Array<IValidationRules>;
}

interface IUploads {
  document: string;
  title?: string;
  id?: number;
  user_name?: string;
}

interface IUploadFile {
  title: string;
  type: 'PDF' | 'PPT' | 'XLS';
  url: string;
}

interface IVideos {
  before: any;
  waiting: any;
  online: any;

}

interface IBlock {
  block: IVideos;
  title: string;
}


const ClassRoomContainer: React.FC = () => {
  const {
    type,
    courses,
    setCoursesList,
    videoStatus,

    setFilter,
    openVideoByID,
    openCategory,
    currentRoom
  } = useVideo();





  const { socket } = useSocket();
  const { translate, language } = useLanguage();
  const { user, signOut, token } = useAuth();
  const [loading, setLoading] = useState(true);
  const [eventDay, setEventDay] = useState(true);
  const [online, setOnline] = useState(false);
  const formRef = useRef<FormHandles>(null);
  const [activeMenu, setActiveMenu] = useState(false);
  const [programActive, setProgramActive] = useState(false);
  const { addToast } = useToast();
  const inputRef = useRef<HTMLInputElement>(null);
  const [uploads, setUploads] = useState<Array<IUploads>>([]);
  const [chatKey, setChatKey] = useState(() => new Date().getMilliseconds());
  const { configuration } = useConfiguration();

  const [listKeywords, setListKeywords] = useState<Array<string>>([]);
  const [listSpeakers, setListSpeakers] = useState<Array<string>>([]);
  const [activeFilterModal, setActiveFilterModal] = useState(false);

  const { interactions } = useInteraction();

  const [allowQuizz, setAllowQuizz] = useState(true);
  const [fail, setFail] = useState(true);
  const asideRef = useRef<HTMLDivElement>(null);

  const { addModal, removeModal } = useModal();
  const [playerKey, setPlayerKey] = useState('videoPlayer');
  const [dateFilter, setDateFilter] = useState({});
  const [roomFilter, setRoomFilter] = useState({});
  const getVideo = ({ type, id, class_name = '', time = '0', status, room_id, dificulty_id }) => {

    if (type === 'vimeo') { return <Vimeo time={time} id={id} keyName={playerKey} />; }
    if (type === 'youtube') { return <Youtube id={id} keyName={playerKey} />; }
    if (type === 'zoom') { return <ZoomContainer dificulty_id={dificulty_id} room_id={room_id} status={status} id={id} class_name={class_name} keyName={playerKey} />; }
    if (type === 'ondemand') { return <OnDemandPlayer id={id} keyName={playerKey} />; }
    if (type === 'iframe') { return <IframePlayer time={time} id={id} keyName={playerKey} />; }
    if (type === 'direct') { return <HLSPlayer time={time} id={id} keyName={playerKey} />; }
  }



  const reloadConnection = () => {




    if (videoStatus.type === 'video') {


      socket?.emit('Module-SimpleChat-Connect', {
        room: `${currentRoom.chat_id || currentRoom._id}`
      });
    }
    else {
      socket?.emit('Module-SimpleChat-Connect', { room: '' });
    }




  }

  useEffect(() => {


    handleReloadRoom();

  }, [videoStatus, courses])

  const { videoIdItem, projectHash } = useParams<IvideoIdItem>();






  const load = async () => {
    setLoading(true);

    let data: Array<ICourses> = [];
    const coursesList = await loadFromJson({ hash: hash, project_id: projectHash, table: 'Course' });
    if (coursesList) {


      data = coursesList;



    }
    else {

      const response = await api.get(`/my-courses/${projectHash}`)
      data = response?.data;
    }




    if (!data || data.length < 1) {
      setLoading(false);
      setOnline(false);
    } else {

      let keywords: Array<string> = [];
      let speakers: Array<string> = [];

      data.map((courseContent, index) => {

        const daysRef = {};
        const roomsRef = {};

        const currentSpeakers = {};
        let currentKeys = {};



        courseContent.lessons.map((lessonContent, indexLesson) => {
          const currentDate = addHours(new Date(lessonContent.release_date), -3);
          const currentDay = currentDate.getUTCDate();
          const currentMonth = (currentDate.getUTCMonth() + 1) < 10 ? `0${currentDate.getUTCMonth() + 1}` : currentDate.getUTCMonth() + 1;

          const currentRef = `${currentDay}.${currentMonth}`;

          if (!daysRef[currentRef]) {
            daysRef[currentRef] = currentRef;
          }

          data[index].lessons[indexLesson].reference_date = currentRef;

          if (!roomsRef[lessonContent.reference]) {

            roomsRef[lessonContent.reference] = lessonContent.room_name || lessonContent.reference;
          }

          if (data?.[index]?.lessons?.[indexLesson]?.keywords) {



            data?.[index]?.lessons?.[indexLesson]?.keywords.map((key) => {
              currentKeys[key] = key;
            });



          }

          const getSpearkers = data?.[index]?.lessons?.[indexLesson].nested_speakers;

          if (getSpearkers && getSpearkers.indexOf(";") >= 0) {

            const listSpeakers = getSpearkers.split(";");


            if (listSpeakers && listSpeakers.length >= 0) {
              listSpeakers.map(sp => {
                currentSpeakers[sp] = sp;
              })



            }


          }



        })

        courseContent?.lessons?.sort((a, b) => {
          const dateA = a.release_date ? new Date(a.release_date) : new Date();
          const dateB = b.release_date ? new Date(b.release_date) : new Date();
          return dateA > dateB ? 1 : dateA < dateB ? -1 : 0;
        })

        const newRoomsKey = Object.keys(roomsRef).sort().reduce(
          (obj, key) => {
            obj[key] = roomsRef[key];
            return obj;
          },
          {}
        );


        speakers = [...Object.keys(currentSpeakers).sort()];
        keywords = [...Object.keys(currentKeys).sort()];
        setDateFilter(daysRef);
        setRoomFilter(newRoomsKey);
        setListKeywords(keywords);
        setListSpeakers(speakers);

      })

      setLoading(false);
      setCoursesList(data || []);
      setOnline(true);
      if (videoIdItem) {


        openVideoByID(data || [], videoIdItem);
      }
      else {


        if (configuration?.video_display === 'video') {

          if (configuration?.video_display_hash) {
            openVideoByID(data || [], configuration?.video_display_hash);
          }
          else {
            openCategory(0, 0, projectHash);
          }


        }


      }
    }
  };


  const handleReloadRoom = () => {
    socket?.off('reloadThisRoom');
    socket?.on('reloadThisRoom', (state) => {

      setTimeout(() => {
        load();
      }, 10000)
    })

  };



  const [videos, setVideos] = useState<IVideos>({
    before: { title: translate('Eventos anteriores'), list: [] },
    waiting: { title: translate('Em breve'), list: [] },
    online: { title: translate('Ao vivo'), list: [] },
  });

  const [filterVideos, setFilterVideos] = useState({
    day: '',
    reference: '',
    favorite: 1,
    speakers: {},
    keywords: {},
    search: ''
  })

  const [listFavorites, setListFavorites] = useState<Array<number>>([]);

  const addToFilter = (column: string, value: any) => {

    if (column === 'keywords') {
      const filter = { ...filterVideos };
      if (filter?.keywords && filter?.keywords[value]) {
        delete filter?.keywords[value];
      }
      else {
        filter.keywords[value] = value;
      }
      setFilterVideos(state => ({ ...filter }));
    }
    else if (column === 'speakers') {
      const filter = { ...filterVideos };
      if (filter?.speakers && filter?.speakers[value]) {
        delete filter?.speakers[value];

      }
      else {
        filter.speakers[value] = value;
      }
      setFilterVideos(state => ({ ...filter }));
    }
    else {

      setFilterVideos(state => ({ ...state, [column]: value }));
    }

  }

  const addToFavorite = (id: number) => {

    const index = listFavorites.findIndex(list => list === id);

    if (index < 0) {
      setListFavorites(state => [...state, id]);

    }
    else {
      setListFavorites(state => {
        const response = state.reduce((prev: Array<number>, favorite) => {

          if (favorite !== id) {
            prev.push(id);
          }
          return prev;
        }
          , []);
        return response;
      });
    }

  }

  const sortLesson = (lesson) => {
    let response = false;


    if (filterVideos.search) {

      const content = `${lesson?.title} ${lesson?.room_name} ${lesson?.nested_keywords} ${lesson?.nested_speakers} ${lesson?.start_hour} ${lesson?.release_date_formatted}`

      if (filterVideos.search.indexOf(' ') > 0) {

        const searching = filterVideos.search.split(' ');

        let test = true;

        searching.map(s => {

          if (test) {
            if (s !== '') {

              if (content.toLowerCase().indexOf(s.toLowerCase()) >= 0) {
                test = true;
              }
              else {
                test = false;

              }
            }


          }


        })

        if (!test) {
          return false;
        }

      }
      else {
        if (content.toLowerCase().indexOf(filterVideos.search.toLowerCase()) >= 0) {
          response = true;
        }
        else {
          return false;
        }
      }



    }



    if (filterVideos.day !== '' && lesson.release_date && lesson.reference_date === filterVideos.day) {
      response = true;
    }

    if (filterVideos.day === '') {
      response = true;
    }

    if (filterVideos.reference !== '' && filterVideos.reference !== lesson.reference) {
      return false;
    }

    if (filterVideos.favorite === 2) {
      const index = interactions?.lesson?.[lesson._id]?.star;
      if (!index) {
        return false;
      }
    }

    const keys = Object.keys(filterVideos?.keywords);

    if (keys.length > 0) {
      if (!lesson?.keywords || lesson?.keywords.length < 1) {

        return false;
      }
      else {

        let test = false;
        Object.keys(filterVideos.keywords).map(key => {
          const index = lesson.keywords.indexOf(key);

          if (index >= 0) {
            test = true;
          }

        });

        if (test === false) {
          return false;
        }
        else {
          response = true;
        }


      }

    }

    const speakers = Object.keys(filterVideos?.speakers);

    if (speakers?.length > 0) {
      if (!lesson?.nested_speakers) {
        return false;
      }
      else {

        const speakers = lesson?.nested_speakers;

        if (speakers && speakers.indexOf(';') > 0) {

          const listSpeakers = speakers.split(";");

          let test = false;
          Object.keys(filterVideos.speakers).map(key => {
            const index = listSpeakers.indexOf(key);

            if (index >= 0) {
              test = true;
            }

          });

          if (test === false) {
            return false;
          }
          else {
            response = true;
          }

        }
        else {
          return false;
        }




      }

    }

    return response;

  }

  const sortVideos = () => {
    const newVideos: IVideos = {
      before: { title: translate('Eventos anteriores'), list: [] },
      waiting: { title: translate('Em breve'), list: [] },
      online: { title: translate('Ao vivo agora'), list: [] },
    }

    {
      videoStatus &&

        courses[videoStatus.course] &&
        courses[videoStatus.course].lessons.map((lesson, index) => {

          if (sortLesson(lesson)) {

            if (lesson.status === 1) {

              newVideos.waiting.list.push(lesson);
            }

            if (lesson.status === 2) {
              newVideos.online.list.push(lesson);
            }

            if (lesson.status === 3) {
              newVideos.before.list.push(lesson);
            }
          }

        })

    };



    setVideos(newVideos);
  }

  useEffect(() => {

    sortVideos();

  }, [courses, filterVideos, interactions]);


  const reloadChat = () => {
    setChatKey(new Date().getMilliseconds());
  };

  const reloadPlayer = () => {
    const d = new Date();
    const n = d.getTime();
    setPlayerKey(`video${n}`);
  };

  useEffect(() => {
    reloadChat();
    load();
    socket?.off('receiveChatList');
    socket?.on('receiveChatList', (data) => {
      console.log(data);
    });
    socket?.off('disconnectUser');
    socket?.on('disconnectUser', () => {
      console.log('disconnect me');
      addToast({
        type: 'error',
        title: translate('Você foi desconectado'),
        description: translate(
          'Seu token expirou ou outra pessoa logou com o seu acesso.',
        ),
      });
      setTimeout(() => {
        signOut();
        window.location.href = '/';
      }, 3000);
    });
    socket?.off('checkDisconnectUser');
    socket?.on('checkDisconnectUser', state => {
      console.log('check disconnect me', state);
      if (token !== state.token) {
        addToast({
          type: 'error',
          title: translate('Você foi desconectado'),
          description: translate(
            'Seu token expirou ou outra pessoa logou com o seu acesso.',
          ),
        });
        setTimeout(() => {
          signOut();
          window.location.href = '/';
        }, 3000);
      }
    });
    socket?.emit('checkUser');
  }, []);

  useEffect(() => {
    reloadChat();
    load();
    setProgramActive(false);
  }, [videoIdItem]);

  const handleSearch = (data: Record<string, any>): void => {
    setFilter(data.search || '');
  };

  const listUsers = (id) => {

    socket?.emit('requestChatList', id)

  }


  const ListRoomByCategory = (column, selector) => {
    const statusSelector = {
      "1": "Em breve",
      "2": <><div className="redBall" /> Online agora</>,
      "3": "Finalizado"
    }



    return (
      (videoStatus &&

        courses[videoStatus.course] &&
        courses[videoStatus.course].lessons.map((lesson, index) => (
          lesson[column] && lesson[column] === selector ?
            <AnimatedContainer
              key={`videoLesson${lesson._id}`}
              className={`videoBox ${lesson.class_name && lesson.class_name} ${lesson.status && `status${lesson.status}`}`}
              onClick={() => openVideoByID(courses, lesson?._id)}
              background={
                lesson.image
                  ? `${urlWebsite}/${lesson.image}`
                  : '/apoio/card_padrao.png'
              }
            >

              <div className="videoThumbHeader">
                <div>{lesson.start_hour} - {lesson.release_date_formatted} </div>
                <div className="videoStatus"> {statusSelector[lesson.status] || 'Em breve'}</div>
              </div>


              {lesson.image && (<>



                <img
                  className="videoSeta"
                  src="/apoio/seta.png"
                />


              </>)}

              {!lesson.image && lesson.title && (
                <>
                  <h2 dangerouslySetInnerHTML={{ __html: lesson.title }} />
                  <ButtonColor background={lesson.room_color}>

                    {' '}
                    |{lesson.release_date_formatted}
                    {' '}
                    -
                    {lesson.start_hour}
                  </ButtonColor>
                </>
              )}
            </AnimatedContainer> : <></>
        ))) || <></>
    );
  };

  const createRoomSelectors = () => {

    const selectors = [
      { column: 'status', selector: 2, title: "Ao Vivo" },
      { column: 'room_name', selector: 'Espaço 1', title: 'Espaço 1' },
      { column: 'room_name', selector: 'Espaço 2', title: 'Espaço 2' },
      { column: 'room_name', selector: 'Espaço 3', title: 'Espaço 3' },
      { column: 'room_name', selector: 'Espaço 4', title: 'Espaço 4' },
      { column: 'room_name', selector: 'Espaço 5', title: 'Espaço 5' },
      { column: 'room_name', selector: 'Espaço 6', title: 'Espaço 6' }
    ];

    const response = selectors.map(category => <div><h2>{category.title}</h2><div>{ListRoomByCategory(category.column, category.selector)}</div></div>);
    return <ContainerSelector>{response} </ContainerSelector> || <></>;
  }





  const ListRoom = () => {

    if (configuration?.exclusive && user?.profile === 2 && user?.position !== configuration?.exclusive && user?.category !== configuration?.exclusive) {
      return <div style={{ minHeight: '50vh', width: '100%', maxWidth: '400px', padding: '20px', borderRadius: '10px', marginTop: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center', background: 'rgb(0,0,0,0.5)', flexDirection: 'column' }}>
        <FaLock size={100} style={{ minWidth: '100px', color: '#fff' }} />
        <h2 style={{ marginTop: '25px', fontSize: '18px', maxWidth: '350px', width: '100%', textAlign: 'center' }}>Prezado inscrito, este conteúdo é exclusivo para a categoria:</h2>
        <button className="defaultButton" style={{ padding: '15px 10px' }}>{configuration?.exclusive}</button>

        <p style={{ marginTop: '50px', fontSize: '16px', maxWidth: '350px', width: '100%', textAlign: 'center' }} >Desfrute de centenas de outros conteúdos disponíveis para você em nossa plataforma</p>

        <Link to="/eventos"> <button className="defaultButtonReverse" style={{ padding: '15px 10px' }}>Confira</button></Link>

      </div>
    }

    return <RoomListTemplate template={configuration?.room_list_template || 'default'} videos={videos} />

  };

  const [option, setOption] = useState(1);

  const getList = (): JSX.Element => {

    return (videoStatus &&

      courses[videoStatus.course] ? <> <div className="buttonDiv"> <div>
        <button type="button" onClick={() => setOption(1)} className={option === 1 ? 'squareButton' : "squareButtonReverse"}>Ver todos conteúdos</button>
        <button type="button" onClick={() => setOption(2)} className={option === 2 ? 'squareButton' : "squareButtonReverse"}><div className="redBall" /> <span> Ver Online Agora</span></button>
      </div>
      </div>
      {option === 1 ? ListRoom() : ListRoomByCategory('status', 2)}

    </> : <></>
    )



  }

  const roomOptions = () => {


    const openNewRoom = (id: string) => {

      openVideoByID(courses, id);
      removeModal('select-room');
    }




    let items: Array<JSX.Element> = []
    let items2: Array<JSX.Element> = []

    courses?.[0]?.lessons?.map(item => {


      if (item?.visible === '2') {

        items.push(<tr><td>{item?.release_date_formatted}</td><td>{`${item?.room_name}`}</td><td onClick={() => { openNewRoom(item?._id) }}><button className='selectButtonMini'>{'PT'}</button></td><td onClick={() => { return item?.languages?.en ? openNewRoom(item?.languages?.en) : false }}>{item?.languages?.en ? <button className='selectButtonMini'>EN</button> : ''} </td><td onClick={() => { return item?.languages?.en ? openNewRoom(item?.languages?.es) : false }}>{item?.languages?.es ? <button className='selectButtonMini'>ES</button> : ''}</td></tr>)
        items2.push(<aside style={{ display: 'flex', width: '100%', marginBottom: '10px' }} className="mobileOnly"><table className='table ' style={{ tableLayout: 'fixed' }}><tbody><tr><td>{item?.release_date_formatted}</td><td colSpan={2}>{`${item?.room_name}`}</td></tr><tr><td onClick={() => { openNewRoom(item?._id) }}><button className='selectButtonMini'>{'PT'}</button></td><td onClick={() => { return item?.languages?.en ? openNewRoom(item?.languages?.en) : false }}>{item?.languages?.en ? <button className='selectButtonMini'>EN</button> : ''} </td><td onClick={() => { return item?.languages?.en ? openNewRoom(item?.languages?.es) : false }}>{item?.languages?.es ? <button className='selectButtonMini'>ES</button> : ''}</td></tr></tbody></table>  </aside>)

      }

    })


    addModal({
      title: '', theme: 'whiteModalMedium', key: 'select-room', content: <><table className='table'><tbody>

        <tr><td colSpan={1}><button className='selectButtonMini'>PT</button> </td><td colSpan={4}>Clique no idioma para acessar a sala</td></tr>
        <tr><td colSpan={1}><button className='selectButtonMini'>EN</button> </td><td colSpan={4}>Click on the language to access the room</td></tr>
        <tr><td colSpan={1}><button className='selectButtonMini'>ES</button></td> <td colSpan={4}>Pulsa sobre el idioma para acceder a la sala</td></tr>
      </tbody></table><br />
        <table className='table computerOnly'><tbody>
          {items}</tbody></table>


        {items2}
      </>
    })



  };

  const getLanguages = (courseIndex, lesson, title = 'Selecionar idioma do vídeo') => {
    if (lesson.languages && (lesson.languages.en || lesson.languages.es)) {
      return (


        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexWrap: 'wrap',
            width: 'auto',
          }}
        >
          <p style={{ color: '#fff' }}>{translate(title)}: </p>
          <LanguageSet style={{ marginTop: '0px' }}>
            <div className='typeButtonInitial2' style={{ minWidth: '44px', padding: '7px' }} onClick={() => openVideoByID(courses, lesson.languages.ptBr)}>
              <img src="/assets/languages/BR.png" style={{ width: '40px' }} />
            </div>
            {lesson.languages.en && (
              <div className='typeButtonInitial2' style={{ minWidth: '44px', padding: '7px' }} onClick={() => openVideoByID(courses, lesson.languages.en)}>
                <img src="/assets/languages/US.png" style={{ width: '40px' }} />
              </div>
            )}
            {lesson.languages.es && (
              <div className='typeButtonInitial2' style={{ minWidth: '44px', padding: '7px' }} onClick={() => openVideoByID(courses, lesson.languages.es)}>
                <img src="/assets/languages/ES.png" style={{ width: '40px' }} />
              </div>
            )}
          </LanguageSet>
        </div>

      );
    }

    return <></>;
  };

  const ListRoomHeader = () => {

    if (videoStatus?.type !== 'list') {
      return <></>
    }

    return <>
      {configuration?.theme_data?.show_room_header !== 'no' ? <div className="listHeader">
        <select className="selectButton" onChange={(e) => addToFilter('day', e.target.value)}>
          <option value="">{translate('TODAS AS DATAS')}</option>


          {Object.keys(dateFilter)?.map((key) => <option value={key}>{dateFilter[key]}</option>)}
        </select>

        <input value={filterVideos.search} placeholder={translate('Escreva para pesquisar')} style={{ padding: '15px', borderRadius: '10px', margin: '5px', border: '2px solid #333' }} name="search" onChange={(e) => addToFilter("search", e.target.value)} />

        <button title="filtrar" style={{ display: 'none' }} className='filterButton' type='button' onClick={() => setActiveFilterModal(!activeFilterModal)}>
          <FaFilter /> <p>Filtrar</p>
        </button>
      </div> : <></>}

      { /* <div className="listHeader">

  

      <SelectButton active={filterVideos.day === ''} onClick={() => addToFilter('day','')}><p>{translate('Todos')}</p></SelectButton>
    

      {Object.keys(dateFilter)?.map( key => <SelectButton active={filterVideos.day === dateFilter[key]} onClick={() => addToFilter('day',dateFilter[key])}><p>{translate(key)}</p></SelectButton>)}

      <SelectButton active={filterVideos.favorite === 2} onClick={() => addToFilter('favorite',filterVideos.favorite === 1 ? 2 : 1)}><FaStar/><p>{translate('Favoritos')}</p></SelectButton>
 
    </div> */}
      {activeFilterModal && <div className='filterModal'>

        <div className='closeFilterModal'><button type="button" onClick={() => setActiveFilterModal(false)}><FiX /></button></div>

        {listSpeakers && listSpeakers.length > 0 && <>
          <div>
            <h2>{translate("Palestrantes")}</h2>
            {listSpeakers && listSpeakers.map(spk => {

              return spk !== '' && spk !== ' ' ? <label onClick={() => addToFilter('speakers', spk)} className={filterVideos?.speakers && filterVideos?.speakers?.[spk] ? 'activeFilter' : ''}>{spk}</label> : <></>

            })}


          </div>
        </>
        }

        {listKeywords && listKeywords.length > 0 && <>
          <div>
            <h2>Palavras-chave</h2>
            {listKeywords && listKeywords.map(spk => {

              return <label onClick={() => addToFilter('keywords', spk)} className={filterVideos?.keywords && filterVideos?.keywords?.[spk] ? 'activeFilter' : ''}>{spk}</label>

            })}


          </div>
        </>
        }

      </div>

      }

    </>
  }

  const OpenRoom = () => {
    const validTypes = {
      PDF: <FaFilePdf />,
      XLSX: <FaFileExcel />,
      PPT: <FaFilePowerpoint />,
    };

    const allow_categories = currentRoom?.allow_categories || [];
    const email = user.email || '';

    const uploadFiles: Array<IUploadFile> =
      videoStatus &&
        videoStatus.type === 'video' &&
        courses[videoStatus.course] &&
        courses[videoStatus.course].lessons &&
        currentRoom &&
        currentRoom.uploads
        ? currentRoom.uploads || []
        : [];


    return (
      (videoStatus &&
        videoStatus.type === 'video' &&
        courses[videoStatus.course] &&
        courses[videoStatus.course].lessons &&
        currentRoom && (
          <>
            <h2 style={{ width: '100%', textAlign: 'center', background: 'rgba(0,0,0,0.5)', color: '#fff', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', padding: '10px', gap: '10px' }}>

              <div >
                <SelectButtonFixed style={{ margin: '0px 10px', color: '#fff' }} onClick={() => { openCategory(0, 0, projectHash); addToFilter('day', ''); }}><FaArrowLeft /> <p >{translate(window?.location?.pathname?.indexOf('zoster') >= 0 ? translate('Episódios') : translate('Palestras'))}</p></SelectButtonFixed>

              </div>


              <div style={{ color: '#fff', fontWeight: 400 }}>{courses?.[videoStatus.course]?.lessons?.[videoStatus?.video]?.room_name && <strong style={{ color: '#a6e4ff', display: 'inline' }}>{courses?.[videoStatus.course]?.lessons?.[videoStatus?.video]?.room_name}:</strong>} <p style={{ display: 'inline', textTransform: 'uppercase', color: '#fff' }}>{currentRoom?.[`title_${language}`] ? currentRoom[`title_${language}`] : currentRoom.title}</p></div>

              {videoStatus?.nextVideo && <SelectButtonFixed className='computerOnly' onClick={() => openVideoByID(courses, videoStatus?.nextVideo)} style={{ margin: '0px 10px', minWidth: '140px', color: '#fff', alignItems: 'center', justifyContent: 'space-between', display: 'flex' }} ><p>{translate("Próximo")} </p> <FaChevronCircleRight style={{ margin: '0px' }} /></SelectButtonFixed>}

              <CounterCardRoomSocket room={currentRoom._id} />


            </h2>

            {allow_categories && allow_categories.length > 0 && allow_categories[0] !== '' && user?.profile === 2 && email?.indexOf('@pfizer') < 0 && allow_categories.indexOf(user?.position) < 0 ?

              <div style={{ padding: '0px', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '300px', background: '#fff', margin: '30px auto', padding: '20px', borderRadius: '10px', boxShadow: '3px 2px 10px rgba(0,0,0,0.7)' }}>
                  <FaLock size={70} style={{ margin: '20px 0px' }} color="rgb(0, 14, 64)" />
                  <h2>{translate("Atividade exclusiva para")}:</h2>
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                    {allow_categories.map(allow => {
                      return <h2 style={{ color: '#fff', background: 'rgb(0, 14, 64)', padding: '10px 20px', margin: '10px 20px' }}>{allow}s</h2>
                    })}

                    <p style={{ width: '100%', maxWidth: '400px', textAlign: 'center', color: 'rgb(0, 14, 64)', padding: '20px' }}>
                      {translate("Caso você esteja em uma destas categorias, realize o seu login novamente ou acione o nosso suporte")}.</p>

                    <Link to="/login"><button className='defaultButton'>{translate("Realizar login novamente")}</button></Link>
                    <aside style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>

                      <Whatsapp number="555195181533" message={translate("Suporte técnico")} text={`${translate("Olá, estou no")} ${authTitle}`} />
                    </aside></div>
                </div>
              </div>

              :


              <div className="videoGroup" style={{ padding: '0px' }}>
                <VideoContainer style={{ display: currentRoom.video_type_id === 'no-player' ? 'none' : 'flex' }}>


                  {currentRoom.room_name === 'Espaço 1' && currentRoom.status === 3 && currentRoom
                    .time &&
                    <p style={{ fontSize: '14px', textAlign: 'center', background: 'coral', color: '#fff', padding: '10px', margin: "5px" }}>

                      {currentRoom
                        .time && <> {`${translate("Esta palestra inicia aos")} ${currentRoom
                          .time} ${translate("do vídeo")}`}</>}
                    </p>
                  }

                  <RoomMessagerContainer
                    room_id={currentRoom?._id || ''
                    }
                    module_id={1}
                  />

                  {projectHash === 'genomic-summit-2023' ? <button className='typeButtonInitial2' style={{ margin: '10px auto', minWidth: '250px' }} onClick={() => roomOptions()}>Selecionar Sala</button> : <></>}
                  {user.profile !== 9 && <div style={{ display: currentRoom?.languages && (currentRoom?.languages?.en || currentRoom?.languages?.es) ? 'flex' : 'none', width: 'auto', margin: '5px auto', padding: '5px', borderRadius: '10px', background: 'rgba(0,0,0,0.5)' }}>{getLanguages(
                    videoStatus.course,
                    currentRoom,
                  )}</div>}


                  {user && user.profile === 9 && (
                    <Zoom room_id={currentRoom
                      ._id} />
                  )

                  }
                  {user && user.profile !== 6 && user.profile !== 9 && (
                    <>
                      <div className="videoContainer">
                        {getVideo({
                          type: currentRoom.video_type_id,
                          id: currentRoom.video_id,
                          class_name: currentRoom.class_name,
                          time: currentRoom.time || '0',
                          status: currentRoom.status,
                          room_id: currentRoom._id,
                          dificulty_id: currentRoom.dificulty_id
                        }
                        )}


                        {currentRoom?.video_type_id !== 'no-player' ? <LessonEmoji room={currentRoom.chat_id || currentRoom._id} /> : <></>}
                        <StatusChanger dificulty_id={currentRoom?.dificulty_id} id={currentRoom?._id} status={currentRoom?.status} class_name={currentRoom?.class_name} type={currentRoom?.video_type || currentRoom?.video_type_id} />

                        {user?.profile === 1 ? <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}> <Link style={{ margin: '10px auto' }} to={`/app/${projectHash}/dashboard/green-room/${currentRoom._id}`}><button className="defaultButton">{translate("Sala de estúdio")}</button></Link></div> : <></>}

                      </div>
                      {currentRoom
                        .video_type_id !== 'zoom' && <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: '5px 0px'

                          }}
                        >
                          {user.profile !== 9 && <div className="mobileOnly">{getLanguages(
                            videoStatus.course,
                            currentRoom, translate('Selecionar Idioma')
                          )}</div>}
                          <button
                            style={{ margin: '10px', minWidth: '150px', background: '#fff' }}
                            title="Player not working? Click here!"
                            className="reloadPlayer"
                            onClick={() => reloadPlayer()}
                          >
                            <AiOutlineReload />
                            {' '}
                            {translate('Recarregar vídeo')}
                          </button>
                        </div>}
                    </>
                  )}



                </VideoContainer >
                {currentRoom.video_type_id !== 'zoom' &&
                  <div style={{ position: 'relative', width: '100%', maxWidth: '400px', margin: '0px auto', display: configuration?.theme_data?.show_room_chat === 'no' ? 'none' : 'flex', flexDirection: 'column' }}>
                    {currentRoom?.video_type_id === 'no-player' ? <><br /><LessonEmoji room={currentRoom.chat_id || currentRoom._id} /> </> : <></>}

                    <RoomConfiguration />
                    <ChatRoom key={`${currentRoom.chat_id || currentRoom._id}`} slides={courses?.[videoStatus.course]?.lessons?.[videoStatus.video]?.slides || []}
                      room_name={courses?.[videoStatus.course]?.lessons?.[videoStatus.video]?.reference || ''} poster_id={courses?.[videoStatus.course]?.lessons?.[videoStatus.video]?.poster_id}
                      id={currentRoom.chat_id || currentRoom._id}
                    />





                  </div>}
                {configuration?.theme_data?.show_room_next_videos === 'yes' ? <div>
                  <TemplateSidebar videos={videos} />
                </div> : <></>}
              </div>}


          </>
        )) || <></>
    );
  };

  const showTrophy = () => {
    addToast({
      type: 'info',
      title: translate('Nenhum troféu identificado'),
      description: translate('Continue sua jornada para alcançar novas conquistas'),
    });
  };

  if (loading) {
    return <Container />;
  }

  if (configuration?.current_event_id_response?.presence_type === 'local') {
    return <div className="onlineMessage">
      <div style={{
        padding: '50px 30px', color: 'rgb(0, 14, 64)', background: '#fff', borderRadius: '10px', boxShadow: '2px 2px 5px rgba(0,0,0,0.3)', maxWidth: '400px',
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: '100px'
      }}>
        <h2 style={{ color: 'rgb(0, 14, 64)', fontSize: '24px', textAlign: 'center' }} className="defaultMarked"><div />{translate(configuration?.current_event_id_response?.title || configuration?.title || '')}</h2>
        <br />
        <h2 style={{ color: 'rgb(0, 14, 64)', fontSize: '18px', textAlign: 'center' }} className="defaultMarked"><div />{translate('Este é um evento presencial')}</h2>
        <br />
        <CourseProductsSubscriptionModule />

        <br />
        <Link to={`/app/${projectHash}`}>
          <button className="defaultButton" type="button">
            {' '}
            {translate('VOLTAR')}
          </button>
        </Link>

      </div>
    </div >
  }

  return (
    <Container background={videoStatus &&
      videoStatus.type === 'video' &&
      courses[videoStatus.course] &&
      courses[videoStatus.course].lessons &&
      currentRoom &&
      currentRoom.background_image || ''}>
      <Container style={{
        background: videoStatus &&
          videoStatus.type === 'video' &&
          courses[videoStatus.course] &&
          courses[videoStatus.course].lessons &&
          currentRoom &&
          currentRoom.background_image ? 'rgba(0,0,0,0.5)' : 'none'
      }}>
        <Content>

          <Body >

            {eventDay || user.profile === 1 || user.profile > 2 ? (
              <>

                {ListRoomHeader()}
                {videoStatus.type === 'list' ? ListRoom() : <></>}
                {OpenRoom()}
              </>
            ) :

              (online ? <div className="onlineMessage">
                <div style={{ padding: '20px', color: '#333', background: '#fff', borderRadius: '10px', boxShadow: '2px 2px 5px rgba(0,0,0,0.3)' }}>
                  <h2 style={{ color: '#333' }} className="defaultMarked"><div />{translate('Inscrição Confirmada')}</h2>
                  <p style={{ color: '#333', textAlign: 'center', marginTop: '20px' }}>
                    {translate(
                      'Retorne a este website no dia do evento para participar do evento.',
                    )}
                  </p><br /><br />
                  <Link to="/">
                    <button className="defaultButton" type="button">
                      {' '}
                      {translate('Sair')}
                    </button>
                  </Link>
                </div>
              </div> : <div className="onlineMessage">

                <h2 style={{ color: '#fff', textAlign: 'center', fontSize: '24px' }} className="defaultMarked"><div />{translate('Pagamento pendente')}</h2>
                <p style={{ color: defaultTheme.defaultBackground, textAlign: 'center', marginTop: '20px' }}>
                  {translate(
                    'O seu pagamento ainda não consta como confirmado em nosso sistema ')}<br /><br /> {translate(
                      'Boletos podem demorar até 3 dias úteis para compensar')}

                </p><br /><br />
                <Link to="/" style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <button className="defaultButton" type="button">
                    {' '}
                    {translate('Sair')}
                  </button>
                </Link>
              </div>)



            }
          </Body>
        </Content>

        <RoomChangeContainer />
      </Container>
    </Container>
  );
};

export default ClassRoomContainer;
