import React, { useEffect } from 'react';
import { MdChat, MdChatBubble } from 'react-icons/md';
import { Container, Sponsor, SponsorGrid } from './styles';
import { Button } from '../../components/Button/styles';
import Sponsors from '../Sponsors';
import Particles from 'react-particles-js';
import ImageSliderBackground from '../../components/ImageBackgroundSlider';
import Whatsapp from '../../components/Whatsapp';
import { FaInstagram, FaLinkedin, FaWhatsapp } from 'react-icons/fa';

const FooterLayout: React.FC = ({ children }) => {
  return (<>
    <div className='full-width column background2' style={{ padding: '50px 20px', position: 'relative', zIndex: 1000, alignItems: 'center' }} >
      <div className="row  full-width gap-sm " style={{ maxWidth: '1000px', alignItems: 'center', justifyContent: 'center', gap: '20px' }}>

        <a href="https://www.instagram.com/encontrodigital.eventos/" target='_BLANK'>
          <FaInstagram className='social-icon color1 scaleOnHover' />
        </a>
        <a href="https://www.linkedin.com/company/plataforma-encontro-digital" target='_BLANK'>
          <FaLinkedin className='social-icon color1 scaleOnHover' />
        </a>

        <a href={`https://api.whatsapp.com/send?l=pt&phone=5551981476007&text=${encodeURI(
          'Olá, estou no website da Encontro Digital'
        )}`} target="_BLANK">
          <FaWhatsapp className='social-icon color1 scaleOnHover' />
        </a>

      </div>

      <div className='full-width  background1 ' style={{ height: '2px', maxWidth: '90%', margin: '20px 0px', marginBottom: '40px', opacity: 0.3 }}>

      </div>

      <div className="row-to-column  full-width  " style={{ maxWidth: '1000px', alignItems: 'center', justifyContent: 'space-between', gap: '20px' }}>
        <img src="/apoio/logo2.png" style={{ width: '200px' }} />
        <p className='colo1'><strong> ® ENCONTRO DIGITAL. TODOS OS DIREITOS RESERVADOS</strong></p>
      </div>


    </div>


    <div style={{ zIndex: -1 }}>
      <Particles
        params={{
          particles: {
            lineLinked: {
              enable: false,
              distance: 250
            },
            move: {
              speed: 15,
              direction: 'top-right',
              collisions: false,
              outModes: {
                default: 'out',
                left: 'out',
                right: 'out',
                bottom: 'out',
                top: 'out',
              },


            },
            opacity: {
              value: 0.7,
              random: true,
              animation: {
                enable: false
              }

            },
            shape: {
              type: 'circle',
              polygon: {

                nb_sides: 6,

              },

            },

            color: { value: 'rgba(77,217,117,1)' },

            number: {
              density: {
                enable: true,
                value_area: 2400,
              },
              value: 60,
            },
            size: {
              value: 4,
              random: true,
            },
          },
        }}
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          width: '100%',
          height: '100%',
          zIndex: 1
        }}
      />
    </div>


  </>
  );
};

export default FooterLayout;
