import React, { useCallback, useRef, useEffect, useState } from 'react';

import { Container, Content, Background, AnimatedDiv2 } from './styles';

import CientificLibraryComponent from '../../components/CientificLibrary';
import { useSocket } from '../../hooks/Socket';


const CientificLibrary: React.FC = () => {
  const { socket } = useSocket();


  return (
    <Container>
      <CientificLibraryComponent />
    </Container>
  );
};
export default CientificLibrary;
