import React, { useCallback, useRef, useEffect, useState } from 'react';
import {
  FiLogIn,
  FiMail,
  FiLock,
  FiKey,
  FiMessageCircle,
} from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { Link, useHistory } from 'react-router-dom';
import { rgb } from 'polished';
import {
  FaConnectdevelop,
  FaLock,
  FaHandPointUp,
  FaCertificate,
  FaTicketAlt,
  FaVideo,
  FaAward,
  FaSlideshare,
  FaKey,
} from 'react-icons/fa';
import { MdColorLens, MdMessage } from 'react-icons/md';
import { AiFillSafetyCertificate } from 'react-icons/ai';
import { IconType } from 'react-icons/lib';
import { dadosOrganizacao, logo } from '../../config';
import { ContactIcons, WhatsappIcon } from '../../components/Menu';
import Whatsapp from '../../components/Whatsapp';

import { Container, ContainerWhite } from './styles';

import ShowDiv from '../../components/ShowOnScroll';
import VideoBackground from '../../components/VideoBackground';
import TextPopper from '../../components/TextPopper';
import Scroller from '../../components/Scroller';

import Vimeo from '../../components/Vimeo';

interface SignInData {
  email: string;
  password: string;
}

interface IPublicData {
  icon: IconType;
  title: string;
  text: string;
}

const Termos: React.FC = () => {
  return (
    <>
      <ContainerWhite id="termos">
        <div className="p50 p600" style={{ textAlign: 'justify' }}>
          <h2> POLÍTICA DE PRIVACIDADE</h2>
          <br />
          <br />
          <strong>1. Informações gerais</strong>
          <br />
          A presente Política de Privacidade contém informações a respeito do
          modo como tratamos, total ou parcialmente, de forma automatizada ou
          não, os dados pessoais dos usuários que acessam nosso site. Seu
          objetivo é esclarecer os interessados acerca dos tipos de dados que
          são coletados, dos motivos da coleta e da forma como o usuário poderá
          atualizar, gerenciar ou excluir estas informações. Esta Política de
          Privacidade foi elaborada em conformidade com a Lei Federal n. 12.965
          de 23 de abril de 2014 (Marco Civil da Internet), com a Lei Federal n.
          13.709, de 14 de agosto de 2018 (Lei de Proteção de Dados Pessoais),
          com a LGPD - LEI Nº 13.709, DE 14 DE AGOSTO DE 2018 e com o
          Regulamento UE n. 2016/679 de 27 de abril de 2016 (Regulamento Geral
          Europeu de Proteção de Dados Pessoais - RGDP). Esta Política de
          Privacidade poderá ser atualizada em decorrência de eventual
          atualização normativa, razão pela qual se convida o usuário a
          consultar periodicamente esta seção.
          <br />
          <br />
          <strong>2. Direitos do usuário</strong>
          <br />
          O site se compromete a cumprir as normas previstas na LGPD, em
          respeito aos seguintes princípios:
          <br />
          <br />
          - Os dados pessoais do usuário serão processados de forma lícita, leal
          e transparente (licitude, lealdade e transparência);
          <br />
          <br />
          - Os dados pessoais do usuário serão coletados apenas para finalidades
          determinadas, explícitas e legítimas, não podendo ser tratados
          posteriormente de uma forma incompatível com essas finalidades
          (limitação das finalidades);
          <br />
          <br />
          - Os dados pessoais do usuário serão coletados de forma adequada,
          pertinente e limitada às necessidades do objetivo para os quais eles
          são processados (minimização dos dados);
          <br />
          <br />
          - Os dados pessoais do usuário serão exatos e atualizados sempre que
          necessário, de maneira que os dados inexatos sejam apagados ou
          retificados quando possível (exatidão);
          <br />
          <br />
          - Os dados pessoais do usuário serão conservados de uma forma que
          permita a identificação dos titulares dos dados apenas durante o
          período necessário para as finalidades para as quais são tratados
          (limitação da conservação);
          <br />
          <br />
          - Os dados pessoais do usuário serão tratados de forma segura,
          protegidos do tratamento não autorizado ou ilícito e contra a sua
          perda, destruição ou danificação acidental, adotando as medidas
          técnicas ou organizativas adequadas (integridade e confidencialidade).
          <br />
          <br />
          O usuário do site possui os seguintes direitos, conferidos pela Lei de
          Proteção de Dados Pessoais e pela LGPD:
          <br />
          <br />
          - Direito de confirmação e acesso: é o direito do usuário de obter do
          site a confirmação de que os dados pessoais que lhe digam respeito são
          ou não objeto de tratamento e, se for esse o caso, o direito de
          acessar os seus dados pessoais;
          <br />
          <br />
          - Direito de retificação: é o direito do usuário de obter do site, sem
          demora injustificada, a retificação dos dados pessoais inexatos que
          lhe digam respeito;
          <br />
          <br />
          - Direito à eliminação dos dados (direito ao esquecimento): é o
          direito do usuário de ter seus dados apagados do site;
          <br />
          <br />
          - Direito à limitação do tratamento dos dados: é o direito do usuário
          de limitar o tratamento de seus dados pessoais, podendo obtê-la quando
          contesta a exatidão dos dados, quando o tratamento for ilícito, quando
          o site não precisar mais dos dados para as finalidades propostas e
          quando tiver se oposto ao tratamento dos dados e em caso de tratamento
          de dados desnecessários;
          <br />
          <br />
          - Direito de oposição: é o direito do usuário de, a qualquer momento,
          se opor por motivos relacionados com a sua situação particular, ao
          tratamento dos dados pessoais que lhe digam respeito, podendo se opor
          ainda ao uso de seus dados pessoais para definição de perfil de
          marketing (profiling);
          <br />
          <br />
          - Direito de portabilidade dos dados: é o direito do usuário de
          receber os dados pessoais que lhe digam respeito e que tenha fornecido
          ao site, num formato estruturado, de uso corrente e de leitura
          automática, e o direito de transmitir esses dados a outro site;
          <br />
          <br />
          - Direito de não ser submetido a decisões automatizadas: é o direito
          do usuário de não ficar sujeito a nenhuma decisão tomada
          exclusivamente com base no tratamento automatizado, incluindo a
          definição de perfis (profiling), que produza efeitos na sua esfera
          jurídica ou que o afete significativamente de forma similar.
          <br />
          <br />
          O usuário poderá exercer os seus direitos por meio de comunicação
          escrita enviada ao site com o assunto
          "LGPD-https://encontrodigital.com.br", especificando:
          <br />
          <br />
          - Nome completo ou razão social, número do CPF (Cadastro de Pessoas
          Físicas, da Receita Federal do Brasil) ou CNPJ (Cadastro Nacional de
          Pessoa Jurídica, da Receita Federal do Brasil) e endereço de e-mail do
          usuário e, se for o caso, do seu representante;
          <br />
          <br />
          - Direito que deseja exercer junto ao site;
          <br />
          <br />
          - Data do pedido e assinatura do usuário;
          <br />
          <br />
          - Todo documento que possa demonstrar ou justificar o exercício de seu
          direito.
          <br />
          <br />
          O pedido deverá ser enviado ao e-mail: comercial@encontrodigital.com.br, ou
          por correio, ao seguinte endereço:
          <br />
          <br />
          ENCONTRO DIGITAL TECNOLOGIA E EVENTOS LTDA
          <br />
          Rua Estácio pessoa 50 – Porto Alegre/RS - CEP 91040-340

          <br />
          <br />
          O usuário será informado em caso de retificação ou eliminação dos seus
          dados.
          <br />
          <br />
          <strong>3. Dever de não fornecer dados de terceiros</strong>
          <br />
          Durante a utilização do site, a fim de resguardar e de proteger os
          direitos de terceiros, o usuário do site deverá fornecer somente seus
          dados pessoais, e não os de terceiros.
          <br />
          <br />
          <strong>4. Informações coletadas</strong>
          <br />
          A coleta de dados dos usuários se dará em conformidade com o disposto
          nesta Política de Privacidade e dependerá do consentimento do usuário,
          sendo este dispensável somente nas hipóteses previstas no art. 11,
          inciso II, da Lei de Proteção de Dados Pessoais.
          <br />
          <br />
          <strong>4.1. Tipos de dados coletados</strong>
          <br />
          4.1.1. Dados de identificação do usuário para realização de cadastro
          <br />
          <br />
          A utilização, pelo usuário, de determinadas funcionalidades do site
          dependerá de cadastro, sendo que, nestes casos, os seguintes dados do
          usuário poderão ser coletados e armazenados:
          <br />
          <br />
          - Nome completo
          <br />
          <br />
          - Endereço de e-mail
          <br />
          <br />
          - Endereço postal
          <br />
          <br />
          - Número de telefone
          <br />
          <br />
          - Número de CPF
          <br />
          <br />
          - Número de CNPJ
          <br />
          <br />
          - Número de Passaporte no caso de estrangeiros
          <br />
          <br />
          - Categoria
          <br />
          <br />
          - Especialidade
          <br />
          <br />
          - Empresa
          <br />
          <br />
          - Cargo
          <br />
          <br />
          - Entidade
          <br />
          <br />
          - Data de nascimento
          <br />
          <br />
          <strong>4.1.2. Dados informados no formulário de contato</strong>
          <br />
          Os dados eventualmente informados pelo usuário que utilizar o
          formulário de contato disponibilizado no site, incluindo o teor da
          mensagem enviada, serão coletados e armazenados.
          <br />
          <br />
          {' '}
          <br />
          <br />
          <strong>
            4.1.3. Dados relacionados à execução de contratos firmados com o
            usuário
          </strong>
          Para a execução de contrato de compra e venda ou de prestação de
          serviços eventualmente firmado entre o site e o usuário, poderão ser
          coletados e armazenados outros dados relacionados ou necessários a sua
          execução, incluindo o teor de eventuais comunicações tidas com o
          usuário.
          <br />
          <br />
          <strong>4.1.4. Registros de acesso</strong>
          <br />
          Em atendimento às disposições do art. 15, caput e parágrafos, da Lei
          Federal n. 12.965/2014 (Marco Civil da Internet), os registros de
          acesso do usuário serão coletados e armazenados por, pelo menos, seis
          meses.
          <br />
          <br />
          <strong>4.1.5. Newsletter</strong>
          <br />
          O endereço de e-mail cadastrado pelo usuário que optar por se
          inscrever em nossa Newsletter será coletado e armazenado até que o
          usuário solicite seu descadastro.
          <br />
          <br />
          <strong>4.1.6. Dados sensíveis</strong>
          <br />
          Não serão coletados dados sensíveis dos usuários, assim entendidos
          aqueles definidos nos arts. 9º e 10 do RGPD e nos arts. 11 e seguintes
          da Lei de Proteção de Dados Pessoais. Assim, dentre outros, não haverá
          coleta dos seguintes dados:
          <br />
          <br />
          - dados que revelem a origem racial ou étnica, as opiniões políticas,
          as convicções religiosas ou filosóficas, ou a filiação sindical do
          usuário;
          <br />
          <br />
          - dados genéticos;
          <br />
          <br />
          - dados biométricos para identificar uma pessoa de forma inequívoca;
          <br />
          <br />
          - dados relativos à saúde do usuário;
          <br />
          <br />
          - dados relativos à vida sexual ou à orientação sexual do usuário;
          <br />
          <br />
          - dados relacionados a condenações penais ou a infrações ou com
          medidas de segurança conexas.
          <br />
          <br />
          <strong>4.1.7. Coleta de dados não previstos expressamente</strong>
          <br />
          Eventualmente, outros tipos de dados não previstos expressamente nesta
          Política de Privacidade poderão ser coletados, desde que sejam
          fornecidos com o consentimento do usuário, ou, ainda, que a coleta
          seja permitida ou imposta por lei.
          <br />
          <br />
          <strong>
            4.2. Fundamento jurídico para o tratamento dos dados pessoais
          </strong>
          <br />
          Ao utilizar os serviços do site, o usuário está consentindo com a
          presente Política de Privacidade.
          <br />
          <br />
          O usuário tem o direito de retirar seu consentimento a qualquer
          momento, não comprometendo a licitude do tratamento de seus dados
          pessoais antes da retirada. A retirada do consentimento poderá ser
          feita pelo e-mail: comercial@encontrodigital.com.br, ou por correio enviado
          ao seguinte endereço:
          <br />
          <br />
          Rua Estácio pessoa 50 – Porto Alegre/RS - CEP 91040-340
          <br />
          <br />
          O consentimento dos relativamente ou absolutamente incapazes,
          especialmente de crianças menores de 16 (dezesseis) anos, apenas
          poderá ser feito, respectivamente, se devidamente assistidos ou
          representados.
          <br />
          <br />
          Poderão ainda ser coletados dados pessoais necessários para a execução
          e cumprimento dos serviços contratados pelo usuário no site.
          <br />
          <br />
          O tratamento de dados pessoais sem o consentimento do usuário apenas
          será realizado em razão de interesse legítimo ou para as hipóteses
          previstas em lei, ou seja, dentre outras, as seguintes:
          <br />
          <br />
          - para o cumprimento de obrigação legal ou regulatória pelo
          controlador;
          <br />
          <br />
          - para a realização de estudos por órgão de pesquisa, garantida,
          sempre que possível, a anonimização dos dados pessoais;
          <br />
          <br />
          - quando necessário para a execução de contrato ou de procedimentos
          preliminares relacionados a contrato do qual seja parte o usuário, a
          pedido do titular dos dados;
          <br />
          <br />
          - para o exercício regular de direitos em processo judicial,
          administrativo ou arbitral, esse último nos termos da Lei nº 9.307, de
          23 de setembro de 1996 (Lei de Arbitragem);
          <br />
          <br />
          - para a proteção da vida ou da incolumidade física do titular dos
          dados ou de terceiro;
          <br />
          <br />
          - para a tutela da saúde, em procedimento realizado por profissionais
          da área da saúde ou por entidades sanitárias;
          <br />
          <br />
          - quando necessário para atender aos interesses legítimos do
          controlador ou de terceiro, exceto no caso de prevalecerem direitos e
          liberdades fundamentais do titular dos dados que exijam a proteção dos
          dados pessoais;
          <br />
          <br />
          - para a proteção do crédito, inclusive quanto ao disposto na
          legislação pertinente.
          <br />
          <br />
          <strong>4.3. Finalidades do tratamento dos dados pessoais</strong>
          <br />
          Os dados pessoais do usuário coletados pelo site têm por finalidade
          facilitar, agilizar e cumprir os compromissos estabelecidos com o
          usuário e a fazer cumprir as solicitações realizadas por meio do
          preenchimento de formulários.
          <br />
          <br />
          Os dados pessoais poderão ser utilizados também com uma finalidade
          comercial, para personalizar o conteúdo oferecido ao usuário, bem como
          para dar subsídio ao site para a melhora da qualidade e funcionamento
          de seus serviços.
          <br />
          <br />
          O site recolhe os dados do usuário para que seja realizada definição
          de perfis (profiling), ou seja, tratamento automatizado de dados
          pessoais que consista em utilizar estes dados para avaliar certos
          aspectos pessoais do usuário, principalmente para analisar ou prever
          características relacionadas ao seu desempenho profissional, a sua
          situação econômica, saúde, preferências pessoais, interesses,
          fiabilidade, comportamento, localização ou deslocamento.
          <br />
          <br />
          Os dados de cadastro serão utilizados para permitir o acesso do
          usuário a determinados conteúdos do site, exclusivos para usuários
          cadastrados.
          <br />
          <br />
          A coleta de dados relacionados ou necessários à execução de um
          contrato de compra e venda ou de prestação de serviços eventualmente
          firmado com o usuário terá a finalidade de conferir às partes
          segurança jurídica, além de facilitar e viabilizar a conclusão do
          negócio.
          <br />
          <br />
          O tratamento de dados pessoais para finalidades não previstas nesta
          Política de Privacidade somente ocorrerá mediante comunicação prévia
          ao usuário, sendo que, em qualquer caso, os direitos e obrigações aqui
          previstos permanecerão aplicáveis.
          <br />
          <br />
          <strong>4.4. Prazo de conservação dos dados pessoais</strong>
          <br />
          Os dados pessoais do usuário serão conservados por um período não
          superior ao exigido para cumprir os objetivos em razão dos quais eles
          são processados.
          <br />
          <br />
          O período de conservação dos dados são definidos de acordo com os
          seguintes critérios:
          <br />
          <br />
          Os dados serão armazenados pelo tempo necessário para a prestação dos
          serviços fornecidos pelo site, que pode variar de 1 a 12 meses, de
          acordo com o status do pedido da pessoa.
          <br />
          <br />
          Os dados pessoais dos usuários apenas poderão ser conservados após o
          término de seu tratamento nas seguintes hipóteses:
          <br />
          <br />
          - para o cumprimento de obrigação legal ou regulatória pelo
          controlador;
          <br />
          <br />
          - para estudo por órgão de pesquisa, garantida, sempre que possível, a
          anonimização dos dados pessoais;
          <br />
          <br />
          - para a transferência a terceiro, desde que respeitados os requisitos
          de tratamento de dados dispostos na legislação;
          <br />
          <br />
          - para uso exclusivo do controlador, vedado seu acesso por terceiro, e
          desde que anonimizados os dados.
          <br />
          <br />
          <strong>4.5. Destinatários e transferência dos dados pessoais</strong>
          <br />
          Os dados pessoais do usuário poderão ser compartilhados com as
          seguintes pessoas ou empresas:
          <br />
          <br />
          <strong>Organizadora do evento e suas parceiras</strong>
          <br />
          {dadosOrganizacao}
          <br />
          <br />
          A transferência apenas poderá ser feita para outro país caso o país ou
          território em questão ou a organização internacional em causa
          assegurem um nível de proteção adequado dos dados do usuário.
          <br />
          <br />
          Caso não haja nível de proteção adequado, o site se compromete a
          garantir a proteção dos seus dados de acordo com as regras mais
          rigorosas, por meio de cláusulas contratuais específicas para
          determinada transferência, cláusulas-padrão contratuais, normas
          corporativas globais ou selos, certificados e códigos de conduta
          regularmente emitidos.
          <br />
          <br />
          <strong>5. Do tratamento dos dados pessoais</strong>
          <br />
          5.1. Do responsável pelo tratamento dos dados (data controller)
          <br />
          <br />
          O controlador, responsável pelo tratamento dos dados pessoais do
          usuário, é a pessoa física ou jurídica, a autoridade pública, a
          agência ou outro organismo que, individualmente ou em conjunto com
          outras, determina as finalidades e os meios de tratamento de dados
          pessoais.
          <br />
          <br />
          Neste site, o responsável pelo tratamento dos dados pessoais coletados
          é ENCONTRO DIGITAL TECNOLOGIA E EVENTOS LTDA LTDA, representada por Guilherme Thiesen da
          Silva, que poderá ser contactado pelo e-mail:
          comercial@encontrodigital.com.br ou no endereço:
          <br />
          Rua Estácio pessoa 50 – Porto Alegre/RS - CEP 91040-340

          <br />
          <br />
          <strong>
            5.2. Do operador de dados subcontratado (data processor)
          </strong>
          <br />
          O operador de dados subcontratado é a pessoa física ou jurídica, a
          autoridade pública, a agência ou outro organismo que trata os dados
          pessoais sob a supervisão do responsável pelo tratamento dos dados do
          usuário.
          <br />
          <br />
          Os dados pessoais do usuário serão tratados por Guilherme Thiesen da
          Silva, e-mail: guilherme@encontrodigital.com.br, com endereço à:
          <br />
          <br />
          Rua Estácio pessoa 50 – Porto Alegre/RS - CEP 91040-340
          <br />
          <br />
          <strong>
            5.3. Do encarregado de proteção de dados (data protection officer)
          </strong>
          <br />
          O encarregado de proteção de dados (data protection officer) é o
          profissional encarregado de informar, aconselhar e controlar o
          responsável pelo tratamento dos dados e o processador de dados
          subcontratado, bem como os trabalhadores que tratem os dados, a
          respeito das obrigações do site nos termos do RGDP, da Lei de Proteção
          de Dados Pessoais e de outras disposições de proteção de dados
          presentes na legislação nacional e internacional, em cooperação com a
          autoridade de controle competente.
          <br />
          <br />
          Neste site o encarregado de proteção de dados (data protection
          officer) é Guilherme Thiesen da Silva, que poderá ser contactado pelo
          e-mail: guilherme@encontrodigital.com.br.
          <br />
          <br />
          <strong>
            6. Segurança no tratamento dos dados pessoais do usuário
          </strong>
          <br />
          O site se compromete a aplicar as medidas técnicas e organizativas
          aptas a proteger os dados pessoais de acessos não autorizados e de
          situações de destruição, perda, alteração, comunicação ou difusão de
          tais dados.
          <br />
          <br />
          Para a garantia da segurança, serão adotadas soluções que levem em
          consideração: as técnicas adequadas; os custos de aplicação; a
          natureza, o âmbito, o contexto e as finalidades do tratamento; e os
          riscos para os direitos e liberdades do usuário.
          <br />
          <br />
          O site utiliza certificado SSL (Secure Socket Layer) que garante que
          os dados pessoais se transmitam de forma segura e confidencial, de
          maneira que a transmissão dos dados entre o servidor e o usuário, e em
          retroalimentação, ocorra de maneira totalmente cifrada ou encriptada.
          <br />
          <br />
          No entanto, o site se exime de responsabilidade por culpa exclusiva de
          terceiro, como em caso de ataque de hackers ou crackers, ou culpa
          exclusiva do usuário, como no caso em que ele mesmo transfere seus
          dados a terceiro. O site se compromete, ainda, a comunicar o usuário
          em prazo adequado caso ocorra algum tipo de violação da segurança de
          seus dados pessoais que possa lhe causar um alto risco para seus
          direitos e liberdades pessoais.
          <br />
          <br />
          A violação de dados pessoais é uma violação de segurança que provoque,
          de modo acidental ou ilícito, a destruição, a perda, a alteração, a
          divulgação ou o acesso não autorizados a dados pessoais transmitidos,
          conservados ou sujeitos a qualquer outro tipo de tratamento.
          <br />
          <br />
          Por fim, o site se compromete a tratar os dados pessoais do usuário
          com confidencialidade, dentro dos limites legais.
          <br />
          <br />
          <strong>7. Dados de navegação (cookies)</strong>
          <br />
          Cookies são pequenos arquivos de texto enviados pelo site ao
          computador do usuário e que nele ficam armazenados, com informações
          relacionadas à navegação do site.
          <br />
          <br />
          Por meio dos cookies, pequenas quantidades de informação são
          armazenadas pelo navegador do usuário para que nosso servidor possa
          lê-las posteriormente. Podem ser armazenados, por exemplo, dados sobre
          o dispositivo utilizado pelo usuário, bem como seu local e horário de
          acesso ao site.
          <br />
          <br />
          Os cookies não permitem que qualquer arquivo ou informação sejam
          extraídos do disco rígido do usuário, não sendo possível, ainda, que,
          por meio deles, se tenha acesso a informações pessoais que não tenham
          partido do usuário ou da forma como utiliza os recursos do site.
          <br />
          <br />
          É importante ressaltar que nem todo cookie contém informações que
          permitem a identificação do usuário, sendo que determinados tipos de
          cookies podem ser empregados simplesmente para que o site sejam
          carregado corretamente ou para que suas funcionalidades funcionem do
          modo esperado.
          <br />
          <br />
          As informações eventualmente armazenadas em cookies que permitam
          identificar um usuário são consideradas dados pessoais. Dessa forma,
          todas as regras previstas nesta Política de Privacidade também lhes
          são aplicáveis.
          <br />
          <br />
          <strong>7.1. Cookies do site</strong>
          <br />
          Os cookies do site são aqueles enviados ao computador ou dispositivo
          do usuário e administrador exclusivamente pelo site.
          <br />
          <br />
          As informações coletadas por meio destes cookies são utilizadas para
          melhorar e personalizar a experiência do usuário, sendo que alguns
          cookies podem, por exemplo, ser utilizados para lembrar as
          preferências e escolhas do usuário, bem como para o oferecimento de
          conteúdo personalizado.
          <br />
          <br />
          <strong>7.2. Cookies de terceiros</strong>
          <br />
          Alguns de nossos parceiros podem configurar cookies nos dispositivos
          dos usuário que acessam nosso site.
          <br />
          <br />
          Estes cookies, em geral, visam possibilitar que nossos parceiros
          possam oferecer seu conteúdo e seus serviços ao usuário que acessa
          nosso site de forma personalizada, por meio da obtenção de dados de
          navegação extraídos a partir de sua interação com o site.
          <br />
          <br />
          O usuário poderá obter mais informações sobre os cookies de terceiro e
          sobre a forma como os dados obtidos a partir dele são tratados, além
          de ter acesso à descrição dos cookies utilizados e de suas
          características, acessando o seguinte link:
          <br />
          <br />
          Google Analytics
          <br />
          <br />
          https://developers.google.com/analytics/devguides/collection/gajs/cookie-usage
          <br />
          <br />
          As entidades encarregadas da coleta dos cookies poderão ceder as
          informações obtidas a terceiros.
          <br />
          <br />
          <strong>7.3. Cookies de redes sociais</strong>
          <br />
          O site utiliza plugins de redes sociais, que permitem acessá-las a
          partir do site. Assim, ao fazê-lo, os cookies utilizados por elas
          poderão ser armazenados no navegador do usuário.
          <br />
          <br />
          Cada rede social possui sua própria política de privacidade e de
          proteção de dados pessoais, sendo as pessoas físicas ou jurídicas que
          as mantêm responsáveis pelos dados coletados e pelas práticas de
          privacidade adotadas.
          <br />
          <br />
          O usuário pode pesquisar, junto às redes sociais, informações sobre
          como seus dados pessoais são tratados. A título informativo,
          disponibilizamos os seguintes links, a partir dos quais poderão ser
          consultadas as políticas de privacidade e de cookies adotadas por
          algumas das principais redes sociais:
          <br />
          <br />
          Facebook: https://www.facebook.com/policies/cookies/
          <br />
          <br />
          Twitter: https://twitter.com/pt/privacy
          <br />
          <br />
          Instagram: https://help.instagram.com/1896641480634370?ref=ig
          <br />
          <br />
          Youtube: https://policies.google.com/privacy?hl=pt-BR&gl=pt
          <br />
          <br />
          Google+: https://policies.google.com/technologies/cookies?hl=pt
          <br />
          <br />
          Pinterest: https://policy.pinterest.com/pt-br/privacy-policy
          <br />
          <br />
          LinkedIn: https://www.linkedin.com/legal/cookie-policy?trk=hp-cookies
          <br />
          <br />
          <strong>7.4. Gestão dos cookies e configurações do navegador</strong>
          <br />
          O usuário poderá se opor ao registro de cookies pelo site, bastando
          que desative esta opção no seu próprio navegador ou aparelho.
          <br />
          <br />
          A desativação dos cookies, no entanto, pode afetar a disponibilidade
          de algumas ferramentas e funcionalidades do site, comprometendo seu
          correto e esperado funcionamento. Outra consequência possível é
          remoção das preferências do usuário que eventualmente tiverem sido
          salvas, prejudicando sua experiência.
          <br />
          <br />
          A seguir, são disponibilizados alguns links para as páginas de ajuda e
          suporte dos navegadores mais utilizados, que poderão ser acessadas
          pelo usuário interessado em obter mais informações sobre a gestão de
          cookies em seu navegador:
          <br />
          <br />
          Internet Explorer:
          <br />
          <br />
          https://support.microsoft.com/pt-br/help/17442/windows-internet-explorer-delete-manage-cookies
          <br />
          <br />
          Safari:
          <br />
          <br />
          https://support.apple.com/pt-br/guide/safari/sfri11471/mac
          <br />
          <br />
          Google Chrome:
          <br />
          <br />
          https://support.google.com/chrome/answer/95647?hl=pt-BR&hlrm=pt
          <br />
          <br />
          Mozila Firefox:
          <br />
          <br />
          https://support.mozilla.org/pt-BR/kb/ative-e-desative-os-cookies-que-os-sites-usam
          <br />
          <br />
          Opera:
          <br />
          <br />
          https://www.opera.com/help/tutorials/security/privacy/
          <br />
          <br />
          <strong>8. Reclamação a uma autoridade de controle</strong>
          <br />
          Sem prejuízo de qualquer outra via de recurso administrativo ou
          judicial, todos os titulares de dados têm direito a apresentar
          reclamação a uma autoridade de controle. A reclamação poderá ser feita
          à autoridade da sede do site, do país de residência habitual do
          usuário, do seu local de trabalho ou do local onde foi alegadamente
          praticada a infração.
          <br />
          <br />
          <strong>9. Das alterações</strong>
          <br />
          A presente versão desta Política de Privacidade foi atualizada pela
          última vez em: 26/03/2024.
          <br />
          <br />
          O editor se reserva o direito de modificar, a qualquer momento e sem
          qualquer aviso prévio, o site as presentes normas, especialmente para
          adaptá-las às evoluções do site Encontro Digital, seja pela
          disponibilização de novas funcionalidades, seja pela supressão ou
          modificação daquelas já existentes.
          <br />
          <br />
          Dessa forma, convida-se o usuário a consultar periodicamente esta
          página para verificar as atualizações.
          <br />
          <br />
          Ao utilizar o serviço após eventuais modificações, o usuário demonstra
          sua concordância com as novas normas. Caso discorde de alguma das
          modificações, deverá pedir, imediatamente, o cancelamento de sua conta
          e apresentar a sua ressalva ao serviço de atendimento, se assim o
          desejar.
          <br />
          <br />
          <strong>10. Do Direito aplicável e do foro</strong>
          <br />
          Para a solução das controvérsias decorrentes do presente instrumento,
          será aplicado integralmente o Direito brasileiro.
          <br />
          <br />
          Os eventuais litígios deverão ser apresentados no foro da comarca em
          que se encontra a sede do editor do site.
        </div>
      </ContainerWhite>
    </>
  );
};
export default Termos;
