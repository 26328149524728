export const countries = [
    {
        "label": "Afghanistan",
        "dialCode": "+93",
        "value": "AF",
        "flag": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "label": "Aland Islands",
        "dialCode": "+358",
        "value": "AX",
        "flag": "https://www.countryflags.io/AX/flat/64.png"
    },
    {
        "label": "Albania",
        "dialCode": "+355",
        "value": "AL",
        "flag": "https://www.countryflags.io/AL/flat/64.png"
    },
    {
        "label": "Algeria",
        "dialCode": "+213",
        "value": "DZ",
        "flag": "https://www.countryflags.io/DZ/flat/64.png"
    },
    {
        "label": "AmericanSamoa",
        "dialCode": "+1684",
        "value": "AS",
        "flag": "https://www.countryflags.io/AS/flat/64.png"
    },
    {
        "label": "Andorra",
        "dialCode": "+376",
        "value": "AD",
        "flag": "https://www.countryflags.io/AD/flat/64.png"
    },
    {
        "label": "Angola",
        "dialCode": "+244",
        "value": "AO",
        "flag": "https://www.countryflags.io/AO/flat/64.png"
    },
    {
        "label": "Anguilla",
        "dialCode": "+1264",
        "value": "AI",
        "flag": "https://www.countryflags.io/AI/flat/64.png"
    },
    {
        "label": "Antarctica",
        "dialCode": "+672",
        "value": "AQ",
        "flag": "https://www.countryflags.io/AQ/flat/64.png"
    },
    {
        "label": "Antigua and Barbuda",
        "dialCode": "+1268",
        "value": "AG",
        "flag": "https://www.countryflags.io/AG/flat/64.png"
    },
    {
        "label": "Argentina",
        "dialCode": "+54",
        "value": "AR",
        "flag": "https://www.countryflags.io/AR/flat/64.png"
    },
    {
        "label": "Armenia",
        "dialCode": "+374",
        "value": "AM",
        "flag": "https://www.countryflags.io/AM/flat/64.png"
    },
    {
        "label": "Aruba",
        "dialCode": "+297",
        "value": "AW",
        "flag": "https://www.countryflags.io/AW/flat/64.png"
    },
    {
        "label": "Ascension Island",
        "dialCode": "+247",
        "value": "AC",
        "flag": "https://www.countryflags.io/AC/flat/64.png"
    },
    {
        "label": "Australia",
        "dialCode": "+61",
        "value": "AU",
        "flag": "https://www.countryflags.io/AU/flat/64.png"
    },
    {
        "label": "Austria",
        "dialCode": "+43",
        "value": "AT",
        "flag": "https://www.countryflags.io/AT/flat/64.png"
    },
    {
        "label": "Azerbaijan",
        "dialCode": "+994",
        "value": "AZ",
        "flag": "https://www.countryflags.io/AZ/flat/64.png"
    },
    {
        "label": "Bahamas",
        "dialCode": "+1242",
        "value": "BS",
        "flag": "https://www.countryflags.io/BS/flat/64.png"
    },
    {
        "label": "Bahrain",
        "dialCode": "+973",
        "value": "BH",
        "flag": "https://www.countryflags.io/BH/flat/64.png"
    },
    {
        "label": "Bangladesh",
        "dialCode": "+880",
        "value": "BD",
        "flag": "https://www.countryflags.io/BD/flat/64.png"
    },
    {
        "label": "Barbados",
        "dialCode": "+1246",
        "value": "BB",
        "flag": "https://www.countryflags.io/BB/flat/64.png"
    },
    {
        "label": "Belarus",
        "dialCode": "+375",
        "value": "BY",
        "flag": "https://www.countryflags.io/BY/flat/64.png"
    },
    {
        "label": "Belgium",
        "dialCode": "+32",
        "value": "BE",
        "flag": "https://www.countryflags.io/BE/flat/64.png"
    },
    {
        "label": "Belize",
        "dialCode": "+501",
        "value": "BZ",
        "flag": "https://www.countryflags.io/BZ/flat/64.png"
    },
    {
        "label": "Benin",
        "dialCode": "+229",
        "value": "BJ",
        "flag": "https://www.countryflags.io/BJ/flat/64.png"
    },
    {
        "label": "Bermuda",
        "dialCode": "+1441",
        "value": "BM",
        "flag": "https://www.countryflags.io/BM/flat/64.png"
    },
    {
        "label": "Bhutan",
        "dialCode": "+975",
        "value": "BT",
        "flag": "https://www.countryflags.io/BT/flat/64.png"
    },
    {
        "label": "Bolivia",
        "dialCode": "+591",
        "value": "BO",
        "flag": "https://www.countryflags.io/BO/flat/64.png"
    },
    {
        "label": "Bosnia and Herzegovina",
        "dialCode": "+387",
        "value": "BA",
        "flag": "https://www.countryflags.io/BA/flat/64.png"
    },
    {
        "label": "Botswana",
        "dialCode": "+267",
        "value": "BW",
        "flag": "https://www.countryflags.io/BW/flat/64.png"
    },
    {
        "label": "Brasil",
        "dialCode": "+55",
        "value": "BR",
        "flag": "https://www.countryflags.io/BR/flat/64.png"
    },
    {
        "label": "British Indian Ocean Territory",
        "dialCode": "+246",
        "value": "IO",
        "flag": "https://www.countryflags.io/IO/flat/64.png"
    },
    {
        "label": "Brunei Darussalam",
        "dialCode": "+673",
        "value": "BN",
        "flag": "https://www.countryflags.io/BN/flat/64.png"
    },
    {
        "label": "Bulgaria",
        "dialCode": "+359",
        "value": "BG",
        "flag": "https://www.countryflags.io/BG/flat/64.png"
    },
    {
        "label": "Burkina Faso",
        "dialCode": "+226",
        "value": "BF",
        "flag": "https://www.countryflags.io/BF/flat/64.png"
    },
    {
        "label": "Burundi",
        "dialCode": "+257",
        "value": "BI",
        "flag": "https://www.countryflags.io/BI/flat/64.png"
    },
    {
        "label": "Cambodia",
        "dialCode": "+855",
        "value": "KH",
        "flag": "https://www.countryflags.io/KH/flat/64.png"
    },
    {
        "label": "Cameroon",
        "dialCode": "+237",
        "value": "CM",
        "flag": "https://www.countryflags.io/CM/flat/64.png"
    },
    {
        "label": "Canada",
        "dialCode": "+1",
        "value": "CA",
        "flag": "https://www.countryflags.io/CA/flat/64.png"
    },
    {
        "label": "Cape Verde",
        "dialCode": "+238",
        "value": "CV",
        "flag": "https://www.countryflags.io/CV/flat/64.png"
    },
    {
        "label": "Cayman Islands",
        "dialCode": "+1345",
        "value": "KY",
        "flag": "https://www.countryflags.io/KY/flat/64.png"
    },
    {
        "label": "Central African Republic",
        "dialCode": "+236",
        "value": "CF",
        "flag": "https://www.countryflags.io/CF/flat/64.png"
    },
    {
        "label": "Chad",
        "dialCode": "+235",
        "value": "TD",
        "flag": "https://www.countryflags.io/TD/flat/64.png"
    },
    {
        "label": "Chile",
        "dialCode": "+56",
        "value": "CL",
        "flag": "https://www.countryflags.io/CL/flat/64.png"
    },
    {
        "label": "China",
        "dialCode": "+86",
        "value": "CN",
        "flag": "https://www.countryflags.io/CN/flat/64.png"
    },
    {
        "label": "Christmas Island",
        "dialCode": "+61",
        "value": "CX",
        "flag": "https://www.countryflags.io/CX/flat/64.png"
    },
    {
        "label": "Cocos (Keeling) Islands",
        "dialCode": "+61",
        "value": "CC",
        "flag": "https://www.countryflags.io/CC/flat/64.png"
    },
    {
        "label": "Colombia",
        "dialCode": "+57",
        "value": "CO",
        "flag": "https://www.countryflags.io/CO/flat/64.png"
    },
    {
        "label": "Comoros",
        "dialCode": "+269",
        "value": "KM",
        "flag": "https://www.countryflags.io/KM/flat/64.png"
    },
    {
        "label": "Congo",
        "dialCode": "+242",
        "value": "CG",
        "flag": "https://www.countryflags.io/CG/flat/64.png"
    },
    {
        "label": "Cook Islands",
        "dialCode": "+682",
        "value": "CK",
        "flag": "https://www.countryflags.io/CK/flat/64.png"
    },
    {
        "label": "Costa Rica",
        "dialCode": "+506",
        "value": "CR",
        "flag": "https://www.countryflags.io/CR/flat/64.png"
    },
    {
        "label": "Croatia",
        "dialCode": "+385",
        "value": "HR",
        "flag": "https://www.countryflags.io/HR/flat/64.png"
    },
    {
        "label": "Cuba",
        "dialCode": "+53",
        "value": "CU",
        "flag": "https://www.countryflags.io/CU/flat/64.png"
    },
    {
        "label": "Cyprus",
        "dialCode": "+357",
        "value": "CY",
        "flag": "https://www.countryflags.io/CY/flat/64.png"
    },
    {
        "label": "Czech Republic",
        "dialCode": "+420",
        "value": "CZ",
        "flag": "https://www.countryflags.io/CZ/flat/64.png"
    },
    {
        "label": "Democratic Republic of the Congo",
        "dialCode": "+243",
        "value": "CD",
        "flag": "https://www.countryflags.io/CD/flat/64.png"
    },
    {
        "label": "Denmark",
        "dialCode": "+45",
        "value": "DK",
        "flag": "https://www.countryflags.io/DK/flat/64.png"
    },
    {
        "label": "Djibouti",
        "dialCode": "+253",
        "value": "DJ",
        "flag": "https://www.countryflags.io/DJ/flat/64.png"
    },
    {
        "label": "Dominica",
        "dialCode": "+1767",
        "value": "DM",
        "flag": "https://www.countryflags.io/DM/flat/64.png"
    },
    {
        "label": "Dominican Republic",
        "dialCode": "+1849",
        "value": "DO",
        "flag": "https://www.countryflags.io/DO/flat/64.png"
    },
    {
        "label": "Ecuador",
        "dialCode": "+593",
        "value": "EC",
        "flag": "https://www.countryflags.io/EC/flat/64.png"
    },
    {
        "label": "Egypt",
        "dialCode": "+20",
        "value": "EG",
        "flag": "https://www.countryflags.io/EG/flat/64.png"
    },
    {
        "label": "El Salvador",
        "dialCode": "+503",
        "value": "SV",
        "flag": "https://www.countryflags.io/SV/flat/64.png"
    },
    {
        "label": "Equatorial Guinea",
        "dialCode": "+240",
        "value": "GQ",
        "flag": "https://www.countryflags.io/GQ/flat/64.png"
    },
    {
        "label": "Eritrea",
        "dialCode": "+291",
        "value": "ER",
        "flag": "https://www.countryflags.io/ER/flat/64.png"
    },
    {
        "label": "Estonia",
        "dialCode": "+372",
        "value": "EE",
        "flag": "https://www.countryflags.io/EE/flat/64.png"
    },
    {
        "label": "Eswatini",
        "dialCode": "+268",
        "value": "SZ",
        "flag": "https://www.countryflags.io/SZ/flat/64.png"
    },
    {
        "label": "Ethiopia",
        "dialCode": "+251",
        "value": "ET",
        "flag": "https://www.countryflags.io/ET/flat/64.png"
    },
    {
        "label": "Falkland Islands (Malvinas)",
        "dialCode": "+500",
        "value": "FK",
        "flag": "https://www.countryflags.io/FK/flat/64.png"
    },
    {
        "label": "Faroe Islands",
        "dialCode": "+298",
        "value": "FO",
        "flag": "https://www.countryflags.io/FO/flat/64.png"
    },
    {
        "label": "Fiji",
        "dialCode": "+679",
        "value": "FJ",
        "flag": "https://www.countryflags.io/FJ/flat/64.png"
    },
    {
        "label": "Finland",
        "dialCode": "+358",
        "value": "FI",
        "flag": "https://www.countryflags.io/FI/flat/64.png"
    },
    {
        "label": "France",
        "dialCode": "+33",
        "value": "FR",
        "flag": "https://www.countryflags.io/FR/flat/64.png"
    },
    {
        "label": "French Guiana",
        "dialCode": "+594",
        "value": "GF",
        "flag": "https://www.countryflags.io/GF/flat/64.png"
    },
    {
        "label": "French Polynesia",
        "dialCode": "+689",
        "value": "PF",
        "flag": "https://www.countryflags.io/PF/flat/64.png"
    },
    {
        "label": "Gabon",
        "dialCode": "+241",
        "value": "GA",
        "flag": "https://www.countryflags.io/GA/flat/64.png"
    },
    {
        "label": "Gambia",
        "dialCode": "+220",
        "value": "GM",
        "flag": "https://www.countryflags.io/GM/flat/64.png"
    },
    {
        "label": "Georgia",
        "dialCode": "+995",
        "value": "GE",
        "flag": "https://www.countryflags.io/GE/flat/64.png"
    },
    {
        "label": "Germany",
        "dialCode": "+49",
        "value": "DE",
        "flag": "https://www.countryflags.io/DE/flat/64.png"
    },
    {
        "label": "Ghana",
        "dialCode": "+233",
        "value": "GH",
        "flag": "https://www.countryflags.io/GH/flat/64.png"
    },
    {
        "label": "Gibraltar",
        "dialCode": "+350",
        "value": "GI",
        "flag": "https://www.countryflags.io/GI/flat/64.png"
    },
    {
        "label": "Greece",
        "dialCode": "+30",
        "value": "GR",
        "flag": "https://www.countryflags.io/GR/flat/64.png"
    },
    {
        "label": "Greenland",
        "dialCode": "+299",
        "value": "GL",
        "flag": "https://www.countryflags.io/GL/flat/64.png"
    },
    {
        "label": "Grenada",
        "dialCode": "+1473",
        "value": "GD",
        "flag": "https://www.countryflags.io/GD/flat/64.png"
    },
    {
        "label": "Guadeloupe",
        "dialCode": "+590",
        "value": "GP",
        "flag": "https://www.countryflags.io/GP/flat/64.png"
    },
    {
        "label": "Guam",
        "dialCode": "+1671",
        "value": "GU",
        "flag": "https://www.countryflags.io/GU/flat/64.png"
    },
    {
        "label": "Guatemala",
        "dialCode": "+502",
        "value": "GT",
        "flag": "https://www.countryflags.io/GT/flat/64.png"
    },
    {
        "label": "Guernsey",
        "dialCode": "+44",
        "value": "GG",
        "flag": "https://www.countryflags.io/GG/flat/64.png"
    },
    {
        "label": "Guinea",
        "dialCode": "+224",
        "value": "GN",
        "flag": "https://www.countryflags.io/GN/flat/64.png"
    },
    {
        "label": "Guinea-Bissau",
        "dialCode": "+245",
        "value": "GW",
        "flag": "https://www.countryflags.io/GW/flat/64.png"
    },
    {
        "label": "Guyana",
        "dialCode": "+592",
        "value": "GY",
        "flag": "https://www.countryflags.io/GY/flat/64.png"
    },
    {
        "label": "Haiti",
        "dialCode": "+509",
        "value": "HT",
        "flag": "https://www.countryflags.io/HT/flat/64.png"
    },
    {
        "label": "Holy See (Vatican City State)",
        "dialCode": "+379",
        "value": "VA",
        "flag": "https://www.countryflags.io/VA/flat/64.png"
    },
    {
        "label": "Honduras",
        "dialCode": "+504",
        "value": "HN",
        "flag": "https://www.countryflags.io/HN/flat/64.png"
    },
    {
        "label": "Hong Kong",
        "dialCode": "+852",
        "value": "HK",
        "flag": "https://www.countryflags.io/HK/flat/64.png"
    },
    {
        "label": "Hungary",
        "dialCode": "+36",
        "value": "HU",
        "flag": "https://www.countryflags.io/HU/flat/64.png"
    },
    {
        "label": "Iceland",
        "dialCode": "+354",
        "value": "IS",
        "flag": "https://www.countryflags.io/IS/flat/64.png"
    },
    {
        "label": "India",
        "dialCode": "+91",
        "value": "IN",
        "flag": "https://www.countryflags.io/IN/flat/64.png"
    },
    {
        "label": "Indonesia",
        "dialCode": "+62",
        "value": "ID",
        "flag": "https://www.countryflags.io/ID/flat/64.png"
    },
    {
        "label": "Iran",
        "dialCode": "+98",
        "value": "IR",
        "flag": "https://www.countryflags.io/IR/flat/64.png"
    },
    {
        "label": "Iraq",
        "dialCode": "+964",
        "value": "IQ",
        "flag": "https://www.countryflags.io/IQ/flat/64.png"
    },
    {
        "label": "Ireland",
        "dialCode": "+353",
        "value": "IE",
        "flag": "https://www.countryflags.io/IE/flat/64.png"
    },
    {
        "label": "Isle of Man",
        "dialCode": "+44",
        "value": "IM",
        "flag": "https://www.countryflags.io/IM/flat/64.png"
    },
    {
        "label": "Italy",
        "dialCode": "+39",
        "value": "IT",
        "flag": "https://www.countryflags.io/IT/flat/64.png"
    },
    {
        "label": "Ivory Coast / Cote d'Ivoire",
        "dialCode": "+225",
        "value": "CI",
        "flag": "https://www.countryflags.io/CI/flat/64.png"
    },
    {
        "label": "Jamaica",
        "dialCode": "+1876",
        "value": "JM",
        "flag": "https://www.countryflags.io/JM/flat/64.png"
    },
    {
        "label": "Japan",
        "dialCode": "+81",
        "value": "JP",
        "flag": "https://www.countryflags.io/JP/flat/64.png"
    },
    {
        "label": "Jersey",
        "dialCode": "+44",
        "value": "JE",
        "flag": "https://www.countryflags.io/JE/flat/64.png"
    },
    {
        "label": "Jordan",
        "dialCode": "+962",
        "value": "JO",
        "flag": "https://www.countryflags.io/JO/flat/64.png"
    },
    {
        "label": "Kazakhstan",
        "dialCode": "+77",
        "value": "KZ",
        "flag": "https://www.countryflags.io/KZ/flat/64.png"
    },
    {
        "label": "Kenya",
        "dialCode": "+254",
        "value": "KE",
        "flag": "https://www.countryflags.io/KE/flat/64.png"
    },
    {
        "label": "Kiribati",
        "dialCode": "+686",
        "value": "KI",
        "flag": "https://www.countryflags.io/KI/flat/64.png"
    },
    {
        "label": "Korea, Democratic People's Republic of Korea",
        "dialCode": "+850",
        "value": "KP",
        "flag": "https://www.countryflags.io/KP/flat/64.png"
    },
    {
        "label": "Korea, Republic of South Korea",
        "dialCode": "+82",
        "value": "KR",
        "flag": "https://www.countryflags.io/KR/flat/64.png"
    },
    {
        "label": "Kosovo",
        "dialCode": "+383",
        "value": "XK",
        "flag": "https://www.countryflags.io/XK/flat/64.png"
    },
    {
        "label": "Kuwait",
        "dialCode": "+965",
        "value": "KW",
        "flag": "https://www.countryflags.io/KW/flat/64.png"
    },
    {
        "label": "Kyrgyzstan",
        "dialCode": "+996",
        "value": "KG",
        "flag": "https://www.countryflags.io/KG/flat/64.png"
    },
    {
        "label": "Laos",
        "dialCode": "+856",
        "value": "LA",
        "flag": "https://www.countryflags.io/LA/flat/64.png"
    },
    {
        "label": "Latvia",
        "dialCode": "+371",
        "value": "LV",
        "flag": "https://www.countryflags.io/LV/flat/64.png"
    },
    {
        "label": "Lebanon",
        "dialCode": "+961",
        "value": "LB",
        "flag": "https://www.countryflags.io/LB/flat/64.png"
    },
    {
        "label": "Lesotho",
        "dialCode": "+266",
        "value": "LS",
        "flag": "https://www.countryflags.io/LS/flat/64.png"
    },
    {
        "label": "Liberia",
        "dialCode": "+231",
        "value": "LR",
        "flag": "https://www.countryflags.io/LR/flat/64.png"
    },
    {
        "label": "Libya",
        "dialCode": "+218",
        "value": "LY",
        "flag": "https://www.countryflags.io/LY/flat/64.png"
    },
    {
        "label": "Liechtenstein",
        "dialCode": "+423",
        "value": "LI",
        "flag": "https://www.countryflags.io/LI/flat/64.png"
    },
    {
        "label": "Lithuania",
        "dialCode": "+370",
        "value": "LT",
        "flag": "https://www.countryflags.io/LT/flat/64.png"
    },
    {
        "label": "Luxembourg",
        "dialCode": "+352",
        "value": "LU",
        "flag": "https://www.countryflags.io/LU/flat/64.png"
    },
    {
        "label": "Macau",
        "dialCode": "+853",
        "value": "MO",
        "flag": "https://www.countryflags.io/MO/flat/64.png"
    },
    {
        "label": "Madagascar",
        "dialCode": "+261",
        "value": "MG",
        "flag": "https://www.countryflags.io/MG/flat/64.png"
    },
    {
        "label": "Malawi",
        "dialCode": "+265",
        "value": "MW",
        "flag": "https://www.countryflags.io/MW/flat/64.png"
    },
    {
        "label": "Malaysia",
        "dialCode": "+60",
        "value": "MY",
        "flag": "https://www.countryflags.io/MY/flat/64.png"
    },
    {
        "label": "Maldives",
        "dialCode": "+960",
        "value": "MV",
        "flag": "https://www.countryflags.io/MV/flat/64.png"
    },
    {
        "label": "Mali",
        "dialCode": "+223",
        "value": "ML",
        "flag": "https://www.countryflags.io/ML/flat/64.png"
    },
    {
        "label": "Malta",
        "dialCode": "+356",
        "value": "MT",
        "flag": "https://www.countryflags.io/MT/flat/64.png"
    },
    {
        "label": "Marshall Islands",
        "dialCode": "+692",
        "value": "MH",
        "flag": "https://www.countryflags.io/MH/flat/64.png"
    },
    {
        "label": "Martinique",
        "dialCode": "+596",
        "value": "MQ",
        "flag": "https://www.countryflags.io/MQ/flat/64.png"
    },
    {
        "label": "Mauritania",
        "dialCode": "+222",
        "value": "MR",
        "flag": "https://www.countryflags.io/MR/flat/64.png"
    },
    {
        "label": "Mauritius",
        "dialCode": "+230",
        "value": "MU",
        "flag": "https://www.countryflags.io/MU/flat/64.png"
    },
    {
        "label": "Mayotte",
        "dialCode": "+262",
        "value": "YT",
        "flag": "https://www.countryflags.io/YT/flat/64.png"
    },
    {
        "label": "Mexico",
        "dialCode": "+52",
        "value": "MX",
        "flag": "https://www.countryflags.io/MX/flat/64.png"
    },
    {
        "label": "Micronesia, Federated States of Micronesia",
        "dialCode": "+691",
        "value": "FM",
        "flag": "https://www.countryflags.io/FM/flat/64.png"
    },
    {
        "label": "Moldova",
        "dialCode": "+373",
        "value": "MD",
        "flag": "https://www.countryflags.io/MD/flat/64.png"
    },
    {
        "label": "Monaco",
        "dialCode": "+377",
        "value": "MC",
        "flag": "https://www.countryflags.io/MC/flat/64.png"
    },
    {
        "label": "Mongolia",
        "dialCode": "+976",
        "value": "MN",
        "flag": "https://www.countryflags.io/MN/flat/64.png"
    },
    {
        "label": "Montenegro",
        "dialCode": "+382",
        "value": "ME",
        "flag": "https://www.countryflags.io/ME/flat/64.png"
    },
    {
        "label": "REM",
        "dialCode": "+1664",
        "value": "MS",
        "flag": "https://www.countryflags.io/MS/flat/64.png"
    },
    {
        "label": "Morocco",
        "dialCode": "+212",
        "value": "MA",
        "flag": "https://www.countryflags.io/MA/flat/64.png"
    },
    {
        "label": "Mozambique",
        "dialCode": "+258",
        "value": "MZ",
        "flag": "https://www.countryflags.io/MZ/flat/64.png"
    },
    {
        "label": "Myanmar",
        "dialCode": "+95",
        "value": "MM",
        "flag": "https://www.countryflags.io/MM/flat/64.png"
    },
    {
        "label": "Namibia",
        "dialCode": "+264",
        "value": "NA",
        "flag": "https://www.countryflags.io/NA/flat/64.png"
    },
    {
        "label": "Nauru",
        "dialCode": "+674",
        "value": "NR",
        "flag": "https://www.countryflags.io/NR/flat/64.png"
    },
    {
        "label": "Nepal",
        "dialCode": "+977",
        "value": "NP",
        "flag": "https://www.countryflags.io/NP/flat/64.png"
    },
    {
        "label": "Netherlands",
        "dialCode": "+31",
        "value": "NL",
        "flag": "https://www.countryflags.io/NL/flat/64.png"
    },
    {
        "label": "Netherlands Antilles",
        "dialCode": "+599",
        "value": "AN",
        "flag": "https://www.countryflags.io/AN/flat/64.png"
    },
    {
        "label": "New Caledonia",
        "dialCode": "+687",
        "value": "NC",
        "flag": "https://www.countryflags.io/NC/flat/64.png"
    },
    {
        "label": "New Zealand",
        "dialCode": "+64",
        "value": "NZ",
        "flag": "https://www.countryflags.io/NZ/flat/64.png"
    },
    {
        "label": "Nicaragua",
        "dialCode": "+505",
        "value": "NI",
        "flag": "https://www.countryflags.io/NI/flat/64.png"
    },
    {
        "label": "Niger",
        "dialCode": "+227",
        "value": "NE",
        "flag": "https://www.countryflags.io/NE/flat/64.png"
    },
    {
        "label": "Nigeria",
        "dialCode": "+234",
        "value": "NG",
        "flag": "https://www.countryflags.io/NG/flat/64.png"
    },
    {
        "label": "Niue",
        "dialCode": "+683",
        "value": "NU",
        "flag": "https://www.countryflags.io/NU/flat/64.png"
    },
    {
        "label": "Norfolk Island",
        "dialCode": "+672",
        "value": "NF",
        "flag": "https://www.countryflags.io/NF/flat/64.png"
    },
    {
        "label": "North Macedonia",
        "dialCode": "+389",
        "value": "MK",
        "flag": "https://www.countryflags.io/MK/flat/64.png"
    },
    {
        "label": "Northern Mariana Islands",
        "dialCode": "+1670",
        "value": "MP",
        "flag": "https://www.countryflags.io/MP/flat/64.png"
    },
    {
        "label": "Norway",
        "dialCode": "+47",
        "value": "NO",
        "flag": "https://www.countryflags.io/NO/flat/64.png"
    },
    {
        "label": "Oman",
        "dialCode": "+968",
        "value": "OM",
        "flag": "https://www.countryflags.io/OM/flat/64.png"
    },
    {
        "label": "Pakistan",
        "dialCode": "+92",
        "value": "PK",
        "flag": "https://www.countryflags.io/PK/flat/64.png"
    },
    {
        "label": "Palau",
        "dialCode": "+680",
        "value": "PW",
        "flag": "https://www.countryflags.io/PW/flat/64.png"
    },
    {
        "label": "Palestine",
        "dialCode": "+970",
        "value": "PS",
        "flag": "https://www.countryflags.io/PS/flat/64.png"
    },
    {
        "label": "Panama",
        "dialCode": "+507",
        "value": "PA",
        "flag": "https://www.countryflags.io/PA/flat/64.png"
    },
    {
        "label": "Papua New Guinea",
        "dialCode": "+675",
        "value": "PG",
        "flag": "https://www.countryflags.io/PG/flat/64.png"
    },
    {
        "label": "Paraguay",
        "dialCode": "+595",
        "value": "PY",
        "flag": "https://www.countryflags.io/PY/flat/64.png"
    },
    {
        "label": "Peru",
        "dialCode": "+51",
        "value": "PE",
        "flag": "https://www.countryflags.io/PE/flat/64.png"
    },
    {
        "label": "Philippines",
        "dialCode": "+63",
        "value": "PH",
        "flag": "https://www.countryflags.io/PH/flat/64.png"
    },
    {
        "label": "Pitcairn",
        "dialCode": "+872",
        "value": "PN",
        "flag": "https://www.countryflags.io/PN/flat/64.png"
    },
    {
        "label": "Poland",
        "dialCode": "+48",
        "value": "PL",
        "flag": "https://www.countryflags.io/PL/flat/64.png"
    },
    {
        "label": "Portugal",
        "dialCode": "+351",
        "value": "PT",
        "flag": "https://www.countryflags.io/PT/flat/64.png"
    },
    {
        "label": "Puerto Rico",
        "dialCode": "+1939",
        "value": "PR",
        "flag": "https://www.countryflags.io/PR/flat/64.png"
    },
    {
        "label": "Qatar",
        "dialCode": "+974",
        "value": "QA",
        "flag": "https://www.countryflags.io/QA/flat/64.png"
    },
    {
        "label": "Reunion",
        "dialCode": "+262",
        "value": "RE",
        "flag": "https://www.countryflags.io/RE/flat/64.png"
    },
    {
        "label": "Romania",
        "dialCode": "+40",
        "value": "RO",
        "flag": "https://www.countryflags.io/RO/flat/64.png"
    },
    {
        "label": "Russia",
        "dialCode": "+7",
        "value": "RU",
        "flag": "https://www.countryflags.io/RU/flat/64.png"
    },
    {
        "label": "Rwanda",
        "dialCode": "+250",
        "value": "RW",
        "flag": "https://www.countryflags.io/RW/flat/64.png"
    },
    {
        "label": "Saint Barthelemy",
        "dialCode": "+590",
        "value": "BL",
        "flag": "https://www.countryflags.io/BL/flat/64.png"
    },
    {
        "label": "Saint Helena, Ascension and Tristan Da Cunha",
        "dialCode": "+290",
        "value": "SH",
        "flag": "https://www.countryflags.io/SH/flat/64.png"
    },
    {
        "label": "Saint Kitts and Nevis",
        "dialCode": "+1869",
        "value": "KN",
        "flag": "https://www.countryflags.io/KN/flat/64.png"
    },
    {
        "label": "Saint Lucia",
        "dialCode": "+1758",
        "value": "LC",
        "flag": "https://www.countryflags.io/LC/flat/64.png"
    },
    {
        "label": "Saint Martin",
        "dialCode": "+590",
        "value": "MF",
        "flag": "https://www.countryflags.io/MF/flat/64.png"
    },
    {
        "label": "Saint Pierre and Miquelon",
        "dialCode": "+508",
        "value": "PM",
        "flag": "https://www.countryflags.io/PM/flat/64.png"
    },
    {
        "label": "Saint Vincent and the Grenadines",
        "dialCode": "+1784",
        "value": "VC",
        "flag": "https://www.countryflags.io/VC/flat/64.png"
    },
    {
        "label": "Samoa",
        "dialCode": "+685",
        "value": "WS",
        "flag": "https://www.countryflags.io/WS/flat/64.png"
    },
    {
        "label": "San Marino",
        "dialCode": "+378",
        "value": "SM",
        "flag": "https://www.countryflags.io/SM/flat/64.png"
    },
    {
        "label": "Sao Tome and Principe",
        "dialCode": "+239",
        "value": "ST",
        "flag": "https://www.countryflags.io/ST/flat/64.png"
    },
    {
        "label": "Saudi Arabia",
        "dialCode": "+966",
        "value": "SA",
        "flag": "https://www.countryflags.io/SA/flat/64.png"
    },
    {
        "label": "Senegal",
        "dialCode": "+221",
        "value": "SN",
        "flag": "https://www.countryflags.io/SN/flat/64.png"
    },
    {
        "label": "Serbia",
        "dialCode": "+381",
        "value": "RS",
        "flag": "https://www.countryflags.io/RS/flat/64.png"
    },
    {
        "label": "Seychelles",
        "dialCode": "+248",
        "value": "SC",
        "flag": "https://www.countryflags.io/SC/flat/64.png"
    },
    {
        "label": "Sierra Leone",
        "dialCode": "+232",
        "value": "SL",
        "flag": "https://www.countryflags.io/SL/flat/64.png"
    },
    {
        "label": "Singapore",
        "dialCode": "+65",
        "value": "SG",
        "flag": "https://www.countryflags.io/SG/flat/64.png"
    },
    {
        "label": "Sint Maarten",
        "dialCode": "+1721",
        "value": "SX",
        "flag": "https://www.countryflags.io/SX/flat/64.png"
    },
    {
        "label": "Slovakia",
        "dialCode": "+421",
        "value": "SK",
        "flag": "https://www.countryflags.io/SK/flat/64.png"
    },
    {
        "label": "Slovenia",
        "dialCode": "+386",
        "value": "SI",
        "flag": "https://www.countryflags.io/SI/flat/64.png"
    },
    {
        "label": "Solomon Islands",
        "dialCode": "+677",
        "value": "SB",
        "flag": "https://www.countryflags.io/SB/flat/64.png"
    },
    {
        "label": "Somalia",
        "dialCode": "+252",
        "value": "SO",
        "flag": "https://www.countryflags.io/SO/flat/64.png"
    },
    {
        "label": "South Africa",
        "dialCode": "+27",
        "value": "ZA",
        "flag": "https://www.countryflags.io/ZA/flat/64.png"
    },
    {
        "label": "South Georgia and the South Sandwich Islands",
        "dialCode": "+500",
        "value": "GS",
        "flag": "https://www.countryflags.io/GS/flat/64.png"
    },
    {
        "label": "South Sudan",
        "dialCode": "+211",
        "value": "SS",
        "flag": "https://www.countryflags.io/SS/flat/64.png"
    },
    {
        "label": "Spain",
        "dialCode": "+34",
        "value": "ES",
        "flag": "https://www.countryflags.io/ES/flat/64.png"
    },
    {
        "label": "Sri Lanka",
        "dialCode": "+94",
        "value": "LK",
        "flag": "https://www.countryflags.io/LK/flat/64.png"
    },
    {
        "label": "Sudan",
        "dialCode": "+249",
        "value": "SD",
        "flag": "https://www.countryflags.io/SD/flat/64.png"
    },
    {
        "label": "Suriname",
        "dialCode": "+597",
        "value": "SR",
        "flag": "https://www.countryflags.io/SR/flat/64.png"
    },
    {
        "label": "Svalbard and Jan Mayen",
        "dialCode": "+47",
        "value": "SJ",
        "flag": "https://www.countryflags.io/SJ/flat/64.png"
    },
    {
        "label": "Sweden",
        "dialCode": "+46",
        "value": "SE",
        "flag": "https://www.countryflags.io/SE/flat/64.png"
    },
    {
        "label": "Switzerland",
        "dialCode": "+41",
        "value": "CH",
        "flag": "https://www.countryflags.io/CH/flat/64.png"
    },
    {
        "label": "Syrian Arab Republic",
        "dialCode": "+963",
        "value": "SY",
        "flag": "https://www.countryflags.io/SY/flat/64.png"
    },
    {
        "label": "Taiwan",
        "dialCode": "+886",
        "value": "TW",
        "flag": "https://www.countryflags.io/TW/flat/64.png"
    },
    {
        "label": "Tajikistan",
        "dialCode": "+992",
        "value": "TJ",
        "flag": "https://www.countryflags.io/TJ/flat/64.png"
    },
    {
        "label": "Tanzania, United Republic of Tanzania",
        "dialCode": "+255",
        "value": "TZ",
        "flag": "https://www.countryflags.io/TZ/flat/64.png"
    },
    {
        "label": "Thailand",
        "dialCode": "+66",
        "value": "TH",
        "flag": "https://www.countryflags.io/TH/flat/64.png"
    },
    {
        "label": "Timor-Leste",
        "dialCode": "+670",
        "value": "TL",
        "flag": "https://www.countryflags.io/TL/flat/64.png"
    },
    {
        "label": "Togo",
        "dialCode": "+228",
        "value": "TG",
        "flag": "https://www.countryflags.io/TG/flat/64.png"
    },
    {
        "label": "Tokelau",
        "dialCode": "+690",
        "value": "TK",
        "flag": "https://www.countryflags.io/TK/flat/64.png"
    },
    {
        "label": "Tonga",
        "dialCode": "+676",
        "value": "TO",
        "flag": "https://www.countryflags.io/TO/flat/64.png"
    },
    {
        "label": "Trinidad and Tobago",
        "dialCode": "+1868",
        "value": "TT",
        "flag": "https://www.countryflags.io/TT/flat/64.png"
    },
    {
        "label": "Tunisia",
        "dialCode": "+216",
        "value": "TN",
        "flag": "https://www.countryflags.io/TN/flat/64.png"
    },
    {
        "label": "Turkey",
        "dialCode": "+90",
        "value": "TR",
        "flag": "https://www.countryflags.io/TR/flat/64.png"
    },
    {
        "label": "Turkmenistan",
        "dialCode": "+993",
        "value": "TM",
        "flag": "https://www.countryflags.io/TM/flat/64.png"
    },
    {
        "label": "Turks and Caicos Islands",
        "dialCode": "+1649",
        "value": "TC",
        "flag": "https://www.countryflags.io/TC/flat/64.png"
    },
    {
        "label": "Tuvalu",
        "dialCode": "+688",
        "value": "TV",
        "flag": "https://www.countryflags.io/TV/flat/64.png"
    },
    {
        "label": "Uganda",
        "dialCode": "+256",
        "value": "UG",
        "flag": "https://www.countryflags.io/UG/flat/64.png"
    },
    {
        "label": "Ukraine",
        "dialCode": "+380",
        "value": "UA",
        "flag": "https://www.countryflags.io/UA/flat/64.png"
    },
    {
        "label": "United Arab Emirates",
        "dialCode": "+971",
        "value": "AE",
        "flag": "https://www.countryflags.io/AE/flat/64.png"
    },
    {
        "label": "United Kingdom",
        "dialCode": "+44",
        "value": "GB",
        "flag": "https://www.countryflags.io/GB/flat/64.png"
    },
    {
        "label": "United States",
        "dialCode": "+1",
        "value": "US",
        "flag": "https://www.countryflags.io/US/flat/64.png"
    },
    {
        "label": "Uruguay",
        "dialCode": "+598",
        "value": "UY",
        "flag": "https://www.countryflags.io/UY/flat/64.png"
    },
    {
        "label": "Uzbekistan",
        "dialCode": "+998",
        "value": "UZ",
        "flag": "https://www.countryflags.io/UZ/flat/64.png"
    },
    {
        "label": "Vanuatu",
        "dialCode": "+678",
        "value": "VU",
        "flag": "https://www.countryflags.io/VU/flat/64.png"
    },
    {
        "label": "Venezuela, Bolivarian Republic of Venezuela",
        "dialCode": "+58",
        "value": "VE",
        "flag": "https://www.countryflags.io/VE/flat/64.png"
    },
    {
        "label": "Vietnam",
        "dialCode": "+84",
        "value": "VN",
        "flag": "https://www.countryflags.io/VN/flat/64.png"
    },
    {
        "label": "Virgin Islands, British",
        "dialCode": "+1284",
        "value": "VG",
        "flag": "https://www.countryflags.io/VG/flat/64.png"
    },
    {
        "label": "Virgin Islands, U.S.",
        "dialCode": "+1340",
        "value": "VI",
        "flag": "https://www.countryflags.io/VI/flat/64.png"
    },
    {
        "label": "Wallis and Futuna",
        "dialCode": "+681",
        "value": "WF",
        "flag": "https://www.countryflags.io/WF/flat/64.png"
    },
    {
        "label": "Yemen",
        "dialCode": "+967",
        "value": "YE",
        "flag": "https://www.countryflags.io/YE/flat/64.png"
    },
    {
        "label": "Zambia",
        "dialCode": "+260",
        "value": "ZM",
        "flag": "https://www.countryflags.io/ZM/flat/64.png"
    },
    {
        "label": "Zimbabwe",
        "dialCode": "+263",
        "value": "ZW",
        "flag": "https://www.countryflags.io/ZW/flat/64.png"
    }
]