import React, {
  useState,
  createContext,
  useContext,
  useCallback,
  useEffect,
} from 'react';
import { date, dateSimple } from '../utils/date';
import { useSocket } from './Socket';
import {
  ICourses,
  ILessons,
  IPrevNextVideo,
} from '../components/ClassRoomContainer/interfaces';
import { useHistory } from 'react-router';

interface IStatus {
  videoID: string;
  course: number;
  video: number;
  type: 'video' | 'list';
  prevVideo: string;
  nextVideo: string;
}

interface IVideoContextData {
  setCoursesList(list: Array<ICourses>): void;
  setFilter(text: string): void;

  openCategory(courseIndex: number, videoIndex: number, projectHash: string): void;
  courses: Array<ICourses>;
  videoStatus: IStatus;
  currentRoom: ILessons;
  openVideoByID(courseList: Array<ICourses>, videoId: string): void;

  openVideoId(videoId: string): void;
  type: string;
  setChecked(
    courseList: Array<ICourses>,
    courseIndex: number,
    videoIndex: number,
  ): void;
}

const VideoContext = createContext<IVideoContextData>({} as IVideoContextData);

const VideoProvider: React.FC = ({ children }) => {
  const [defaultCourses, setDefaultCourses] = useState<Array<ICourses>>(
    [] as Array<ICourses>,
  );
  const [courses, setCourses] = useState<Array<ICourses>>([]);
  const [videoStatus, setVideoStatus] = useState<IStatus>({
    videoID: '',
    course: 0,
    video: 0,
    type: 'list',
    prevVideo: '',
    nextVideo: '',
  });

  const roomTemplate = {} as ILessons;
  const currentRoom = videoStatus.type === 'list' ? roomTemplate : courses?.[videoStatus?.course]?.lessons?.find(l => l._id === videoStatus?.videoID) || roomTemplate;

  const { socket } = useSocket();
  const { push } = useHistory();

  const getPrevVideo = (
    list: Array<ICourses>,
    courseIndex: number,
    videoIndex: number,
  ): string => {
    if (!list || !list[courseIndex]) {
      return '';
    }
    if (videoIndex === 0 && courseIndex === 0) {
      return '';
    }
    if (videoIndex === 0 && courseIndex > 0) {
      const prevCourse = courseIndex - 1;
      if (!list[prevCourse]) {
        return '';
      }
      else {
        return list[prevCourse].lessons[list[prevCourse].lessons.length - 1]?._id;
      }
    }

    return list[courseIndex]?.lessons?.[videoIndex - 1]?._id || '';


  }


  const getNextVideo = (
    list: Array<ICourses>,
    courseIndex: number,
    videoIndex: number,
  ): string => {
    const coursesLenght = list.length - 1;
    const LessonsLenght = list[courseIndex].lessons.length - 1;
    const nextVideoIndex = videoIndex >= LessonsLenght ? 0 : videoIndex + 1;
    const nextCourseIndex =
      nextVideoIndex === 0 ? courseIndex + 1 : courseIndex;

    if (
      list &&
      list[nextCourseIndex] &&
      list[nextCourseIndex].lessons &&
      list[nextCourseIndex].lessons[nextVideoIndex]
    ) {
      return list[nextCourseIndex].lessons[nextVideoIndex]?._id;
    } else {
      return '';
    }


  };





  const openCategory =
    (courseIndex, videoIndex, projectHash = '') => {

      setVideoStatus((state) => {

        return {
          videoID: '',
          course: courseIndex,
          video: videoIndex,
          type: 'list',
          prevVideo: '',
          nextVideo: '',


        }


      });
      push(`/app/${projectHash}/dashboard`);

      socket?.emit('unsubscribeClassroom');
    }



  useEffect(() => {
    const ping = setInterval(() => {
      if (videoStatus && videoStatus.video >= 0) {
        socket?.emit('SetAttendance', {
          room: currentRoom._id || '0',
        });
      }
    }, 120000);
    socket?.off('updateLessons');
    socket?.on('updateLessons', (data: ILessons) => {

      const reduceCourses: Array<ICourses> = [];
      const getCourses = [...courses];



      getCourses.map((course, indexCourse) => {

        const lessons: Array<ILessons> = [];

        course.lessons.map((lesson, index) => {
          if (lesson._id === data._id) { lessons[index] = data; }
          else { lessons[index] = lesson; }
        });

        course.lessons = lessons;

        reduceCourses[indexCourse] = course;

      }, [])



      setCourses(reduceCourses);

    })

    return () => clearInterval(ping);
  }, [videoStatus, courses, currentRoom]);

  useEffect(() => {


    if (videoStatus && videoStatus.video >= 0) {
      socket?.emit('SetAttendance', {
        room: currentRoom?._id || '0',
      });
    }
    socket?.off('UpdateCourses');
    socket?.on('UpdateCourses', (data) => {
      setCoursesList(data);
    })
  }, []);
  const setChecked = useCallback((list, courseIndex, videoIndex) => {
    const newList = [...list];
    newList[courseIndex].lessons[videoIndex].check = true;
    setCourses([...newList]);
  }, []);

  const setCoursesList = (list: Array<ICourses>) => {
    const filterList: ILessons[] = [];

    const coursesList: Array<ICourses> = [];

    list.map((course, index) => {
      coursesList.push(course);
      course.lessons.map(lesson => {
        lesson.courseID = index;
        lesson.release_date_formatted = dateSimple(lesson.release_date);
        filterList.push(lesson);
      });

      return course;
    });

    filterList.sort((a, b) => {
      const dateA = new Date(a.release_date).getTime();
      const dateB = new Date(b.release_date).getTime();
      return dateA - dateB;
    });



    setCourses([...coursesList]);
    setDefaultCourses([...coursesList]);
  };

  const setFilter = useCallback(
    (text: string) => {
      const coursesList: Array<ICourses> = [...defaultCourses];

      const list: Array<ICourses> = [];

      let lessonsList: Array<ILessons> = [];
      coursesList.map((course, index) => {
        list[index] = { ...course, lessons: [] };

        const replaceSpecialChars = (str: string) => {
          return str
            .normalize('NFD')
            .toString()
            .replace(/[\u0300-\u036f]/g, '')
            .replace(/([^\w]+|\s+)/g, '-')
            .replace(/\-\-+/g, '-')
            .replace(/(^-+|-+$)/, '')
            .toUpperCase();
        };

        const currentText = replaceSpecialChars(text);

        const lessons = course.lessons.filter(m2 =>
          replaceSpecialChars(m2.title).includes(currentText),
        );

        list[index].lessons = [...lessons];

        lessonsList = [...lessonsList, ...lessons];
      });

      setCourses([...list]);

      openCategory(0, 0);
    },
    [defaultCourses, openCategory],
  );

  const openVideoId = (videoID) => {
    let courseIndex = 0;
    let videoIndex = -1;
    let finish = false;



    courses.map((listItem, index) => {
      if (finish === false) {
        if (listItem && listItem.lessons) {
          if (finish === false) {
            const lessonIndex = listItem.lessons.findIndex(
              les => les._id === videoID,
            );

            if (lessonIndex >= 0) {
              courseIndex = index;
              videoIndex = lessonIndex;
              finish = true;
            }
          }
        }
      }
    });

    if (videoIndex >= 0) {



      setVideoStatus({
        videoID: videoID,
        course: courseIndex,
        video: videoIndex,
        type: 'video',
        prevVideo: getPrevVideo(courses, courseIndex, videoIndex),
        nextVideo: getNextVideo(courses, courseIndex, videoIndex),
      });



      socket?.emit('SetAttendance', {
        room: courses[courseIndex].lessons[videoIndex]._id,
      });

      window.scrollTo(0, 0);
    }
  };

  const openVideoByID = (list, videoID) => {
    let courseIndex = 0;
    let videoIndex = -1;
    let finish = false;



    list.map((listItem, index) => {
      if (finish === false) {
        if (listItem && listItem.lessons) {
          if (finish === false) {
            const lessonIndex = listItem.lessons.findIndex(
              les => les._id === videoID,
            );

            if (lessonIndex >= 0) {
              courseIndex = index;
              videoIndex = lessonIndex;
              finish = true;
            }
          }
        }
      }
    });

    if (videoIndex >= 0) {



      setVideoStatus({
        videoID: videoID,
        course: courseIndex,
        video: videoIndex,
        type: 'video',
        prevVideo: getPrevVideo(list, courseIndex, videoIndex),
        nextVideo: getNextVideo(list, courseIndex, videoIndex),
      });



      socket?.emit('SetAttendance', {
        room: list[courseIndex].lessons[videoIndex]._id,
      });

      window.scrollTo(0, 0);
    }
  };

  return (
    <VideoContext.Provider
      value={{
        setChecked,
        setCoursesList,
        setFilter,

        openCategory,
        courses,
        videoStatus,
        openVideoByID,
        openVideoId,
        type: videoStatus.type,
        currentRoom

      }}
    >
      {children}
    </VideoContext.Provider>
  );
};

function useVideo(): IVideoContextData {
  const context = useContext(VideoContext);

  if (!context) {
    throw new Error('useVideo must be used within a VideoProvider');
  }

  return context;
}

export { useVideo, VideoProvider };
