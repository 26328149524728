import { Form } from '@unform/web';
import React, { useEffect, useState } from 'react';
import { FaBell, FaCertificate, FaFile, FaIdBadge, FaImages, FaQuestionCircle, FaReceipt, FaRegIdBadge, FaSubscript, FaTicketAlt, FaTrophy, FaUser } from 'react-icons/fa';
import { FiMessageCircle } from 'react-icons/fi';

import { IconType } from 'react-icons/lib';
import { urlWebsite } from '../../../../config';
import { useAuth } from '../../../../hooks/Auth';
import { useConfiguration } from '../../../../hooks/Configuration';
import { useLanguage } from '../../../../hooks/Language';
import api from '../../../../services/api';
import { dateFullWithHour } from '../../../../utils/date';
import format from '../../../../utils/format';
import InOut from '../../../DashboardPages/core/components/Forms/InOut';
import Sale from '../../../DashboardPages/pages/Sales';

import { Container, ContainerWhite } from './styles';

interface IProps {
  changeStatus: Function;

}

const PagamentosGeral: React.FC<IProps> = ({ changeStatus }) => {

  const { user } = useAuth();

  const [tickets, setTickets] = useState<Array<Record<string, any>>>([])

  const { projectHash, configuration } = useConfiguration();
  const { translate } = useLanguage();
  const getPaymentStatus = {

    waiting: "Aguardando Pagamento",
    paid: "Pago",
    cancelled: "Cancelado",
    refunded: "Estornado"

  }

  const getPaymentMethod = {

    boleto: "Boleto",
    credit_card: "Cartão de Crédito",
    pix: "Pix",
    deposit: "Depósito",
    cortesy: "Cortesia",

  }


  const load = async () => {



    const response = await api.get(`/my-tickets`);

    if (response?.data?.rows?.length > 0) {
      setTickets(response.data.rows);
    }

  }

  useEffect(() => {



    load();


  }, [])

  return (
    <>
      <Container >

        <div className='user-block shadow' style={{ overflow: 'hidden' }}>
          <div className='content-block' style={{ padding: '10px' }}>

            <p className='contentTitle'>{translate('Meus pagamentos')}</p>
            <table><tbody>
              {tickets?.length > 0 && <>    <tr><th>{translate('Data')}</th> <th>{translate('Produto')}</th></tr>
                {tickets.map(sale => {

                  return <tr key={sale._id}><td style={{ fontSize: '14px', lineHeight: '16px' }}>{dateFullWithHour(sale?.createdAt)}</td> <td style={{ fontSize: '14px', lineHeight: '16px' }}>
                    <strong>{sale?.product_id_response?.title}</strong>
                    <p>{sale?.fields_text}</p>
                    <br /> Valor: {format(sale.total)}
                    <br />{translate('Forma de pagamento')}: {translate(getPaymentMethod?.[sale?.payment_method_id]) || translate('Não identificado')}
                    <br />{translate('Status de pagamento')}: {translate(getPaymentStatus?.[sale?.payment_status_id]) || translate('Erro ao identificar')}
                    {sale?.boleto_url ? <><br /><a href={sale?.boleto_url}><strong>{translate('Clique aqui para visualizar o boleto')} </strong></a></> : <></>}

                    {sale.courtesy_hash && sale.payment_status_id === 'paid' && sale.type === 'Grupo' ? <><br /><br />   {translate('Utilize o código de inscrição')}:   <strong>{sale.courtesy_hash} </strong>{translate(', este código é válido para ')}{sale.quantity || 1}{translate(sale.quantity === 1 ? ' inscrição' : ' inscrições')}</> : <></>}

                    {sale?.payment_method_id === 'boleto' && <><br />
                      <a href={`/app/${sale?.project_id}/checkout/${sale?.product_id_response?.hash_link}`}><button style={{ border: '1px solid #ddd', color: '#fff', background: '#333', borderRadius: '15px', padding: '10px', cursor: 'pointer', margin: '10px 0px' }}>
                        {translate('Emitir nova cobrança')} </button></a></>}


                    {/* sale?.payment_status_id === 'paid' && sale?.total > 0 && <><br />
                      <a href={`/app/${sale?.project_id}/recibo/${sale?.sale_hash}`}><button style={{ border: '1px solid #ddd', color: '#fff', background: '#333', borderRadius: '15px', padding: '10px', cursor: 'pointer', margin: '10px 0px' }}>
                {translate('Visualizar Recibo')} </button></a></> */}

                    {sale?.qrcode_url ? <><br />
                      <h2 style={{ color: '#333', fontSize: '14px', marginBottom: '10px', marginTop: '15px' }}>QRCode de acesso</h2>
                      <img src={`${urlWebsite}/${sale?.qrcode_url}`} style={{ width: '200px' }} />

                    </> : <></>}




                  </td></tr>
                })}
              </>}

              {tickets?.length < 1 &&
                <tr  ><td>{translate('Nenhum pagamento identificado')}</td></tr>}
            </tbody>
            </table>
          </div>

        </div>
      </Container>
    </>
  );
};
export default PagamentosGeral;
