import React, { useState, useRef } from 'react';
import ReactDOM from 'react-dom';
import * as Yup from 'yup';
import { FormHandles } from '@unform/core';
import { uuid } from 'uuidv4';
import QRCode from 'qrcode.react';
import { FaBarcode, FaQrcode } from 'react-icons/fa';
import { AxiosInstance } from 'axios';
import { useModal } from '../../../../../../hooks/Modal';
import FormComponent, { sanitizeDataObject } from '../../FormComponent';
import { createSchemaByForm, IYup } from '../../../../../../utils/SchemaCreator';
import getValidationErrors from '../../../../../../utils/getValidationErrors';
import api from '../../../../../../services/api';
import { useToast } from '../../../../../../hooks/Toast';
import { useSearch } from '../../../../../../hooks/Search';
import Button from '../../Forms/Button';

import { usePrinter } from '../../../../../../hooks/PrinterHook';
import QRCodeComponent from '../../QRCodeComponent';
import { useConfiguration } from '../../../../../../hooks/Configuration';
import { Form } from '@unform/web';
import Input from '../../Forms/Input';
import SelectSimple from '../../Forms/SelectSimple';
import states from '../../../../../SignUp/FormContainer/states';

interface ICreateModule {
  lineData: Record<string, any>;
  title: string;
  formSchema: Record<string, any>;
  endpoint: string;
  validationSchema: Record<string, IYup>;
  apiRef?: AxiosInstance;
}

export const decodeCracha = (line, config) => {

  const masks = {
    firstAndLast: (name) => {

      if (!name) {
        return '';
      }

      const namelist = name?.split(' ');

      if (namelist?.length > 1) {
        return `${namelist[0]} ${namelist[namelist?.length - 1]}`;
      }
      else {
        return name;
      }
    },
    firstSecondAndLast: (name) => {

      if (!name) {
        return '';
      }

      const namelist = name?.split(' ');

      if (namelist?.length > 2) {
        return `${namelist[0]} ${namelist[1]} ${namelist[namelist?.length - 1]}`;
      }
      else {
        return name;
      }
    }

  }



  const lines: Array<JSX.Element> = [];

  const positions: Array<Record<string, any>> = [];

  config.map(conf => {

    const index = positions?.findIndex(p => p.position === conf.position);

    if (index >= 0) {

      const maskedValue = masks?.[conf?.mask] ? masks[conf?.mask](line?.[conf.field]) : line?.[conf.field] || '';

      let text = positions[index]?.value ? `${positions[index]?.value}${maskedValue ? conf?.separator ? ` ${conf?.separator} ` : ' - ' : ''}${maskedValue ? maskedValue : ''}` : maskedValue ? maskedValue : ''
      positions[index].value = text;
    }
    else {


      if (conf?.mask) {

        if (masks?.[conf?.mask]) {
          positions.push({ ...conf, value: masks[conf?.mask](line?.[conf.field]) });
        }
        else {
          positions.push({ ...conf, value: line?.[conf.field] ? line?.[conf.field] : '' });
        }

      }
      else {
        positions.push({ ...conf, value: line?.[conf.field] ? line?.[conf.field] : '' });

      }


    }

  })

  positions.sort((a, b) => a.position > b.position ? 1 : a.position < b.position ? -1 : 0);

  positions?.map(item => {


    lines.push(<h2 style={{ fontSize: item.size, width: '100%', fontWeight: item?.bold === 'yes' ? 700 : 400, textAlign: 'center', color: '#000', marginTop: item?.margin ? item?.margin : '0px' }}>{item?.value ? item.value.toUpperCase() : ''}</h2>);
  })



  return lines;



}

export default function Cracha({
  apiRef = api,
  lineData,
  title,
  formSchema,
  validationSchema,
  endpoint,
}: ICreateModule): JSX.Element {
  const { addModal, removeModal } = useModal();
  const { addToast } = useToast();
  const { addPrint, printIt } = usePrinter();
  const { reloadSearchAll } = useSearch();
  const { configuration } = useConfiguration();
  const key = uuid();

  const print = async line => {
    removeModal('crachaKey');
    removeModal('handleCRM');

    printIt();
    await api.post(`/credenciar`, { hash: line.user_hash });
    reloadSearchAll();

  };

  const handlePrint = async (line): Promise<void> => {



    const category = line && line.facebook && line.facebook === 'organizacao' ? 'ORGANIZAÇÃO' : line.facebook;



    addPrint([
      <div className="qrHolder2" style={{ display: 'flex', width: '100%', justifyContent: 'space-between', padding: '15px' }}>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', minWidth: '6cm', flexDirection: 'column' }}>
          {decodeCracha(line, configuration?.cracha_config)}
        </div>

        {configuration?.cracha_have_qrcode !== 'no' ? <div style={{ width: '80px', minWidth: '80px' }}><QRCode
          size={80}
          value={line.user_hash} renderAs="svg" /></div> : <></>}
      </div>]
    );


    print(line);


  };

  const updateCRM = async (data) => {

    if (!data?.crm) {
      addToast({ title: 'Preencha o CRM / CRP', type: 'error' });
      return;
    }

    if (!data?.crm_uf) {
      addToast({ title: 'Preencha o estado do CRM / CRP', type: 'error' });
      return;
    }

    const update = await api.put(`/students/${lineData?._id?.toString()}/crm`, { crm: data?.crm });
    const updateUF = await api.put(`/students/${lineData?._id?.toString()}/crm_uf`, { crm_uf: data?.crm_uf });



    addPrint([
      <div className="qrHolder2" style={{ display: 'flex', width: '100%', minWidth: '8.7cm', justifyContent: 'space-between', alignItems: 'center', padding: '15px', paddingBottom: '0px', paddingTop: configuration?.theme_data?.cracha_margin_top_line ? configuration?.theme_data?.cracha_margin_top_line : '30px' }}>
        <div style={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
          {decodeCracha(lineData, configuration?.cracha_config)}
        </div>
        {configuration?.cracha_have_qrcode !== 'no' ? <div style={{ width: '80px', minWidth: '80px' }}><QRCode
          size={80}
          value={lineData.user_hash} renderAs="svg" /></div> : <></>}
      </div>]
    );
    const content = <><h2 style={{ color: '#fff' }}>CRACHÁ</h2><div className="qrHolder2" style={{ background: '#fff', display: 'flex', width: '9cm', paddingLeft: '20px', paddingRight: '20px', justifyContent: 'space-between', maxWidth: '8cm', padding: '15px', borderRadius: '10px', margin: '15px auto', border: '2px solid #ddd' }}>
      <div style={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', marginRight: configuration?.theme_data?.qrcode !== 'no' ? '20px' : '0px' }}>
        {decodeCracha(lineData, configuration?.cracha_config)}
      </div>
      {configuration?.cracha_have_qrcode !== 'no' ? <div style={{ width: '80px', minWidth: '80px' }}><QRCode
        size={80}
        value={lineData.user_hash} renderAs="svg" /></div> : <></>}
    </div> <Button onClick={() => print(lineData)}>Imprimir</Button></>;



    if (content) {
      const keyModal = addModal({ theme: 'whiteModal', title, content, key: 'crachaKey' });
    }

  }

  const handleQR = async (line): Promise<void> => {


    if (!line?.crm && (line?.category_id?.indexOf('Médico') >= 0 || line?.category_id?.indexOf('Psicólogo') >= 0 || line?.category_id?.indexOf('Psicólogo') >= 0)) {

      addModal({
        title: '', content: <Form onSubmit={updateCRM}>

          <Input name="crm" placeholder='CRM / CRP' />
          <SelectSimple name='crm_uf' label='Estado do CRM / CRP' options={states} />
          <button className='defaultButton'>Atualizar</button>
        </Form>, key: 'handleCRM'
      },
      )


    }



    addPrint([
      <div className="qrHolder2" style={{ display: 'flex', width: '100%', minWidth: '8.7cm', justifyContent: 'space-between', alignItems: 'center', padding: '15px', paddingBottom: '0px', paddingTop: configuration?.theme_data?.cracha_margin_top_line ? configuration?.theme_data?.cracha_margin_top_line : '0px' }}>
        <div style={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
          {decodeCracha(line, configuration?.cracha_config)}
        </div>
        {configuration?.cracha_have_qrcode !== 'no' ? <div style={{ width: '80px', minWidth: '80px' }}><QRCode
          size={80}
          value={line.user_hash} renderAs="svg" /></div> : <></>}
      </div>]
    );
    const content = <><h2 style={{ color: '#fff' }}>CRACHÁ</h2><div className="qrHolder2" style={{ background: '#fff', display: 'flex', width: '9cm', paddingLeft: '20px', paddingRight: '20px', justifyContent: 'space-between', maxWidth: '8cm', padding: '15px', borderRadius: '10px', margin: '15px auto', border: '2px solid #ddd' }}>
      <div style={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', marginRight: configuration?.theme_data?.qrcode !== 'no' ? '20px' : '0px' }}>
        {decodeCracha(line, configuration?.cracha_config)}
      </div>
      {configuration?.cracha_have_qrcode !== 'no' ? <div style={{ width: '80px', minWidth: '80px' }}><QRCode
        size={80}
        value={line.user_hash} renderAs="svg" /></div> : <></>}
    </div> <Button onClick={() => print(line)}>Imprimir</Button></>;



    if (content) {
      const keyModal = addModal({ theme: 'whiteModal', title, content, key: 'crachaKey' });
    }



  };

  const moduleQRCode = (line): JSX.Element => (

    <button className="lineIcon" title="Imprimir Crachá" type="button" onClick={() => handleQR(line)}>
      <FaBarcode />
    </button>

  );

  return moduleQRCode(lineData);
}
