
import React, { useEffect, useState } from 'react';
import { FaArrowCircleRight, FaDownload, FaMapMarker } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { urlWebsite } from '../../../../config';
import { useConfiguration } from '../../../../hooks/Configuration';
import { useLanguage } from '../../../../hooks/Language';

import QRCode from 'qrcode.react';
import {

    Capa, Container,



} from '../Themes/SinglePageTheme/styles';
import ProductsList from './modules/Products';

const Apresentacao = () => {
    const { configuration, projectHash } = useConfiguration();
    const { translate } = useLanguage();

    const [theme, setTheme] = useState({
        titleColor: configuration?.current_event_id_response?.theme?.title_color || '#fff',
        textColor: configuration?.current_event_id_response?.theme?.text_color || '#fff',
        buttonColor: configuration?.current_event_id_response?.theme?.button_color || '#333',
        buttonBackground: configuration?.current_event_id_response?.theme?.button_background || '#3FB8EB',
    })


    useEffect(() => {
        setTheme({
            titleColor: configuration?.current_event_id_response?.theme?.title_color || '#fff',
            textColor: configuration?.current_event_id_response?.theme?.text_color || '#fff',
            buttonColor: configuration?.current_event_id_response?.theme?.button_color || '#333',
            buttonBackground: configuration?.current_event_id_response?.theme?.button_background || '#3FB8EB',
        })
    }, [configuration])


    return <><div className='row-to-column' style={{ alignItems: 'center', paddingTop: '50px' }}>

        <div className='column-start gap-sm pad pad-mobile'>
            <p className='color2'><strong className='color2'>Online, híbrido ou presencial</strong> </p>
            <h2 className='title color-white'>
                Baixe nossa  <br /><span className='color2'>Apresentação</span>



            </h2>

            <QRCode style={{ marginTop: '20px' }}

                size={210}
                includeMargin={true}
                imageSettings={{ src: "", width: 210, height: 210, excavate: false }}
                renderAs="svg"
                value={`https://encontrodigital.com.br/download/Encontro_Digital_2024.pdf`}
            />

            <a href={`https://encontrodigital.com.br/download/Encontro_Digital_2024.pdf`} target="_BLANK" download ><button className='blueButtonBig'>
                <h2> DOWNLOAD</h2>
                <FaDownload />
            </button></a>


        </div>

        <div style={{ position: 'relative', alignSelf: 'flex-end' }}>



            <img src="/apoio/pattern.png" style={{ position: 'absolute', zIndex: 0, top: '0px', right: '0px', width: '90%' }} />
            <img src="/apoio/menina.png" style={{ zIndex: 10, position: 'relative', width: '100%', marginBottom: '-4px' }} />

        </div>

    </div>


    </>

}

export default Apresentacao;