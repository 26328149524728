import React from 'react';
import { saveAs } from 'file-saver'
import { pdf } from '@react-pdf/renderer'
import { Page, Canvas, Svg, PDFViewer, PDFDownloadLink, Text, View, Document, StyleSheet, Image, Font, } from '@react-pdf/renderer';
import { urlWebsite } from '../../../../../../config';
import QRCode from 'qrcode.react';

interface IProps {
    lineData?: Record<string, any>;
}

const replaceData = (certificate, text) => {

    if (!text) {
        return '';
    }


    const data = {
        'NOME': certificate?.name,
        'E-MAIL': certificate?.email,
        'CPF': certificate?.document_number,
        'ORIENTADOR': certificate?.orientator,
        'TRABALHO': certificate?.paper,
        'MODALIDADE': certificate?.modal,
        'CATEGORIA': certificate?.category,
        'ATIVIDADE': certificate?.activity,
        'AUTORES': certificate?.authors,
        'COAUTORES': certificate?.coauthors,
        'DIAS': certificate?.days,
        'HORAS': certificate?.hours
    };


    Object.keys(data).map(key => {
        text = text.replace(`{${key}}`, data[key]);
    })

    return text;



}


export const handleCertificateDownload = async ({ certificate }) => {




    const styles = StyleSheet.create({
        page: {
            position: 'relative',
            width: '27.9cm',
            margin: '0px',
            height: certificate?.certificate_id_response?.height || '21cm',
            backgroundColor: "#fff",
            color: "#333",


        },

    });


    const item: any = document.getElementById(`canva-${certificate.hash}`);

    // Define a simple HTML to PDF rendering function
    const renderHtmlToPdf = (htmlContent) => {
        const elements: any = [];
        let keyIndex = 0;

        // Parse the HTML content and create corresponding PDF elements
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlContent, 'text/html');

        const traverse = (node) => {
            if (node.nodeType === Node.ELEMENT_NODE) {
                const style = {};

                // Extract and apply CSS styles
                const computedStyle = window.getComputedStyle(node);
                for (let i = 0; i < computedStyle.length; i++) {
                    const propName = computedStyle[i];
                    style[propName] = computedStyle.getPropertyValue(propName);
                }

                // Create PDF elements based on HTML tags
                switch (node.tagName.toLowerCase()) {
                    case 'p':
                        elements.push({ type: 'normal', text: node.textContent });
                        break;
                    case 'h1':
                    case 'h2':
                    case 'h3':
                    case 'h4':
                    case 'h5':
                    case 'h6':
                    case 'strong':
                        //  elements.push({ type: 'bold', text: node.textContent })

                        break;
                    // You can add more cases for other HTML elements
                    default:
                        // Handle unsupported elements or ignore them
                        break;
                }

                keyIndex++;
            }

            if (node.hasChildNodes()) {
                for (let child of node.childNodes) {
                    traverse(child);
                }
            }
        };

        // Start traversing the HTML document
        traverse(doc.body);

        return elements;
    };

    // Example component to render HTML content to PDF






    const DocumentElements: React.FC = () => <><Document  >
        {/*render a single page*/}
        <Page wrap={false} style={styles.page}>

            <View wrap={false} style={{ width: "100%", zIndex: 0, opacity: 1, }}  >  <Image src={`${urlWebsite}/${certificate?.certificate_id_response?.image}`} style={{
                minWidth: '100%',
                maxHeight: certificate?.certificate_id_response?.height || '21cm',
                display: 'flex',

                width: '100%',
            }} />
            </View>

            {certificate?.certificate_id_response?.content?.map((text, index) => {

                const style: Record<string, any> = {
                    fontSize: text.size,
                    fontFamily: text.bold === 'bold' || text.bold === '800' ? 'Helvetica-Bold' : 'Helvetica',
                    left: text.left,
                    top: text.top,
                    position: 'absolute',

                    width: text.width === '95%' ? '100%' : text.width,
                    textAlign: text.align,
                    color: text.color || '#000',


                }


                const styleText: Record<string, any> = {
                    fontSize: text.size,

                    color: text.color || '#000',


                }



                if (text?.element === 'h2') {
                    style.textTransform = 'uppercase';
                }





                text.text = replaceData(certificate, text.text);
                const pdfElements = renderHtmlToPdf(text.text);




                const elements: any = [];
                return <View style={style}>
                    {pdfElements?.map((itemValue, indexValue) => {

                        return <Text style={{ ...styleText, fontFamily: text.bold === 'bold' || text.bold === '800' ? 'Helvetica-Bold' : 'Helvetica' }}>{itemValue?.text}</Text>
                    })}
                </View>


            })}



            {certificate?.certificate_id_response?.have_qrcode === 'yes' && <View style={{ width: "70px", backgroundColor: '#fff', height: '90px', alignSelf: 'flex-end', marginTop: '-90px', display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', position: 'absolute', bottom: '0px', right: '10px' }}  >

                <Image style={{ width: '50px', height: '50px' }} src={item.toDataURL()} /><Text style={{ fontSize: '8px', color: '#000', textAlign: 'center', marginTop: '5px' }}   >Leia para validar</Text></View>}
        </Page>
    </Document ></>;


    try {
        const blob = await pdf(<DocumentElements />).toBlob()
        saveAs(blob, `${certificate?.name?.toUpperCase()}-${certificate?.document_number}-${certificate?.certificate_id_response?.title?.toUpperCase()}.pdf`)
    }
    catch (err) {
        console.log(err);
    }
}

export const downloadCertificate = ({ lineData: certificate }) => {



    return <>
        <aside style={{ display: 'none' }}>
            <QRCode id={`canva-${certificate.hash}`}
                size={100}
                value={`${urlWebsite}/validar-certificado/${certificate.hash}`} renderAs="canva" />
        </aside>
        <button className='defaultButtonMini' onClick={() => handleCertificateDownload({ certificate })}>Download</button>


    </>



}

export default downloadCertificate