import React, { useEffect, useState } from 'react';
import { useConfiguration } from '../../../../hooks/Configuration';
import Program from '../../../../pages/Program';
import ProgramClean from '../../../../pages/ProgramClean';
import ProgramByUnimed from './Templates/ProgramByUnimed';


interface IParams {
    projectHash: string;
}

const ProgramTheme: React.FC = () => {

    const { configuration } = useConfiguration();

    const [currentProgram, setCurrentProgram] = useState('');

    const [theme, setTheme] = useState({
        template: configuration?.current_event_id_response?.theme?.program_template || 'unimed',
        titleColor: configuration?.current_event_id_response?.theme?.program_title_color || '#fff',
        textColor: configuration?.current_event_id_response?.theme?.program_text_color || '#fff',
        background: configuration?.current_event_id_response?.theme?.program_background || 'none',
        main_title_color: configuration?.current_event_id_response?.theme?.program_title_color || 'rgb(0,14,64)',
    })


    useEffect(() => {
        setTheme({

            template: configuration?.current_event_id_response?.theme?.program_template || 'unimed',
            titleColor: configuration?.current_event_id_response?.theme?.program_title_color || '#fff',
            textColor: configuration?.current_event_id_response?.theme?.program_text_color || '#fff',
            background: configuration?.current_event_id_response?.theme?.program_background || 'none',
            main_title_color: configuration?.current_event_id_response?.theme?.program_title_color || 'rgb(0,14,64)',
        })
    }, [configuration])

    const validPrograms = {

        default: <Program theme={theme} />,
        clean: <ProgramClean theme={theme} />,
        unimed: <ProgramByUnimed theme={theme} />
    }


    return validPrograms[theme.template] ? validPrograms[theme.template] : validPrograms['default']

}

export default ProgramTheme;