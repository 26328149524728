import React, { useCallback, useRef, useEffect, useState } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { Link, useHistory } from 'react-router-dom';

import { IconType } from 'react-icons/lib';

import {
  Container,
  SecondaryContainer,
  ContainerWhite,
  GreyContainer,
  Capa,
  ProgramContainer,
  Sponsors,
  Photo,
} from './styles';

import getValidationErrors from '../../utils/getValidationErrors';

import { useAuth } from '../../hooks/Auth';
import { useToast } from '../../hooks/Toast';
import { useModal } from '../../hooks/Modal';

import { usePages } from '../../hooks/Pages';
import { useLanguage } from '../../hooks/Language';
import SlideList from '../DashboardPages/features/SliderComponent';
import ProjectList from '../DashboardPages/features/ProjectList';
import EventList from '../DashboardPages/features/EventsList';
import api from '../../services/api';
import { hash, urlWebsite } from '../../config';
import { Button } from '../../components/Button/styles';
import SliderContainer from '../../components/SliderContainer';
import { FaBrain, FaSearch, FaStar } from 'react-icons/fa';
import Calendar from './modules/Calendar';
import SelectSimple from '../../components/Forms/SelectSimple';
import EventsListDisplay from '../DashboardPages/features/EventsListDisplay';
import PosterListDisplay from '../DashboardPages/features/PosterListDisplay';
import TestimonialListDisplay from '../DashboardPages/features/TestimonialDisplay';
import { ContactIcons, ContactIconsOrange } from '../../components/Menu';
import themeDefault from '../../styles/ThemeComponents';
import HomeSlider from '../HomeSlider';
import { loadFromJson } from '../../utils/loadFromJson';
import HomeApplication from '../../PagesApplication/HomeApplication';
import { useConfiguration } from '../../hooks/Configuration';
import { FiMail } from 'react-icons/fi';

interface IMenu {
  title: string;
  target: string;
  targetID: string;
  fluid: number;
  external: number;
  status: number;
}

interface SignInData {
  email: string;
  password: string;
}

interface IPublicData {
  icon: IconType;
  title: string;
  text: string;
}

const Unsubscribe: React.FC = () => {


  const { translate } = useLanguage();

  const { user } = useAuth();
  const { configuration } = useConfiguration();
  const [search, setSearch] = useState('');
  const [currentCategory, setCurrentCategory] = useState('');



  return (<>
    <Container style={{ minHeight: '90vh', flexDirection: 'column', gap: '50px' }}>
      <FiMail style={{ color: "#fff", fontSize: '150px' }} />
      <h2 style={{ color: '#fff' }}>Seu e-mail foi descadastrado com sucesso.</h2>



    </Container></>
  );
};
export default Unsubscribe;
