import React, { Dispatch, useRef, useCallback, useState } from 'react';

import { Form } from '@unform/web';
import {
  FiMail,
  FiLock,
  FiUser,
  FiArrowRight,
  FiArrowLeft,
} from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { FaArrowLeft } from 'react-icons/fa';
import { ISigla, readSigla } from '../../siglas';
import { useAuth } from '../../../../hooks/Auth';
import Input from '../../../../components/Forms/Input';
import MaskedInput from '../../../../components/Forms/MaskedInput';
import { useToast } from '../../../../hooks/Toast';
import { AnimatedDiv } from '../../styles';
import { LoginContent } from './styles';
import getValidationErrors from '../../../../utils/getValidationErrors';
import SelectSimple from '../../../../components/Forms/SelectSimple';

import Button from '../../../../components/Button';
import { BuyContext } from '../../index';
import api from '../../../../services/api';
import TextareaInput from '../../../../components/Forms/TextareaInput';
import Loader from '../../../../components/Loader';
import { countries } from './countries';
import getCep from '../../../../services/cep';
import { useLanguage } from '../../../../hooks/Language';
import CheckboxInput from '../../../../components/Forms/CheckboxInput';
import { authTitle } from '../../../../config';
import { truncate } from 'fs';
interface IRead {
  [key: string]: any;
}

interface ComponentProps {
  step: number[];
  setStep: Dispatch<React.SetStateAction<number[]>>;
  buyContext: BuyContext;
  setBuyContext: Dispatch<React.SetStateAction<BuyContext>>;
  productId: string;
}

interface SignInData {
  email: string;
  password: string;
}

interface LoginProps {
  [key: string]: string;
}

const AtualizarCadastroContainer: React.FC = () => {
  const { signIn } = useAuth();
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);

  const { user } = useAuth();

  const [buyType, setBuyType] = useState('');
  const [countryISO, setCountryISO] = useState('BR');
  const [loading, setLoading] = useState(false);

  const { translate } = useLanguage();

  const siglas = readSigla();




  const getCepFromApi = async (value: string): Promise<void> => {
    const cepResponse = await getCep(value);

    if (!cepResponse.error) {
      formRef?.current?.setFieldValue('address', cepResponse.logradouro);
      formRef?.current?.setFieldValue('neighborhood', cepResponse.bairro);
      formRef?.current?.setFieldValue('city', cepResponse.localidade);
      formRef?.current?.setFieldValue('state', cepResponse.uf);
    }
  };


  const handleNewLogin = useCallback(
    async (data: LoginProps) => {
      setLoading(true);
      try {
        formRef.current?.setErrors({});


        const schema = countryISO === 'BR' ? Yup.object().shape({


          birth_date: Yup.string()
            .min(10, translate('min 10 Preecha a Data de Nascimento'))
            .max(10, translate('max 10 Preecha a Data de Nascimento'))
            .required(`${translate('Data de Nascimento')} ${translate('obrigatório')}`),

          zipcode: Yup.string()
            .min(8, `${translate('CEP')} ${translate('obrigatório')}`)
            .max(8, `${translate('CEP')} ${translate('obrigatório')}`)
            .required(`${translate('CEP')} ${translate('obrigatório')}`),
          address: Yup.string().required(`${translate('Endereço')} ${translate('obrigatório')}`),
          address_number: Yup.string().required(`${translate('Endereço')} ${translate('obrigatório')}`),
          neighborhood: Yup.string().required(`${translate('Bairro')} ${translate('obrigatório')}`),
          city: Yup.string().required(`${translate('Cidade')} ${translate('obrigatório')}`),
          state: Yup.string()
            .max(2, translate('No máximo 2 caracteres'))
            .required(translate(`${translate('Estado')} ${translate('obrigatório')}`)),

        }) : Yup.object().shape({




          document_number: Yup.string()

            .required(`${translate('CPF')} ${translate('obrigatório')}`),

          birth_date: Yup.string()
            .min(10, translate('min 10 Preecha a Data de Nascimento'))
            .max(10, translate('max 10 Preecha a Data de Nascimento'))
            .required(translate(`${translate('Data de Nascimento')} ${translate('obrigatória')}`)),

          zipcode: Yup.string()

            .required(`${translate('CEP')} ${translate('obrigatório')}`),
          address: Yup.string().required(`${translate('Endereço')} ${translate('obrigatório')}`),
          address_number: Yup.string().required(`${translate('Endereço')} ${translate('obrigatório')}`),
          neighborhood: Yup.string().required(`${translate('Bairro')} ${translate('obrigatório')}`),
          city: Yup.string().required(`${translate('Cidade')} ${translate('obrigatório')}`),
          state: Yup.string()

            .required(`${translate('Estado')} ${translate('obrigatório')}`),

        });
        if (data.birth_date) {
          data.birth_date.toString().replace(/[^0-9/]/g, '');
        }

        if (data.company_document_number) {
          data.company_document_number.toString().replace(/[^0-9]/g, '');
        }

        let {
          crm,

          company,

          position,

          phone,

          birth_date,
          company_document_number,
          extra_1,
          extra_2,
          extra_3,
          extra_4,
          education_id,
          especialidade,
          country,
          address,
          address_number,
          neighborhood,
          city,
          state,
          zipcode,
          gender,
          facebook,
          term,

        } = data;



        await schema.validate(data, { abortEarly: false });





        const pais = countries.findIndex(c => c.value === country);
        if (pais) {
          extra_3 = countries[pais].dialCode;
          extra_2 = countries[pais].label;
        }


        const update: Record<string, any> = {
          crm,


          company,


          position,


          company_document_number,

          education_id,
          especialidade,
          country,
          birth_date,
          address,
          address_number,
          neighborhood,
          city,
          state,
          zipcode,
          gender,
          term,
          phone
        };




        const newUser = await api
          .put('/update-user', update)
          .catch(error => {
            if (error instanceof Yup.ValidationError) {
              const errors = getValidationErrors(error);
              formRef.current?.setErrors(errors);
              setLoading(false);
            }

            addToast({
              type: 'error',
              title: error.response.data.message,
            });
            setLoading(false);
            return false;
          });

        if (!newUser) {
          setLoading(false);
          return false;
        }

        window.location.reload();

        setLoading(false);

        window.scrollTo(0, 0);

        return false;
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          const errorInfo = err.errors.reduce((error, prev) => {
            prev = `${prev}, ${error}`;
            return prev;
          });
          setLoading(false);
          addToast({
            type: 'error',
            title: 'Erro de validação',
            description: errorInfo,
          });
        }
        setLoading(false);
      }
    },
    [addToast],
  );

  return (
    <AnimatedDiv visible={true}>
      <LoginContent>
        {loading && <Loader message="Carregando" />}
        <div>
          {' '}
          <aside style={{ display: 'flex', background: '#fff', borderRadius: '10px', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-start', paddingTop: '30px' }}>

            <h2 style={{ width: '100%', textAlign: 'center', color: '#333' }}>Atualização cadastral</h2>
            <p style={{ width: '100%', textAlign: 'center', color: '#333', marginBottom: '20px' }}>Para continuar, é necessário atualizar seu cadastro</p>
            <Form className="form" ref={formRef} onSubmit={handleNewLogin} initialData={{ country: 'BR', extra_4: 2 }}>


              <SelectSimple setCallback={(value) => setCountryISO(value)} options={countries} name="country" label={translate('Pais')} />



              <MaskedInput
                mask="99/99/9999"
                name="birth_date"
                placeholder={translate('Data de Nascimento (DD/MM/YYYY)')}
              />

              {countryISO === 'BR' ? <MaskedInput
                mask="(99) 99999.9999"
                name="phone"
                placeholder={translate('Telefone')}
              /> : <Input name="phone"
                placeholder={translate('Telefone')} />}


              {countryISO === 'BR' ?
                <MaskedInput

                  setCallback={getCepFromApi}
                  mask="99999999"
                  name="zipcode"
                  placeholder={translate('CEP')}
                /> : <Input name="zipcode" placeholder={translate('ZIP Code')} />}
              <Input name="address" placeholder={translate('Endereço')} />
              <Input name="address_number" placeholder={translate('Número / Apartamento')} />
              <Input name="neighborhood" placeholder={translate('Bairro')} />
              <Input name="city" placeholder={translate('Cidade')} />
              {countryISO === 'BR' ? <MaskedInput mask="aa" name="state" placeholder={translate('Estado')} /> :

                <Input name="state" placeholder={translate('Província / Região')} />}

              <p style={{ width: '100%', color: '#333', fontSize: '12px', textAlign: 'center', margin: '20px 0px' }}>Caso precise realizar a compra como Pessoa Jurídica, preencha os campos abaixo</p>

              {countryISO === 'BR' ? <MaskedInput

                mask="99.999.999/9999-99"
                name="company_document_number"
                placeholder={`${translate('CNPJ da Empresa / Entidade')}`}
              /> :
                <Input name="company_document_number"
                  placeholder={`${translate('Nº de identificação da Empresa / Entidade')}`} />}



              <Input
                name="company"
                placeholder={translate('Empresa')}
              />


              <Input
                name="position"
                placeholder={translate('Cargo')}
              />


              <p
                style={{
                  padding: '10px',
                  background: 'none',
                  margin: '5px auto',
                  color: '#333',
                  fontSize: '12px',
                  textAlign: 'left',
                }}
              >
                {translate('Ao me cadastrar, afirmo que li e aceito a')}
                {' '}
                <strong>
                  <Link
                    style={{ display: 'inline', color: '#333' }}
                    to="/termos"
                    target="_BLANK"
                  >
                    {translate('POLÍTICA DE PRIVACIDADE')}
                    {' '}
                  </Link>
                  {' '}
                </strong>

              </p>

              <Button type="submit">
                {translate('Atualizar Cadastro')}

                <FiArrowRight />
              </Button>
            </Form>



          </aside>
        </div>
      </LoginContent>
    </AnimatedDiv>
  );
};

export default AtualizarCadastroContainer;
