import React from 'react';
import * as Yup from 'yup';
import { FormHandles } from '@unform/core';
import uuid from '../../UniqueHash';
import { useModal } from '../../../../../../hooks/Modal';
import FormComponent, { sanitizeDataObject } from '../../FormComponent';
import { createSchemaByForm, IYup } from '../../../../../../utils/SchemaCreator';
import getValidationErrors from '../../../../../../utils/getValidationErrors';
import api from '../../../../../../services/api';
import { useToast } from '../../../../../../hooks/Toast';
import { useSearch } from '../../../../../../hooks/Search';
import { AxiosInstance } from 'axios';
import { FaLightbulb, FaPlus } from 'react-icons/fa';
import { useParams } from 'react-router-dom';

interface ICreateModule {
  project_id: string;
}

interface IParams {
  projectHash: string;
  eventHash: string;
}

export default function CreateModule({
  project_id,

}: ICreateModule): JSX.Element {
  const { reloadSearchAll } = useSearch();
  const { addModal, removeModal } = useModal();
  const { addToast } = useToast();
  const { projectHash, eventHash } = useParams<IParams>()
  const key = uuid();



  const handleCreate = async () => {
    addToast({ title: 'Carregando', type: 'info' });
    const response = await api.get('/update-users-from-integration/20-endofeminina');
    addToast({ title: response?.data?.message, type: 'success' });
    reloadSearchAll();

    return;
  };

  const moduleCreate = (): JSX.Element => (
    <button title='Atualizar cadastros' className="searchButton" type="button" onClick={handleCreate}>
      <FaLightbulb />
    </button>
  );

  return moduleCreate();
}
