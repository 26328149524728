import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { IconBaseProps } from 'react-icons';

import { useField, FormHandles } from '@unform/core';
import { Container, Field } from './styles';
import { Error } from '../styles';
import { FaChevronDown, FaChevronUp, FaEdit, FaPlus, FaTrash } from 'react-icons/fa';
import { uuid } from 'uuidv4';
import { Form } from '@unform/web';
import Input from '../Input';
import SelectSimple from '../SelectSimple';
import RichText from '../RichText';
import { useModal } from '../../../../../../hooks/Modal';
import JsonList from '../JsonListMultiple';
import ArrayIncluder from '../ArrayInputIncluder';

interface IOptions {
  text?: string;
  value?: string;
  label?: string;
}

interface IListInput {
  label: string;
  name: string;
  type?: string;
  options?: Array<IOptions>;
}

interface IInputProps {
  name: string;
  icon?: React.ComponentType<IconBaseProps>;
  setCallback?: Function;
  formRef?: React.RefObject<FormHandles>;
  list: Array<IListInput>;
  defaultItem?: Array<Record<string, any>>;
  placeholder: string;
  show?: string | string[];
}

/*
The concept is simple.
Create a form with multiple inputs

*/

const JsonInputList: React.FC<IInputProps> = ({
  name,
  icon: Icon,
  formRef,
  setCallback = () => { },
  placeholder,
  defaultItem,
  list,
  show,
  ...rest
}): JSX.Element => {

  const { addModal, removeModal } = useModal();

  const { fieldName, defaultValue, error, registerField } = useField(name);
  const inputRef = useRef<HTMLInputElement>(null);

  const [value, setValue] = useState(defaultValue || defaultItem || []);



  useEffect(() => {


    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: undefined,
      setValue: (ref: any, value: any) => {
        setValue(value);
      },
      getValue: (ref: any) => {
        return value;
      },
    });
  }, [fieldName, registerField, value]);


  const goUp = index => {
    const indexup = index - 1;

    if (value[indexup]) {
      const newCurrent = [...value];

      newCurrent[index] = value[indexup];
      newCurrent[indexup] = value[index];

      setValue([...newCurrent]);

    }
  };

  const goDown = index => {
    const indexup = index + 1;

    if (value[indexup]) {
      const newCurrent = [...value];

      newCurrent[index] = value[indexup];
      newCurrent[indexup] = value[index];

      setValue([...newCurrent]);
    }
  };

  const defaultValueList = {
    input: '',
    select: '',
    textList: '',
    jsonList: [],
    arrayIncluder: []
  }

  const addRow = () => {
    const newValue = [...value];



    const newLine = Object.keys(list).reduce((prev, key) => {
      prev[list[key].name] = defaultValueList[list?.[key]?.type || 'input'];
      return prev;
    }, {});

    newValue.push(newLine);

    setValue([...newValue]);
  };

  const removeItem = (index) => {

    const item = [...value];
    item.splice(index, 1);

    setValue([...item]);

  }

  const printLines = useCallback(() => {

    const items: Array<JSX.Element> = [];
    {
      value.map((v, index) => {




        let display = `Adicione sua pergunta`;

        if (typeof show === 'object') {
          show.map((s, index) => {

            let virtualName = v?.[s];

            const listItem = list?.find(listI => listI.name === s);
            console.log(listItem)
            if (listItem?.type === 'select') {

              const text = listItem.options?.find(l => l.value === v?.[s]);

              if (text) {
                virtualName = text?.label;
              }
            }



            display = index === 0 ? virtualName : `${display} - ${virtualName}`;
          })

        }
        else if (typeof show === 'string') {
          display = v[show] || 'Adicione sua pergunta';
        }

        const key = uuid();
        items.push(<div key={key} className='display'>
          <p>{display}</p>
          <div className='listModules'>
            <div><FaEdit onClick={() => openInputs(index)} size={15} style={{ cursor: 'pointer' }} /></div>

            <div><FaChevronUp onClick={() => goUp(index)} style={{ cursor: 'pointer' }} /></div>
            <div><FaChevronDown onClick={() => goDown(index)} style={{ cursor: 'pointer' }} /></div>
            <div><FaTrash onClick={() => removeItem(index)} style={{ cursor: 'pointer' }} /></div>
          </div>
        </div>)

      })

      return items;

    }
  }, [value]);


  const validTypes = (listInput) => {

    const valid = {
      input: (item) => <Input name={item.name} placeholder={item.label} />,
      select: (item) => <SelectSimple name={item.name} label={item.label} options={item.options} />,
      richText: (item) => <RichText name={item.name} placeholder={item.label} />,
      jsonList: (item) => <JsonList list={item.list} name={item.name} placeholder={item.label} />,
      arrayIncluder: (item) => <ArrayIncluder name={item.name} placeholder={item.label} />
    }

    return valid[listInput.type] ? valid[listInput.type](listInput) : <></>;

  }

  const openInputs = (index) => {

    const updateItem = (data) => {

      const valuesItems = [...value];
      valuesItems[index] = { ...data };

      setValue([...valuesItems]);
      removeModal('updateForm')

    }



    addModal({
      title: 'Atualizar', theme: "whiteModal", key: "updateForm", content: <Form onSubmit={updateItem} initialData={{ ...value[index] }}>

        {list.map((item => {
          return validTypes(item);
        }))}

        <aside className='displayCenter'>
          <button className='defaultButton' style={{ width: '200px', margin: '10px auto' }}>Atualizar</button>
        </aside>

      </Form>
    }
    )

  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', padding: '20px 10px', border: '2px solid #eee' }}>
      <h2 style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>{placeholder}

        <div className="iconMod"> <FaPlus onClick={() => addRow()} size={15} style={{ margin: '0px 3px', cursor: 'pointer' }} /></div>
      </h2>

      <Container>{printLines()}</Container>
    </div>
  );
};

export default JsonInputList;
