import { Form } from '@unform/web';
import React, { useEffect, useState } from 'react';
import { FaBell, FaCertificate, FaFile, FaIdBadge, FaImages, FaQuestionCircle, FaReceipt, FaRegIdBadge, FaSubscript, FaTicketAlt, FaTrophy, FaUser } from 'react-icons/fa';
import { FiMessageCircle } from 'react-icons/fi';

import { IconType } from 'react-icons/lib';
import { useAuth } from '../../../../hooks/Auth';
import { useConfiguration } from '../../../../hooks/Configuration';
import { useLanguage } from '../../../../hooks/Language';
import api from '../../../../services/api';
import { dateFullWithHour } from '../../../../utils/date';
import format from '../../../../utils/format';
import InOut from '../../../DashboardPages/core/components/Forms/InOut';
import Sale from '../../../DashboardPages/pages/Sales';

import { Container, ContainerWhite } from './styles';

interface IProps {
  changeStatus: Function;

}

const CodeUsage: React.FC<IProps> = ({ changeStatus }) => {

  const { user } = useAuth();

  const [tickets, setTickets] = useState<Array<Record<string, any>>>([])

  const { projectHash, configuration } = useConfiguration();
  const { translate } = useLanguage();
  const getPaymentStatus = {

    waiting: "Aguardando Pagamento",
    paid: "Pago",
    cancelled: "Cancelado",
    refunded: "Estornado"

  }

  const getPaymentMethod = {

    boleto: "Boleto",
    credit_card: "Cartão de Crédito",
    pix: "Pix",
    deposit: "Depósito",
    cortesy: "Cortesia",

  }


  const load = async () => {



    const response = await api.get(`/my-codes-usage/${projectHash}`);

    if (response?.data?.rows?.length > 0) {
      setTickets(response.data.rows);
    }

  }

  useEffect(() => {



    load();


  }, [])

  return (
    <>
      <Container >

        <div className='user-block shadow' style={{ overflow: 'hidden' }}>
          <div className='content-block' style={{ padding: '10px' }}>

            <p className='contentTitle'>{translate('Inscrições com meu códigos')}</p>
            <table><tbody>
              {tickets?.length > 0 && <>    <tr><th>{translate('Data')}</th><th>{translate('Código do Lote')}</th> <th>{translate('Nome')}</th><th>{translate('Produto')}</th></tr>
                {tickets.map(sale => {

                  return <tr key={sale._id}>
                    <td style={{ fontSize: '14px', lineHeight: '16px' }}>{dateFullWithHour(sale?.createdAt)}</td>
                    <td style={{ fontSize: '14px', lineHeight: '16px' }}>
                      {sale?.courtesy_hash} </td>
                    <td style={{ fontSize: '14px', lineHeight: '16px' }}>
                      <strong>{sale?.client_id_response?.name}</strong></td>
                    <td style={{ fontSize: '14px', lineHeight: '16px' }}>
                      {sale?.product_id_response.title} </td>
                  </tr>
                })}
              </>}

              {tickets?.length < 1 &&
                <tr  ><td>{translate('Nenhuma inscrição identificada com um código da sua conta')}</td></tr>}
            </tbody>
            </table>
          </div>

        </div>
      </Container>
    </>
  );
};
export default CodeUsage;
