import { Form } from '@unform/web';
import React, { useEffect, useState } from 'react';
import { FaBell, FaCertificate, FaCheckCircle, FaExclamationCircle, FaFile, FaIdBadge, FaImages, FaQuestionCircle, FaReceipt, FaRegIdBadge, FaSubscript, FaTicketAlt, FaTrophy, FaUser } from 'react-icons/fa';
import { FiMessageCircle } from 'react-icons/fi';

import { IconType } from 'react-icons/lib';
import { useAuth } from '../../../../hooks/Auth';
import { useConfiguration } from '../../../../hooks/Configuration';
import api from '../../../../services/api';
import InOut from '../../../DashboardPages/core/components/Forms/InOut';


import PagamentosGeral from '../PagamentosGeral';


import { Container, ContainerWhite } from './styles';
import { useParams } from 'react-router-dom';
import { useModal } from '../../../../hooks/Modal';
import { useToast } from '../../../../hooks/Toast';
import Input from '../../../../components/Forms/Input';
import Loader from '../../../../components/Loader';
import SelectSimple from '../../../../components/Forms/SelectSimple';
import CheckboxInput from '../../../../components/Forms/CheckboxInput';
import { useLanguage } from '../../../../hooks/Language';
import CodeUsage from '../InscricoesComMeuCodigo';




const UserHome: React.FC = () => {

  const { user } = useAuth();
  const { projectHash } = useParams<Record<string, any>>();
  const [userInfo, setUserInfo] = useState<Record<string, any>>({});
  const [projects, setProjects] = useState<Array<Record<string, any>>>([]);
  const [dashboardStatus, setDashboardStatus] = useState('myPayments');
  const { addModal } = useModal();
  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);


  const changeStatus = (value) => {
    setDashboardStatus(value);
    window.scrollTo(0, 0);
  }

  const { configuration } = useConfiguration();
  const [myMedicSubscription, setMyMedicSubscription] = useState<Record<string, any>>({})
  const { translate } = useLanguage();
  const loadProjects = async () => {
    const response = await api.get('/get-projects');
    if (response?.data) {

      response?.data?.rows.sort((a, b) => {
        return a.title > b.title ? 1 : a.title < b.title ? -1 : 0;
      })


      setProjects(response?.data.rows);
    }
  }



  useEffect(() => {
    loadProjects();

  }, [])



  const dashboardStates = [

    { title: translate('Meus Pagamentos'), Icon: <FaBell />, state: 'myPayments', getPage: () => <PagamentosGeral changeStatus={(value) => changeStatus(value)} />, show: false },
    { title: translate('Códigos utilizados'), Icon: <FaReceipt />, state: 'myCodeUsage', getPage: () => <CodeUsage changeStatus={(value) => changeStatus(value)} />, show: false },
  ]







  const currentState = dashboardStates.find(state => state.state === dashboardStatus);





  return (
    <>
      {loading && <Loader message='Carregando ...' />}
      <Container id="dashboardUser" >
        <div className='p800 headerBoard' >
          <h2>{configuration?.title}</h2>
          <div style={{ display: 'flex', width: '100%', gap: '10px', justifyContent: 'flex-end' }}>

            <button className='defaultButtonMini' onClick={() => setDashboardStatus('myPayments')}>{translate('Meus pagamentos')}</button>
            <button className='defaultButtonMini' onClick={() => setDashboardStatus('myCodeUsage')}>{translate('Códigos utilizados')}</button>
          </div>

          {dashboardStatus === 'myPayments' ? <>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', marginTop: '10px' }} >


            </div>
            {currentState?.getPage()} </> : <>


            <div >
              <h2 style={{ background: '#41517E', padding: '10px', margin: '10px 0px', marginBottom: '20px', color: '#fff', borderRadius: '5px' }}>
                {configuration?.current_event_id_response?.title}
              </h2>


            </div>

            <div className='user-header'>
              <h2>
                Área do Participante
              </h2>

              <div />

            </div>
            <div className='user-block shadow'>
              <div className='content-block'>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                  {dashboardStates.map(dash => {
                    return dash?.show === false ? <> </> : <nav key={dash.title} onClick={() => changeStatus(dash.state)} className={`dashboardMenu ${dashboardStatus === dash.state ? 'dashboardMenuActive' : ''}`} > {dash.Icon} <p className='computerOnly'>{dash.title}</p></nav>
                  })}



                </div>
              </div>


            </div>

            <div className='user-header-submenu'>
              <h2>
                {currentState?.Icon}{` ${currentState?.title}`}
              </h2>

              <div />

            </div>

            {currentState?.getPage()}



          </>}


        </div>
      </Container>
    </>
  );
};
export default UserHome;
