import * as Yup from 'yup';

interface IYupRule {
  type: 'email' | 'required' | 'min' | 'max' | 'length';
  message?: string;
  quantity?: number;
}

export interface IYup {
  type: string;
  label: string;
  rules: Array<IYupRule>;
  name: string;
}

export function defaultValidationMessage(
  type: string,
  label: string,
  quantity = 1,
): string {
  const validMessages: Record<string, Function> = {
    required: () => `O campo ${label} é obrigatório`,
    email: () => `Insira uma e-mail válido`,
    min: () => `Valor mínimo de ${quantity} no campo ${label}`,
    max: () => `Valor máximo de ${quantity} no campo ${label}`,
    length: () => `O campo ${label} deve ter no mínimo ${quantity} caracteres`,
  };

  return validMessages[type]
    ? validMessages[type]()
    : `Campo ${label} inválido`;
}

export function validation(
  yup: any,
  label: string,
  type: 'email' | 'required' | 'min' | 'max' | 'length',
  message?: string,
  quantity?: number,
): any {
  const validations = {
    email: () =>
      yup.email(message || defaultValidationMessage(type, label, quantity)),
    required: () =>
      yup.required(message || defaultValidationMessage(type, label, quantity)),
    min: () =>
      yup.required(message || defaultValidationMessage(type, label, quantity)),
    max: () =>
      yup.required(message || defaultValidationMessage(type, label, quantity)),
    length: () =>
      yup.required(message || defaultValidationMessage(type, label, quantity)),
  };

  return validations[type] ? validations[type]() : yup;
}

export function createSchemaByField(name: string, field: IYup): any {
  if (!field.rules) {
    return false;
  }

  const { type, rules, label }: IYup = field;

  if (!Yup.string) {
    return false;
  }

  let element = Yup.string();
  rules.map(rule => {
    if (!element[rule.type]) {
      return false;
    }

    const message = rule?.message ? rule?.message : '';
    const quantity = rule?.quantity ? rule?.quantity : 0;

    element = validation(element, label, rule.type, message, quantity);
    return false;
  });

  return element;
}

export function createSchemaByForm(
  schema: Record<string, IYup>,
): Record<string, any> {
  const schemaFields: Record<string, any> = {};

  Object.keys(schema).map(key => {
    const field = schema[key];
    if (!field.rules) {
      return false;
    }

    const { name } = field;

    schemaFields[name] = createSchemaByField(name, field);

    return schemaFields[name];
  });

  const schemaObject = Yup.object().shape(schemaFields);
  return schemaObject;
}
