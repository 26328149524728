import React, { useState, createContext, useContext, useCallback } from 'react';
import { uuid } from 'uuidv4';
import ModalContainer from '../components/ModalContainer';

export interface IModalMessage {
  id: string;
  title: string;
  content: JSX.Element;
  key?: string;
  theme?: string;

  closeButton?: boolean;
}

interface IModalContextData {
  addModal(message: Omit<IModalMessage, 'id'>): void;
  removeModal(id: string): void;
  prepareKey(): string;
}

const ModalContext = createContext<IModalContextData>({} as IModalContextData);

const ModalProvider: React.FC = ({ children }) => {
  const [messages, setMessages] = useState<IModalMessage[]>([]);
  const [className, setClassName] = useState('');

  const [closeButton, setCloseButton] = useState(true);

  const prepareKey = useCallback(() => uuid(), []);

  const addModal = useCallback(
    ({ title, content, key, theme = '', closeButton = true }: Omit<IModalMessage, 'id'>) => {
      const id = key || uuid();
      const newMessage = {
        id,
        title,
        content,
        theme: theme
      };
      setCloseButton(state => closeButton);
      setClassName(state => theme);
      setMessages(state => [...state, newMessage]);

      return id;
    },
    [],
  );
  const removeModal = useCallback((id: string) => {
    setMessages(state => state.filter(message => message.id !== id));
  }, []);

  return (
    <ModalContext.Provider value={{ addModal, removeModal, prepareKey }}>
      {children}
      <ModalContainer messages={messages} className={className} closeButton={closeButton} />
    </ModalContext.Provider>
  );
};

function useModal(): IModalContextData {
  const context = useContext(ModalContext);

  if (!context) {
    throw new Error('useModal must be used within a ModalProvider');
  }

  return context;
}

export { useModal, ModalProvider };
