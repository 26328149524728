import React from 'react';
import { useSearch } from '../../../../../../hooks/Search';
import { useAuth } from '../../../../../../hooks/Auth';
import { urlWebsite } from '../../../../../../config';
import { Container } from './styles';
import { FaBuilding, FaCalendar, FaCalendarAlt, FaCalendarCheck, FaChair, FaChartArea, FaEdit, FaHandHoldingHeart, FaHeart, FaHome, FaMoneyBillWave, FaVideo } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import lineModules from '../../../../core/components/lineModules';
import { FiCalendar, FiMenu, FiMonitor } from 'react-icons/fi';

import Resume from '../../../Resumo';
import { useModal } from '../../../../../../hooks/Modal';
import { AiFillMoneyCollect } from 'react-icons/ai';

interface ILine {
  [key: string]: string;
}
interface IParams {
  projectHash: string;
  eventHash: string;
}

const translateType = (type) => {

  const types = {
    free: <label className='label free'><FaHandHoldingHeart /> Gratuíto</label>,
    paid: <label className='label paid'><FaMoneyBillWave /> Pago</label>,
  }

  return types?.[type] ? types?.[type] : <></>

}
const translatePresenceType = (type) => {
  const types = {
    online: <label className='label online'><FiMonitor /> Online</label>,
    hibrid: <label className='label hybrid'><FaChair /> Híbrido</label>,
    local: <label className='label local'><FaBuilding /> Presencial</label>,
  }

  return types?.[type] ? types?.[type] : <></>
}

export default function Render(
  id: string,
  data: Array<ILine>,
  schema: Record<string, any>,
  bodyModules: Record<string, Function>,
): JSX.Element {
  const { changeSearchOrder } = useSearch();
  const { user } = useAuth();
  const { addModal } = useModal();
  function renderColumns(
    line: Record<string, any>,
    schema: Record<string, any>,
  ): Array<JSX.Element> {
    return Object.keys(schema).map((key: string) => {
      const column = schema[key];
      return column.show !== false ? (
        <td>
          {column.includeColumn
            ? (line[column.column] &&
              line[column.column][column.includeColumn]) ||
            ''
            : line[column.column]}
        </td>
      ) : (
        <></>
      );
    });
  }

  function renderLine(line: Record<string, any>): JSX.Element {
    return (
      <tr>
        <td>
          <div style={{ display: 'flex' }}>
            {bodyModules &&
              user &&
              (user.profile === 1 || user.profile === 4) &&
              bodyModules.map((lineModule: Function) => lineModule(line))}
          </div>
        </td>
        {renderColumns(line, schema)}
      </tr>
    );
  }

  const openResume = ({ projectHash }) => {

    addModal({ theme: 'whiteModal', title: '', content: <Resume hash={projectHash} /> })

  }

  const render = (): JSX.Element => {

    return <Container>

      {data && data.map(line => {

        return <div className='project-line' key={line._id}>

          <div >
            <img src={line.thumb ? `${urlWebsite}/${line.thumb}` : 'apoio/eventos.jpeg'} />

          </div>
          <div className='project-line-content'>
            <h2>{line.title}</h2>
            <div className='line'><h2>{translateType(line.type)}</h2>
              <h2>{translatePresenceType(line.presence_type)}</h2>
            </div>
            <div className='project-line-icons'>

              <div style={{ position: 'absolute', top: '0px', right: '0px' }}  >{bodyModules[0](line)} </div>
              <aside style={{ cursor: 'pointer' }} onClick={() => openResume({ projectHash: line.url })}><FaChartArea title="Relatórios" /></aside>
              <Link to={`/manager/satisfaction_project/${line.url}`}><FaHeart title="Pesquisa de satisfação" /></Link>
              <Link to={`/manager/events/${line.url}`}><FaCalendarAlt title="Eventos" /></Link>
              <Link to={`/app/${line.url}`}><FaHome title="Visitar website" /></Link>
              <Link to={`/app/${line.url}/dashboard`}><FaVideo title="Visitar área de palestras" /></Link>
              <Link to={`/manager/home2/${line.project_id}/${line.url}/`}><FiMenu title="Menu" /></Link>
              {bodyModules.map((lineModule: Function) => lineModule(line))}
            </div>
          </div>

        </div>


      })}

    </Container>




    /* return (
      <table>
        <thead>
          <tr>
            <th />
            {schema &&
              Object.keys(schema).map((key: string) =>
                schema[key].show !== false ? (
                  <th
                    onClick={() =>
                      changeSearchOrder(id, schema[key].ref, 'asc')
                    }
                  >
                    {schema[key].label}
                  </th>
                ) : (
                  ''
                ),
              )}
          </tr>
        </thead>
        <tbody>{data && data.map(line => renderLine(line))}</tbody>
      </table> */

  };

  return render();
}
