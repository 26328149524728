import styled from 'styled-components';
import { ContainerRef } from '../styles';

export const Container = styled(ContainerRef)`

background: none ;
color: #fff;
border-color: #fff;
border-radius: 12px;
input{
    color: #fff;
&::placeholder{
    color: #fff;
}
}

span {
    color: #333 !important;
}

`;