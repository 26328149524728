
import React, { useEffect, useState } from 'react';
import { FaArrowCircleRight, FaMapMarker } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { urlWebsite } from '../../../../config';
import { useConfiguration } from '../../../../hooks/Configuration';
import { useLanguage } from '../../../../hooks/Language';
import {

    Capa, Container,



} from '../Themes/SinglePageTheme/styles';
import ProductsList from './modules/Products';

const HomeApplication = () => {
    const { configuration, projectHash } = useConfiguration();
    const { translate } = useLanguage();

    const [theme, setTheme] = useState({
        titleColor: configuration?.current_event_id_response?.theme?.title_color || '#fff',
        textColor: configuration?.current_event_id_response?.theme?.text_color || '#fff',
        buttonColor: configuration?.current_event_id_response?.theme?.button_color || '#333',
        buttonBackground: configuration?.current_event_id_response?.theme?.button_background || '#3FB8EB',
    })


    useEffect(() => {
        setTheme({
            titleColor: configuration?.current_event_id_response?.theme?.title_color || '#fff',
            textColor: configuration?.current_event_id_response?.theme?.text_color || '#fff',
            buttonColor: configuration?.current_event_id_response?.theme?.button_color || '#333',
            buttonBackground: configuration?.current_event_id_response?.theme?.button_background || '#3FB8EB',
        })
    }, [configuration])


    return <><div className='row-to-column' style={{ alignItems: 'center', paddingTop: '50px' }}>

        <div className='column-start gap-sm pad pad-mobile'>
            <p className='color2'><strong className='color2'>Online, híbrido ou presencial</strong> </p>
            <h2 className='title color-white'>
                Seu <span className='color2'>evento</span> <br />
                em uma só <br />
                plataforma.


            </h2>

            <p className='color-white max-400'> Transmitimos e gerenciamos seu evento em
                uma plataforma personalizada pra você.</p>
        </div>

        <div style={{ position: 'relative', alignSelf: 'flex-end' }}>



            <img src="/apoio/pattern.png" style={{ position: 'absolute', zIndex: 0, top: '0px', right: '0px', width: '90%' }} />
            <img src="/apoio/menina.png" style={{ zIndex: 10, position: 'relative', width: '100%', marginBottom: '-4px' }} />

        </div>

    </div>

        <div className='full-width row-to-column background2 pad-xl gap temosSolucao'>
            <div>
                <p className='color1 max-350'>
                    Temos a solução que você precisa para
                    que seu evento seja um sucesso!<br />
                    <br />
                    <strong className='color1'>comercial@encontrodigital.com.br</strong>
                </p>


            </div>
            <div>
                <a href={`https://api.whatsapp.com/send?l=pt&phone=5551981476007&text=${encodeURI(
                    'Olá, estou no website da Encontro Digital'
                )}`} target="_BLANK"><button className='blueButtonBig'>
                        <h2> SOLICITE SUA PROPOSTA</h2>
                        <FaArrowCircleRight />
                    </button></a>
            </div>
        </div>


        <div id='servicos' className='full-width row background-white pad-xl gap' style={{ position: 'relative' }}>
            <div className='column gap'>
                <h2 className='midTitle color1'>SERVIÇOS</h2>
                <p className='color1 max-600 text-center  color1 midTitle4'>
                    Desde 2020, implantamos centenas de projetos na área de eventos e ensino, abrangendo desde iniciativas locais, até grandes empresas.
                    <br />    <br />
                    Nosso foco é entregar <strong className='color2'>soluções personalizadas</strong>, que atendam a experiência do usuário de ponta a ponta, fortalecendo a marca de nossos clientes
                </p>


            </div>
            <img src="/apoio/pattern2.png" className='computerOnly' style={{ position: 'absolute', zIndex: 0, bottom: '-55%', left: '0px', width: '100%', maxWidth: '250px' }} />

        </div>


        <div className='full-width row-end'>

            <div className='column-start gap-sm computerOnly' >
                <h2 className='midTitle color-white'>
                    Seu <span className='color2'>evento</span> <br />
                    em uma só <br />
                    plataforma.


                </h2>




            </div>

            <div className='productContainerCapa computerOnly' style={{ position: 'relative', minWidth: '60%' }}>


                <div className='column productItem item1'>
                    <img src="/apoio/venda.png" />
                    <p className='color1'>Venda</p>
                </div>

                <div className='column productItem item2'>
                    <img src="/apoio/lp.png" />
                    <p className='color1'>Landing Page</p>
                </div>


                <div className='column productItem item3'>
                    <img src="/apoio/game.png" />
                    <p className='color1'>Gamificação</p>
                </div>

                <div className='column productItem item4'>
                    <img src="/apoio/transmissao.png" />
                    <p className='color1'>Transmissão</p>
                </div>

                <div className='column productItem item5'>
                    <img src="/apoio/credenciamento.png" />
                    <p className='color1'>Credenciamento</p>
                </div>

                <div className='column productItem item6'>
                    <img src="/apoio/expositores.png" />
                    <p className='color1'>Expositores</p>
                </div>

                <img src="/apoio/produtos.png" style={{ width: '100%' }} />

            </div>
            <div className=' mobileOnly row flex-wrap' style={{ position: 'relative', minWidth: '100%' }}>


                <div className='column pad background2' style={{ width: '50%', border: '1px solid rgb(24,18,49)' }}>
                    <img src="/apoio/venda.png" />
                    <p className='color1' style={{ marginTop: '15px' }}>Venda</p>
                </div>

                <div className='column pad  background2' style={{ width: '50%', border: '1px solid rgb(24,18,49)' }}>
                    <img src="/apoio/lp.png" />
                    <p className='color1' style={{ marginTop: '15px' }}>Landing Page</p>
                </div>


                <div className='column pad  background2' style={{ width: '50%', border: '1px solid rgb(24,18,49)' }}>
                    <img src="/apoio/game.png" />
                    <p className='color1' style={{ marginTop: '15px' }}>Gamificação</p>
                </div>

                <div className='column pad  background2' style={{ width: '50%', border: '1px solid rgb(24,18,49)' }}>
                    <img src="/apoio/transmissao.png" />
                    <p className='color1' style={{ marginTop: '15px' }}>Transmissão</p>
                </div>

                <div className='column pad  background2' style={{ width: '50%', border: '1px solid rgb(24,18,49)' }}>
                    <img src="/apoio/credenciamento.png" />
                    <p className='color1' style={{ marginTop: '15px' }}>Credenciamento</p>
                </div>

                <div className='column pad  background2' style={{ width: '50%', border: '1px solid rgb(24,18,49)' }}>
                    <img src="/apoio/expositores.png" />
                    <p className='color1' style={{ marginTop: '15px' }}>Expositores</p>
                </div>



            </div>

        </div>


        <div id='solucoes' className='full-width row background-white pad-xl gap' style={{ position: 'relative' }}>
            <div className='column  '>
                <h2 className='midTitle  color1'>SOLUÇÕES PERSONALIZADAS</h2>
                <p className=' midTitle3 color2 max-600 text-center  color1'>
                    Conheça nossos recursos
                </p>
                <div style={{ marginTop: '25px' }} className='row full-width flex-wrap max-1000 gap-sm '>
                    <ProductsList />
                </div>
            </div>

        </div>
        <Container id='clientes' className='row-to-column ' style={{ paddingTop: '100px', paddingBottom: '100px' }}>
            <div className='row-to-column pad gap full-width max-1200' style={{ alignItems: 'center' }}><div className='column-start gap-sm full-width max-400' >

                <h2 className='midTitle color-white'>
                    Clientes <br />que <strong className='color2'>confiam</strong>


                </h2>

                <p className='color-white'> Instituições que confiaram na <br /><strong className='color-white'>Encontro Digital</strong> para realizar <br />seus eventos.</p>
            </div>

                <div style={{ position: 'relative' }}>



                    <img src="/apoio/clientes.png" style={{ width: '100%', zIndex: 10, position: 'relative', }} />

                </div>
            </div>
        </Container>
    </>

}

export default HomeApplication;