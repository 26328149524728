import styled, { css, keyframes } from 'styled-components';
import { defaultTheme } from '../../config';

interface ISpeaker {
  background?: string;
}

const fadeIn = keyframes`
0% {
  opacity: 0;
  filter: blur(10deg);
  transform: translateY(-20px);
}
100% {
  opacity: 1;
  filter: blur(0deg);
  transform: translateY(0px);
}
`;

export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
  background: none;
  flex-direction: column;
  
  padding: 0px;
  margin: 10px auto;
  position: relative;
  width: 100% ;

  max-width: 1000px;
  margin: 0px auto;


  .fadeIn{
    animation : ${fadeIn} 1s forwards;
  }


  @media (max-width: 1200px) {
    padding: 20px 0px;

  }

  > div.title {
    font-size: 24px;
    color: #fff;
    text-align: left;
    max-width: 100%;

    
    margin-top: 0px;

    > h2 {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      text-align: center;
      font-size: 28px;
      word-wrap: break-word;
      margin-bottom: 20px;

      
      @media (max-width: 750px) {
        text-align: center;
  }
      > svg {
        margin: 5px 10px;
        cursor: pointer;
      }
    }

    >p{
      font-size: 16px;
      width: 100%;
      text-align: center;
      margin-bottom: 20px;
      @media (max-width: 750px) {
        text-align: center;
  }
    }

    padding: 0px;

    @media (max-width: 750px) {
      padding: 10px 10px;

  }
    

  }
`;

export const Grid = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  max-width: 1000px;
  position: relative;
  z-index: 20;
  margin-top: 30px;

  gap: 25px;

  @media (max-width: 750px) {
    margin-top: 0px;

  }
`;

export const SpeakerDiv = styled.div`
  width: 227px;
  margin: 0px;
 //background: #fff ;
  border-radius: 10px;
  margin-top: 20px;


  .speakerSeparator{
    width: 100%;
    height: 1px;
    background: #06496E;
  }

  .contentSpeaker{
    padding: 15px;
    display: flex;
   // background: #fff;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    max-width: 227px;
    margin: 0px auto;

    border-radius: 0px 0px 12px 12px;

    >div{
      width: 100%;
    }

    p,strong,h2{
 
      width: 210px;
      color: rgb(0,14,60);
      max-width: 100%;
      @media (max-width: 750px) {
    width: 100%;
    
    font-size: 10px;
  }
    }

    .positionSpeaker{
      font-size: 13px;
      text-align: center;
      width: 100%;
      max-width: 160px;
      

      @media (max-width: 750px) {
 
    font-size: 10px;
  }
    }

    h3{
 
      font-size: 16px;
      text-align: center;
      text-align: center !important;
      @media (max-width: 750px) {
 
 font-size: 16px;
}
    }
  }
  

  @media (max-width: 750px) {
    width: 140px;
    margin:  0px;
    margin-top: 20px;
  }
`;

export const Speaker = styled.div<ISpeaker>`
  width: 227px;
  height: 218px;
 
  background: url(${props => props.background || '/apoio/user.jpg'}) no-repeat
   top  center;
  background-size: cover;
  margin: 0px;
  position: relative;
  transition: 0.5s all;
  margin: 0px auto;
  cursor: pointer;
  border-radius: 12px 12px 0px 0px;
  border: 0px solid #188582;

  transition: 0.4s all ;

  &:hover{
    transform: scale(1.05) ;
  }

  svg {
    font-size: 18px !important;
    color: #fff;
    
  }
  .socialIcon{
    opacity: 1;
    padding: 5px;
    border-radius: 10px;
    width: 30px;
    height: 30px;

    background: yellow;
      svg{
      color: #333;
    }

    &:hover{
      transform: scale(1.1);
    }


  }

  > div {
  
    width: 100%;
    background: none;
    border-radius: 10px;

    height: 100%;
    justify-content: flex-end;

    color: #fff;
    padding: 20px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    transition: 0.5s all;
    flex-direction: column;
    height: auto;
    height: 100%;

    > h2 {
      font-size: 14px;
      padding: 0px;
      transition: 0.5s all;
      color: #fff !important;
      background: rgba(0,0,0,0.8);
      padding: 2px;
    }

    > p {
      font-size: 10px;
      padding: 2px;
      transition: 0.5s all;
      height: auto;
      overflow: hidden;
      background: rgba(0,0,0,0.8);
      > p {
        line-height: 14px;
        background: rgba(0,0,0,0.8);
      }
    }
  }

  &:hover {
    > div {
      height: 100%;
      justify-content: flex-end;
      padding-bottom: 30px;
      background: rgb(50, 70, 107, 0.2);

      > p {
        font-size: 12px;
        height: auto;
        overflow: auto;
      }
    }
  }

  @media (max-width: 750px) {
    width: 140px;
    height: 140px;

    > div {
      padding: 10px;
      > h2 {
        font-size: 10px;
        font-weight: bold;
        padding: 0px;
        transition: 0.5s all;
      }

      > p {
        font-size: 9px;
        padding: 0px;
        transition: 0.5s all;
        height: 12px;
        overflow: hidden;
      }
    }

    &:hover {
      > div {
        height: 100%;
        justify-content: flex-end;
        padding-bottom: 20px;
        > p {
          font-size: 10px;
          height: auto;
          overflow: auto;
        }
      }
    }
  }
`;

export const SpeakerListContainer = styled.div`
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: flex-start;
  width: 100%;
  position: relative;
  z-index: 20;
  margin: 20px auto;

  max-width: 400px;
`;

export const SpeakerLine = styled.li<ISpeaker>`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: rgba(0, 0, 0, 0.3);
  cursor: pointer;

  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }

  > div {
    margin: 5px;
  }

  .speakerPhoto {
    background: url(${props => props.background || '/apoio/user.jpeg'})
      no-repeat center center;
    background-size: cover;
    width: 50px;
    height: 50px;
    min-height: 50px;
    min-width: 50px;
  }

  .speakerPlus {
    width: 30px;
    height: 30px;
    min-height: 30px;
    min-width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    > svg {
      font-size: 22px;
      color: #fff;
    }
  }

  margin: 5px;
  position: relative;
  transition: 0.5s all;

  > div.speakerInfo {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    transition: 0.5s all;
    flex-direction: column;
    max-width: 400px;

    > h2 {
      font-size: 14px;
      padding: 0px;
      transition: 0.5s all;
      color: #fff !important;
    }

    > p {
      font-size: 12px;
      padding: 0px;
      transition: 0.5s all;
      line-height: 1rem;
      color: #fff !important;
    }
  }
`;

export const ContainerWhite = styled(Container)`
  min-height: auto;
  height: auto;
  z-index: 100;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #fff;
  color: #555;
  padding: 50px 10%;
  width: 100%;
  max-width: 100%;
  margin-top: 20px;
  animation : ${fadeIn} 1s forwards;

  @media (max-width: 1200px) {
    margin-top: 20px;
    padding: 0px 20px;
    flex-direction: column;
  }

  strong {
    color: #fff;
    display: block;
  }

  div {
  h2, p, h3, strong {
    color: rgb(0,14,64) !important;
  }
}

h2, p, h3, strong {
    color: rgb(0,14,64) !important;
  }

  > .p50 {
    z-index: 1;
    width: 50%;
    min-width: 50%;
    margin: 15px 0;
    align-items: flex-start;
    justify-content: center;
    @media (max-width: 850px) {
      width: 100%;
      min-width: 100%;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
    padding: 10px 10px;
    margin: 5px auto;

    img, h2, p, strong{
      margin: 0px auto;
    }
    }

    > h2 {
      font-size: 1.5em;
    }

    > p {
      text-align: left;
    }
  }
  p {
      text-align: left;
    }
  .p600 {
    max-width: 600px;
    min-width: auto;
    width: 100%;

    font-size: 16px;

    > strong {
      font-weight: bold;
 
    }
  }
`;
