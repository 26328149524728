import React, { useEffect, useState } from 'react';
import { useConfiguration } from '../../../../../hooks/Configuration';

import Program from '../../Program';
import Speakers from '../../../../../pages/Speakers';
import SponsorsPage from '../../../../../pages/SponsorsPage';
import HomeApplication from '../../Home';
import HomeApplicationRight from '../../HomeRight';
import Discussoes from '../../Discussoes';
import CustomPage from '../../CustomPage';
import Subscription from '../../../../../pages/Subscription';
import ButtonsLinks from '../../Buttons';
import SpeakersListByCountry from '../../SpeakersListByCountry';
import SignIn from '../../../../../pages/SignIn';
import EdicoesAnteriores from '../../EdicoesAnteriores';

const Template: React.FC = () => {
  const { configuration } = useConfiguration();

  const { projectHash = configuration?.url, eventHash } = useConfiguration();
  const [layoutElements, setLayoutElements] = useState<Array<JSX.Element>>([]);


  const eventPageLayout = configuration?.current_event_id_response?.landing_page ? configuration?.current_event_id_response?.landing_page : [
    { reference: 'home' },

    { reference: 'speaker' },
    { reference: 'program' },

    { reference: 'sponsor' },


  ]

  const generatePage = () => {

    const valid = {
      home: ({ hash }) => <HomeApplication />,
      signin: ({ hash }) => <SignIn />,
      homeRight: ({ hash }) => <HomeApplicationRight />,
      discussoes: ({ hash }) => <Discussoes />,
      buttons: ({ hash }) => <ButtonsLinks />,
      program: (hash) => <Program />,
      speaker: ({ hash }) => <Speakers />,
      custom_page: ({ hash }) => <CustomPage hash={hash} />,
      sponsor: ({ hash }) => <SponsorsPage />,
      sale: ({ hash }) => <Subscription />,
      edicoes: ({ hash }) => <EdicoesAnteriores />,
    }

    const items: Array<JSX.Element> = [];

    eventPageLayout.map(layoutItem => {


      if (valid?.[layoutItem?.reference]) {
        items.push(valid[layoutItem.reference]({ hash: layoutItem?.hash || '' }));
      }

    })

    setLayoutElements(items);

  }

  useEffect(() => {
    if (projectHash) {
      generatePage();
    }
  }, [projectHash, eventHash])


  return <>{layoutElements}</>;
};
export default Template;
