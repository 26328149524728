import React from 'react';
import api from '../../../../../../services/api';
import { useToast } from '../../../../../../hooks/Toast';

import { useAuth } from '../../../../../../hooks/Auth';
import { FiSend } from 'react-icons/fi';
import Whatsapp from '../../../../../../components/Whatsapp';
import { authTitle, defaultProjectHash } from '../../../../../../config';
import { FaMoneyBill, FaTicketAlt, FaUser } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import downloadit from '../../../../../../utils/download';

interface ICreateModule {
  lineData: Record<string, any>;
}

export const CheckUniqueModule: React.FC = () => {

  const { projectHash = defaultProjectHash } = useParams<Record<string, any>>();
  const { addToast } = useToast();


  const gerarRelatorioFinanceiro = async () => {

    addToast({ title: 'Gerando relatório', type: 'info' });

    await downloadit({ url: `/get-sale-report/${projectHash}/sale` });


  }

  const moduleUpdate = (): JSX.Element => (
    projectHash ? <button title='Ingressos vendidos' className="searchButton" type="button" onClick={gerarRelatorioFinanceiro}>
      <FaTicketAlt />
    </button> : <></>
  );

  return moduleUpdate();
}
