import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FiArrowLeft } from 'react-icons/fi';
import { Link, useHistory, useParams } from 'react-router-dom';
import { FormHandles } from '@unform/core';

import * as Yup from 'yup';

import { Container, Content, Background, AnimatedDiv } from './styles';

import getValidationErrors from '../../utils/getValidationErrors';
import api from '../../services/api';
import { useToast } from '../../hooks/Toast';

import FormContainer from './FormContainer';
import { useLanguage } from '../../hooks/Language';


interface SignUpFormData {
  name: string;
  email: string;
  password: string;
}

interface IParams {
  projectHash: string;
  eventHash: string;
}

const CortesyForm: React.FC = () => {
  const history = useHistory();
  const { addToast } = useToast();
  const [withPassword, setWithPassword] = useState(false);
  const { projectHash, eventHash } = useParams<IParams>();
  const formRef = useRef<FormHandles>(null);
  const { translate } = useLanguage();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  return (<div className='column gradient1 full-width'>

    <div className='fundoBlue full-width row' style={{ padding: '50px 20px', zIndex: 1000, boxShadow: '0px 4px 4px 0px rgba(0,0,0,0.25)', }}>

      <div className='full-width header-sub' style={{ maxWidth: '1000px', justifyContent: 'space-between' }}>


        <div className='column full-width' style={{ maxWidth: '300px' }}>

          <div className='space-between gap-sm full-width'>

            <p className='color-white medium midTitle'>INSCRIÇÃO</p>
            <p className='color-white medium midTitle'>/ /</p>
          </div>
          <p className='color-white medium midTitle'>PRESENCIAL</p>
        </div>

        <div style={{ maxWidth: '250px', width: '100%' }}>
          <img src="/assets/logo.png" className='full-width' />
        </div>

      </div>


    </div>


    <Container>
      <Content>
        <AnimatedDiv>
          <Link to={`/`} style={{ color: 'rgb(0,15,64)' }}>
            <FiArrowLeft />
            {translate('Voltar para o site')}
          </Link>


          <FormContainer />
        </AnimatedDiv>
      </Content>
    </Container>


  </div>
  );
};

export default CortesyForm;
