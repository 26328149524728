import React from 'react';

import { AuthProvider } from './Auth';
import { ToastProvider } from './Toast';

import { ModalProvider } from './Modal';
import { SearchProvider } from './Search';
import { ScrollProvider } from './Scroll';
import { SocketProvider } from './Socket';
import { PagesProvider } from './Pages';
import { InteractionProvider } from './Interactions';
import { LanguageProvider } from './Language';
import { ConfigurationProvider } from './Configuration';
import { LoadingProvider } from './LoadingHook';
import { PrinterProvider } from './PrinterHook';
const AppProvider: React.FC = ({ children }) => {
  return (<PrinterProvider>
    <LoadingProvider>
      <AuthProvider>
        <LanguageProvider>
          <PagesProvider>
            <SocketProvider>
              <ConfigurationProvider>
                <InteractionProvider>
                  <ToastProvider>
                    <ModalProvider>
                      <SearchProvider>
                        <ScrollProvider>{children}</ScrollProvider>
                      </SearchProvider>
                    </ModalProvider>
                  </ToastProvider>
                </InteractionProvider>
              </ConfigurationProvider>
            </SocketProvider>
          </PagesProvider>
        </LanguageProvider>

      </AuthProvider>
    </LoadingProvider>
  </PrinterProvider>
  );
};

export default AppProvider;
