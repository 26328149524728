
import React from 'react';
import { FaCheck, FaCheckSquare } from 'react-icons/fa';

const ProductsList = () => {

    const items = [
        {
            title: 'Landing ',
            subtitle: 'Page',
            list: [
                {
                    title: 'Venda',
                    url: 'sale'
                },
                {
                    title: 'Inscrição',
                    url: 'inscricao'
                },
                {
                    title: 'Palestrante',
                    url: 'palestrantes'
                },
                {
                    title: 'Patrocinador',
                    url: 'patrocinadores'
                },
                {
                    title: 'Programação',
                    url: 'programacao'
                },
                {
                    title: 'Local do evento',
                    url: 'local'
                },
            ]
        },
        {
            title: 'Evento ',
            subtitle: 'Online',
            list: [
                {
                    title: 'Conteúdo on Demand',
                    url: 'ondemand'
                },
                {
                    title: 'Controle de acesso',
                    url: 'controle'
                },
                {
                    title: 'Transmissão ao vivo',
                    url: 'transmissao'
                },
                {
                    title: 'Enquetes',
                    url: 'enquetes'
                },
                {
                    title: 'Sorteios',
                    url: 'sorteios'
                },
                {
                    title: 'Chat',
                    url: 'chat'
                },
                {
                    title: 'Equipe especializada',
                    url: 'equipe'
                },
                {
                    title: 'Controle de presença',
                    url: 'presenca'
                },
            ]
        },
        {
            title: 'Evento ',
            subtitle: 'Presencial',
            list: [
                {
                    title: 'Credenciamento',
                    url: 'credenciamento'
                },
                {
                    title: 'Controle de acesso',
                    url: 'controle'
                },
                {
                    title: 'Área de expositores',
                    url: 'expositores'
                },
                {
                    title: 'Autoatendimento',
                    url: 'autoatendimento'
                },
                {
                    title: 'Plantão técnico',
                    url: 'plantao'
                },
                {
                    title: 'Relatório ao vivo',
                    url: 'relatorio'
                },

            ]
        },

        {
            title: 'Módulo de ',
            subtitle: 'Vendas',
            list: [
                {
                    title: 'Cartão de crédito',
                    url: 'cartao'
                },
                {
                    title: 'Boleto',
                    url: 'boleto'
                },
                {
                    title: 'PIX',
                    url: 'pix'
                },
                {
                    title: 'Cortesias',
                    url: 'cortesias'
                },
                {
                    title: 'Cupom de desconto',
                    url: 'cupom'
                },
                {
                    title: 'Controle de lotes',
                    url: 'lotes'
                },
                {
                    title: 'Controle de quantidade',
                    url: 'quantidade'
                },

            ]
        },

        {
            title: 'Portais de',
            subtitle: 'Conteúdos',
            list: [
                {
                    title: 'Centralize seus eventos',
                    url: 'centralizar'
                },
                {
                    title: 'LGPD centralizada',
                    url: 'lgpd'
                },
                {
                    title: 'Base cadastral unificada',
                    url: 'cadastro'
                },
                {
                    title: 'Aumente seus Leads',
                    url: 'leads'
                },


            ]
        },

        {
            title: 'Recursos ',
            subtitle: 'adicionais',
            list: [
                {
                    title: 'Certificados',
                    url: 'certificados'
                },
                {
                    title: 'Dashboard administrativo',
                    url: 'dashboard'
                },
                {
                    title: 'Provas online',
                    url: 'provas'
                },
                {
                    title: 'Pesquisa de satisfação',
                    url: 'pesquisa'
                },


            ]
        },

    ]


    return <>

        {items.map(item => {

            return <div className='productItemCapa pad'>
                <h2 className='color1' style={{ marginBottom: '15px' }}>{item?.title} <strong className='color2'>{item?.subtitle}</strong></h2>
                <div className='   gap-sm column-start'>
                    {item?.list?.map(option => {
                        return <div className='optionItem'> <FaCheck />
                            <p>{option?.title}</p>
                        </div>
                    })}
                </div>

            </div>


        })}

    </>
}

export default ProductsList;