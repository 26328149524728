import React, { useEffect, useState } from 'react';

import {
  SearchComponent,
  ISearchComponent,
} from '../DashboardPages/core/components/SearchComponent';

import lineModules from '../DashboardPages/core/components/lineModules'
import { Container } from './styles';
import { useParams } from 'react-router-dom';

import { useAuth } from '../../hooks/Auth';
import { useConfiguration } from '../../hooks/Configuration';
import api from '../../services/api';
import { FaFileExcel } from 'react-icons/fa';
import downloadit from '../../utils/download';
import { useToast } from '../../hooks/Toast';
import states from '../SignUp/FormContainer/states';

interface ValidationRules {
  type: 'email' | 'required' | 'min' | 'max' | 'length';
  message?: string;
  quantity?: number;
}

interface ValidationSchema {
  name: string;
  type: 'string' | 'number' | 'boolean';
  label: string;
  rules: Array<ValidationRules>;
}

interface ILine {
  [key: string]: string;
}
interface IParams {
  projectHash: string;
  eventHash: string;
}

interface IPropsListCredenciados {
  projectHash: string;

}

const ListCredenciados: React.FC<IPropsListCredenciados> = ({ projectHash }) => {

  const { configuration } = useConfiguration();
  const [confirmados, setConfirmados] = useState(0);
  const { addToast } = useToast();
  const { user, token } = useAuth();
  const load = async () => {

    setInterval(async () => {
      const count = await api.get(`/count-credenciados/${projectHash}`)

      setConfirmados(count?.data?.count || 0);
    }, 3000)

  }

  const handleDownloadNoCourtesy = async () => {

    if (user?.profile !== 1 && user?.profile !== 4) {
      addToast({ title: 'Download não autorizado', type: 'error' });
      return;
    }

    await downloadit({ url: '/students-attendance', token, type: 'xlsx', params: { where: { "$and": [{ project_id: projectHash }, { "$or": [{ extra_2: "" }, { extra_2: { "$exists": false } }] }, { reference: 'Sim' }, { profile: 2 }] } } })
    addToast({ title: 'Carregando relatório', type: 'success' });
  }

  const handleDownload = async () => {

    if (user?.profile !== 1 && user?.profile !== 4) {
      addToast({ title: 'Download não autorizado', type: 'error' });
      return;
    }

    await downloadit({ url: '/students-attendance', token, type: 'xlsx', params: { where: { project_id: projectHash, reference: 'Sim', profile: 2 } } })
    addToast({ title: 'Carregando relatório', type: 'success' });
  }

  useEffect(() => {

    load();

  }, [projectHash])

  return <aside style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center' }}><button onClick={() => { handleDownload() }} className='defaultButton'><FaFileExcel /> {`${confirmados} credenciados`}</button>
    {/* <button onClick={() => { handleDownloadNoCourtesy() }} className='defaultButton'><FaFileExcel /> {`Sem Cortesia`}</button> */}
  </aside>

}


const AreaAtendimento: React.FC = () => {
  const endpoint = '/students-attendance';
  const title = 'Inscritos';
  const { projectHash, eventHash } = useParams<IParams>();
  const { user } = useAuth();

  const schema: Record<string, any> = {
    _id: { ref: '_id', column: '_id', label: 'ID', show: false },
    project_id: { ref: 'project_id', column: 'project_id', label: 'Projeto', show: false },
    user_hash: { ref: 'user_hash', column: 'user_hash', label: 'Hash', show: false },
    event_id: { ref: 'event_id', column: 'event_id', label: 'Evento', show: false },

    reference: { ref: 'reference', column: 'reference', label: 'Credenciado?' },
    subscription_text: { ref: 'subscription_text', column: 'subscription_text', template: projectHash, label: 'Ingresso', show: false },
    name: { ref: 'name', column: 'name', label: 'Nome' },
    email: { ref: 'email', column: 'email', label: 'Email' },
    category_id: { ref: 'category_id', column: 'category_id', label: 'Categoria' },
    phone: { ref: 'phone', column: 'phone', label: 'Telefone' },

    document_number: {
      ref: 'document_number',
      column: 'document_number',
      label: 'CPF', show: false,
    },

    company: {
      ref: 'company',
      column: 'company',
      label: 'Empresa',
      show: false,
    },
    position: {
      ref: 'position',
      column: 'position',
      label: 'Cargo', show: false,
    },
    city: {
      ref: 'city',
      column: 'city',
      label: 'Cidade',
      show: false
    },
    state: {
      ref: 'state',
      column: 'state',
      label: 'Estado', show: false,
    },
    extra_1: {
      ref: 'extra_1',
      column: 'extra_1',
      label: 'Produto', show: false
    },
    extra_2: {
      ref: 'extra_2',
      column: 'extra_2',
      label: 'Cortesia?', show: false
    },
    company_document_number: {
      ref: 'company_document_number',
      column: 'company_document_number',
      label: 'CNPJ', show: false,
    },

    crm: {
      ref: 'crm',
      column: 'crm',
      label: 'CRM',
    },
    crm_uf: {
      ref: 'crm_uf',
      column: 'crm_uf',
      label: 'UF - CRM',
    },
    updated_information: {
      ref: 'updated_information',
      column: 'updated_information',
      label: 'Atualizado?', show: false,
    },

    createdAt: {
      ref: 'created_at',
      column: 'createdAt',
      label: 'Data de Criação',
      mask: 'date',
    },
  };

  const formSchema = {
    reference: {
      model: 'selectSimple',
      name: 'reference',
      label: 'Credenciado?',
      defaultValue: 'Não',
      options: [
        { label: 'Sim', value: 'Sim' },
        { label: 'Não', value: 'Não' },

      ]

    },


    name: { model: 'input', type: 'text', name: 'name', label: 'Nome' },
    document_number: { model: 'input', type: 'text', name: 'document_number', label: 'CPF / Passaporte' },
    email: { model: 'input', type: 'text', name: 'email', label: 'Email' },
    phone: { model: 'input', type: 'text', name: 'phone', label: 'Telefone' },

    category_id: {
      model: 'selectSimple', type: 'text', name: 'category_id', label: 'Categoria', options: [
        { label: 'Médico', value: 'Médico' },
        { label: 'Psicólogo', value: 'Psicólogo' },
        { label: 'Staff', value: 'Staff' },
        { label: 'Imprensa', value: 'Imprensa' },
        { label: 'Moderador', value: 'Moderador' },
        { label: 'Expositor', value: 'Expositor' },
        { label: 'Palestrante', value: 'Palestrante' },
        { label: 'Diretor', value: 'Diretor' },



      ]
    },
    crm: { model: 'input', type: 'text', name: 'crm', label: 'CRM / CRP' },
    crm_uf: { model: 'selectSimple', type: 'text', name: 'crm_uf', label: 'Estado - CRM / CRP', options: states },


    company: { model: 'input', type: 'text', name: 'company', label: 'Empresa' },
    position: { model: 'input', type: 'text', name: 'position', label: 'Cargo' },
    city: { model: 'input', type: 'text', name: 'city', label: 'Cidade' },
    state: { model: 'input', type: 'text', name: 'state', label: 'Estado' },
    company_document_number: { model: 'input', type: 'text', name: 'company_document_number', label: 'CNPJ' },


    password_hash: {
      model: 'input',
      type: 'password',
      name: 'password_hash',
      label: 'Senha',
    },
    profile: {
      model: 'selectSimple',
      name: 'profile',
      label: 'Nível',
      defaultValue: 2,
      options: user?.profile === 1 ? [
        { label: 'Administrador', value: 1 },
        { label: 'Leitor de porta', value: 30 },
        { label: 'Participante', value: 2 },
        { label: 'Mediador / Palestrante', value: 3 },
        { label: 'Organização', value: 4 },
        { label: 'Expositor', value: 5 },
        { label: 'Moderador de Chat', value: 6 },
      ] :
        [


          { label: 'Participante', value: 2 },


        ]


      ,
    },

  };

  const formValidation: Record<string, ValidationSchema> = {

  };


  const config: ISearchComponent = {
    idKey: `${endpoint}-${projectHash}-${eventHash}`,
    title,
    endpoint,
    schema,
    autoStart: true,
    headModules: [
      () =>
        lineModules.create({
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint: 'students',
        }),
      () =>
        lineModules.crachaLote({
          idKey: endpoint
        }),
      () =>
        lineModules.ChangeCrachaConfig(),
      () =>
        lineModules.UploadStudents(),
    ],
    bodyModules: [
      (lineData: Record<string, any>) =>
        lineModules.updateUnique({
          lineData,
          title,
          formSchema: formSchema,
          validationSchema: {},
          endpoint: 'students',
        }),

      /* (lineData: Record<string, any>) =>
         AplicarCortesia({
           lineData,
 
         }), */
      (lineData: Record<string, any>) =>
        lineModules.cracha({
          lineData,
          title,
          formSchema: formSchema,
          validationSchema: {},
          endpoint: 'students',
        }),


      (lineData: Record<string, any>) =>
        lineModules.credenciar({
          lineData,
          title,
          formSchema: formSchema,
          validationSchema: {},
          endpoint: 'students',
        }),
      (lineData: Record<string, any>) =>
        lineModules.Balls({
          lineData,

        }),
    ],
  };

  if (!user || (user?.profile !== 1 && user?.profile !== 4 && user?.profile !== 35)) {
    window.location.href = '/';
  }


  return (
    <Container>
      <ListCredenciados projectHash={projectHash} />
      <SearchComponent {...config} />
    </Container>
  );
};

export default AreaAtendimento;
