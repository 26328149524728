import React from 'react';
import * as Yup from 'yup';
import { FormHandles } from '@unform/core';
import uuid from '../../UniqueHash';
import { useModal } from '../../../../../../hooks/Modal';
import FormComponent, { sanitizeDataObject } from '../../FormComponentUnique';
import { createSchemaByForm, IYup } from '../../../../../../utils/SchemaCreator';
import getValidationErrors from '../../../../../../utils/getValidationErrors';
import api from '../../../../../../services/api';
import { useToast } from '../../../../../../hooks/Toast';
import { useSearch } from '../../../../../../hooks/Search';
import { AxiosInstance } from 'axios';
import { FaCheck, FaPlus, FaUpload } from 'react-icons/fa';
import { useParams } from 'react-router-dom';

interface ICreateModule {
  lineData: Record<string, any>;
}

interface IParams {
  projectHash: string;
  eventHash: string;
}

export default function CreateModule({
  lineData
}: ICreateModule): JSX.Element {



  const moduleCreate = (): JSX.Element => (
    lineData._id ? <>

      {lineData?.cpf_familiar?.indexOf('EAFM') >= 0 ? <div style={{ width: '25px', height: '25px', minWidth: '25px', minHeight: '25px', background: 'blue', borderRadius: '50%', alignSelf: 'center', color: '#fff', fontSize: '12px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>EA</div> : <></>}
      {lineData?.cpf_familiar?.indexOf('Curso Mob') >= 0 ? <div style={{ width: '25px', height: '25px', minWidth: '25px', minHeight: '25px', background: 'green', borderRadius: '50%', alignSelf: 'center', color: '#fff', fontSize: '12px', display: 'flex', alignItems: 'center', justifyContent: 'center' }} >CM</div> : <></>}
    </> : <></>
  );

  return moduleCreate();
}
