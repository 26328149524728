import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FiArrowLeft } from 'react-icons/fi';
import { Link, useHistory, useParams } from 'react-router-dom';
import { FormHandles } from '@unform/core';

import * as Yup from 'yup';

import { Container, Content, Background, AnimatedDiv } from './styles';

import getValidationErrors from '../../utils/getValidationErrors';
import api from '../../services/api';
import { useToast } from '../../hooks/Toast';

import FormContainer from './FormContainer';
import { useLanguage } from '../../hooks/Language';
import { defaultProjectHash } from '../../config';


interface SignUpFormData {
  name: string;
  email: string;
  password: string;
}

interface IParams {
  projectHash: string;
  eventHash: string;
}

const SignUp: React.FC = () => {
  const history = useHistory();
  const { addToast } = useToast();
  const [withPassword, setWithPassword] = useState(false);
  const { projectHash = defaultProjectHash, eventHash } = useParams<IParams>();
  const formRef = useRef<FormHandles>(null);
  const { translate } = useLanguage();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  return (<>
    <div className='gradient2 full-width row' style={{ padding: '50px 20px' }}>

      <div className='full-width header-sub' style={{ maxWidth: '1000px', justifyContent: 'space-between' }}>


        <div className='column full-width' style={{ maxWidth: '300px' }}>
          <p className='color-white medium midTitle'>INSCRIÇÃO</p>
          <div className='space-between gap-sm full-width'>

            <p className='color-white medium midTitle'>ONLINE</p>
            <p className='color-white medium midTitle'>/ /</p>
          </div>
        </div>

        <div style={{ maxWidth: '250px', width: '100%' }}>
          <img src="/assets/logo.png" className='full-width' />
        </div>

      </div>


    </div>
    <div className='full-width row-to-column' style={{ alignItems: 'flex-start', gap: '50px' }}>
      <div className='column full-width' style={{ maxWidth: '400px', gap: '20px', padding: '50px 20px' }}>

        {/*  <p className='color-white' style={{ textAlign: 'left', marginBottom: '20px', color: 'rgb(8, 40, 81)', fontSize: '16px' }}>
          <strong>Para seguir com sua inscrição</strong><br /> e participar do evento de maneira<br /> online, preencha o cadastro abaixo.
        </p>

  */}

        <div className='full-width' style={{ background: '#fff', width: '100%', maxWidth: '400px', borderRadius: '15px', padding: '20px', margin: '0px auto' }}>


          <FormContainer />
        </div>
      </div>
      <div>
        <img className="full-width" src="/apoio/inscricao-online.png" />
      </div>
    </div>
  </>
  );
};

export default SignUp;
