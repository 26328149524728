import styled from 'styled-components';
import { darken } from 'polished';

interface ILink {
  fontSize: string;
  iconSize: string;
}

export const Whats = styled.a<ILink>`
  z-index: 1;
  border-radius: 8px;
  background: rgb(50, 150, 26);
  width: 200px;
  max-width: 100%;
  padding: 15px;

  margin-bottom: 10px;
  margin-top: 10px;
  font-size: ${props => (props.fontSize ? props.fontSize : '16px')};
  font-weight: bold;
  color: white;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
    linear,
    right bottom,
    right top,
    color-stop(0, #18ae00),
    color-stop(0.66, rgb(50, 200, 26))
  );
  text-decoration: none;

  transition: 0.5s all;
  cursor: pointer;

  &:hover {
    background: ${darken(0.03, '#18ae00')};
    font-weight: bolder;
  }

  .icon {
    width: ${props => (props.iconSize ? props.iconSize : '20px')};
    height: ${props => (props.iconSize ? props.iconSize : '20px')};
    margin-right: 10px;
  }

  @media (max-width: 760px) {
    width: 200px;
  }
`;
