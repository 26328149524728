import React from 'react';
import whatsIcon from '../../assets/whats.png';

import { Whats } from './styles';

interface IWhatsapp {
  number?: string;
  text?: string;
  message?: string;
  iconSize?: string;
  fontSize?: string;
  style?: Record<string, any>;
}

const WhatsappFixed: React.FC<IWhatsapp> = ({
  number = '5551995181533',
  text = 'Oi, estou em seu website!',
  message = 'Tire suas dúvidas pelo WhatsApp',
  iconSize = '30px',
  fontSize = '16px',
  style = {}
}) => {
  return (
    <Whats style={{ position: 'fixed', bottom: '10px', right: '0px', zIndex: 10000000000 }}
      iconSize={'20px'}
      fontSize={fontSize}
      href={`https://api.whatsapp.com/send?l=pt&phone=${number}&text=${encodeURI(
        text,
      )}`}
      className="floater whatsapp"
    >
      <img
        className="icon whats"
        src={whatsIcon}
        title="Whatsapp"
        alt="Whatsapp"
      />
      {message}
    </Whats>
  );
};

export default WhatsappFixed;
