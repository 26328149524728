import React, { useEffect, useState } from 'react';

import { IconType } from 'react-icons/lib';

import { Container, ContainerWhite, Paragraph } from './styles';

import Vimeo from '../../components/Vimeo';
import VideoGallery from '../../components/VideoGallery';
import ImageGallery from '../../components/ImageGallery';
import Speakers from '../Speakers';
import api from '../../services/api';
import { useParams } from 'react-router-dom';
import { urlWebsite } from '../../config';
import IframePlayer from '../../components/IframePlayer';
import { useConfiguration } from '../../hooks/Configuration';
import SliderComponent from '../../components/SliderContainer';
import SpeakersListByCountry from '../../PagesApplication/HomeApplication/modules/SpeakersListByCountry';

interface SignInData {
  email: string;
  password: string;
}

interface IPublicData {
  icon: IconType;
  title: string;
  text: string;
}

interface IProps {
  hash?: string;
}

const EventsPages: React.FC<IProps> = ({ hash = '' }) => {
  const { configuration } = useConfiguration();
  const { projectHash = configuration?.url, url = hash } = useParams<Record<string, any>>();
  const [ready, setReady] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pageContent, setPageContent] = useState<Record<string, any>>({});

  const theme = configuration?.current_event_id_response?.theme || {};

  const load = async () => {
    if (url) {
      setLoading(true);

      const response = await api.get(`/show-page/${projectHash}/${url}`);

      if (response) {
        setPageContent(response.data);
        setReady(true);
      }
      else {
        setReady(true);
      }
      setLoading(false);
    }
  }

  useEffect(() => {

    load();

  }, [url, projectHash])

  const images = pageContent?.images?.length > 0 ? pageContent?.images?.reduce((prev, image) => {

    prev.push(<img style={{ width: '100%', maxWidth: '100%' }} src={`${urlWebsite}/${image?.url}`} />);
    return prev;
  }, []) : [];

  const validTheme = {
    default: <Container id={hash} style={{ flexDirection: pageContent?.orientation === 'column' ? 'column' : 'row', background: pageContent?.theme_data?.background ? pageContent?.theme_data?.background : pageContent?.image ? `url("${urlWebsite}/${pageContent?.image}")` : '#fff' }} >
      {pageContent?.images && pageContent?.images?.length > 0 ? <div style={{ width: '100%', maxWidth: '400px', borderRadius: '15px', overflow: 'hidden' }} className={"ImageSlider"}>{pageContent?.images?.length === 1 ? <img src={`${urlWebsite}/${pageContent?.images?.[0]?.url}`} /> : <SliderComponent speed={5000} autoplay={true} slidesToScroll={1} slidesToShow={1} arrows={false} >{images}</SliderComponent>} </div> : <></>}

      <div className='content'>
        <h2 className='titleElement' style={{ borderBottom: pageContent?.theme_data?.line_color ? `2px solid ${pageContent?.theme_data?.title_color}` : '2px solid #ddd', color: pageContent?.theme_data?.title_color ? pageContent?.theme_data?.title_color : '#333' }}>{pageContent?.title}</h2>
        <Paragraph style={{ lineHeight: '24px' }} color={pageContent?.theme_data?.text_color ? pageContent?.theme_data?.text_color : '#333'} dangerouslySetInnerHTML={{ __html: pageContent?.description || '' }} />

        {pageContent?.files && pageContent?.files.map((item) => {
          return <a href={`${urlWebsite}/${item.url}`} target="_blank"><button className='defaultButton'>{item.title}</button></a>
        })}

        {pageContent?.buttons?.length > 0 ? <div style={{ margin: '20px auto', display: 'flex', gap: '15px', justifyContent: 'flex-start', alignItems: 'center', flexWrap: 'wrap' }}>
          {pageContent?.buttons && pageContent?.buttons.map((itemButton) => {
            return <a href={`${itemButton.link}`} style={{ margin: '0px' }} ><button className={itemButton.class_name || 'greenButton2 shadow'} style={{ background: theme?.button_background, color: theme?.button_color }}>{itemButton.title}</button></a>
          })}
        </div> : <></>}
      </div>

      {pageContent?.map_link ? <div style={{ width: '100%', maxWidth: '400px', borderRadius: '15px', overflow: 'hidden' }}  > <iframe src={pageContent?.map_link} width="600" height="450" style={{ 'border': 0 }} allowFullScreen={true} loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe> </div> : <></>}

      {pageContent?.video ? <div className='imageHolder' style={{ maxWidth: '800px' }}><IframePlayer id={pageContent?.video} /></div> : <></>}


    </Container>,
    speakersWithCountry: <SpeakersListByCountry title={pageContent?.title} list={pageContent?.data_list?.data || []} />
  }

  return (
    <>

      {validTheme?.[pageContent?.template || 'default']}
    </>
  );
};
export default EventsPages;
